// Core.
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {BrokerModel} from '../../../../models/broker.model';
import {IQuoteInsuranceStep2AboutYou} from '../../../../services/session/i-quote-insurance-step2-about-you';
import {UserVerifyResultModel} from '../../../../models/user-verify-result.model';
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
// Shared.
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {SolicitudService} from '../../../../services/solicitud/solicitud.service';
import {UserService} from '../../../../services/user/user.service';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ContactDataPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Contact Data page. "Confirmar Datos de Contacto".
 */
@Component({
  selector: 'app-contact-data-page',
  templateUrl: './contact-data-page.component.html',
  styleUrls: ['./contact-data-page.component.scss']
})
export class ContactDataPageComponent implements OnInit {
  protected selectedBroker: BrokerModel;
  protected personData: IQuoteInsuranceStep2AboutYou;

  /* Form controls. */
  @ViewChild('contactDataForm') protected contactDataForm: NgForm;
  @ViewChild('inputPersonCellphoneForSelection') protected inputPersonCellphoneForSelection: ElementRef;
  @ViewChild('inputPersonCellphone') protected inputPersonCellphone: NgModel;
  @ViewChild('inputPersonEmail') protected inputPersonEmail: NgModel;
  protected disableForm: boolean;
  protected personEmail: string;
  protected personCellphone: string;
  protected personEmailIsModified: boolean;
  protected personCellphoneIsModified: boolean;
  protected disableContinueByPhone = true; // Functionality disabled (issue #TCSOM-123).

  private changedPersonEmail: boolean;
  private validatingPhone: boolean;
  private validatingEmail: boolean;
  private phoneOnLineValidationResult: boolean;
  private emailOnLineValidationResult: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private errorService: ErrorService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private solicitudService: SolicitudService,
    private userService: UserService
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  protected get formIsMissingData(): boolean {
    return (!this.personEmail || !this.personCellphone);
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.contactDataForm && this.contactDataForm.pristine) {
      this.contactDataForm.control.reset();
    }

    this.personEmailIsModified = false;
    this.personEmail = this.personData.personEmail;
    this.onChangePersonEmail(true);
    this.changedPersonEmail = false;

    this.personCellphoneIsModified = false;
    this.validatingPhone = false;
    this.validatingEmail = false;
    this.phoneOnLineValidationResult = null;
    this.emailOnLineValidationResult = null;
    this.personCellphone = this.personData.personCellphone;
    this.onChangePersonCellphone(true);

    this.disableForm = false;

    setTimeout(() => {
      if (this.contactDataForm) {
        this.contactDataForm.control.markAsTouched();
        if (this.contactDataForm.controls && this.contactDataForm.controls.inputPersonEmail) {
          this.contactDataForm.controls.inputPersonEmail.markAsTouched();
        }
        if (this.contactDataForm.controls && this.contactDataForm.controls.inputPersonCellphone) {
          this.contactDataForm.controls.inputPersonCellphone.markAsTouched();
        }
      }
    }, 100);
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    // No init needed.
    this.disableForm = true;
    _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
    this.formReset();
  }

  /**
   * Returns TRUE if the user input E-mail is in a valid format (RFC2822).
   * Returns FALSE otherwise.
   */
  protected isEmailValid(): boolean {
    if (!this.personEmail) {
      return false;
    }

    // RFC2822 Email Validation.
    const regExp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/); // tslint:disable-line:max-line-length
    const regMatch = this.personEmail.match(regExp);
    return regExp.test(this.personEmail) && !!regMatch && (regMatch.length === 1) && (regMatch[0] === regMatch.input);
  }

  /**
   * Returns TRUE if the user input Cellphone is in a valid format (checks characters used, initial digits, and digits count).
   * Returns FALSE otherwise.
   * These validations are Off-Line.
   */
  protected isCellphoneValid(): boolean {
    if (!this.personCellphone) {
      return false;
    }

    // Check invalid chars.
    if (this.personCellphone.match(/[^\d \-()]/g)) {
      return false;
    }

    // Extract all numbers.
    let normalized = this.personCellphone.trim().replace(/[\D]/g, '');

    // Remove initial 0 (once).
    if (normalized.startsWith('0')) {
      normalized = normalized.substr(1);
    }

    // Invalid second 0.
    if (normalized.startsWith('0')) {
      return false;
    }

    // Check digit count.
    return (normalized.length <= 12) && (normalized.length >= 8);
  }

  /**
   * Marks the input Cellphone as incorrect.
   */
  private markCellphoneAsIncorrect(): void {
    setTimeout(() => {
      if (this.inputPersonCellphone && this.inputPersonCellphone.control && this.inputPersonCellphone.control.setErrors) {
        this.inputPersonCellphone.control.setErrors({incorrect: true});
      }
    }, 10);
  }

  /**
   * Marks the input Email as incorrect.
   */
  private markEmailAsIncorrect(): void {
    setTimeout(() => {
      if (this.inputPersonEmail && this.inputPersonEmail.control && this.inputPersonEmail.control.setErrors) {
        this.inputPersonEmail.control.setErrors({incorrect: true});
      }
    }, 10);
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks Broker data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.selectedBroker = this.sessionService.getSelectedBroker();
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      if (this.selectedBroker && this.selectedBroker.selectedInsuranceOption && this.personData) {
        this.personData.contactDataConfirmed = false;
        this.formInit();
        return;
      }
    }
    this.router.navigate(['/insurance/pick-family']);
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Resets session & Cotizador data on errors.
   */
  private onError(error: any, doNotNavigate: boolean = false): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage(), 'doNotNavigate:', doNotNavigate);

    if (!doNotNavigate) {
      this.sessionService.reset();
      CotizadorService.doReset();
    }

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      }).finally(() => {
        if (doNotNavigate) {
          this.viewportScroller.scrollToAnchor('app-main-header');
        } else {
          this.router.navigate(['/home']).finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
        }
      });
    });
  }

  /**
   * Updates & Confirms User's contact data.
   */
  private onConfirmContactData(): void {
    this.personData.personEmail = this.personEmail;
    this.personData.personCellphone = this.personCellphone;
    this.personData.contactDataConfirmed = true;
  }

  private validatePhoneAndEmail(): void {
    const __SUBTAG = 'validatePhoneAndEmail';

    const onFailValidationPhone = (error: any) => {
      this.phoneOnLineValidationResult = false;
      _LOGGER.debug(_TAG, __SUBTAG, 'Fail phone validation due to:', this.errorService.getAppError(error));
    };
    if (this.isCellphoneValid()) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Validating phone On-Line...');
      this.validatingPhone = true;
      this.phoneOnLineValidationResult = null;
      this.userService.doUserVerifyPhone(this.personCellphone).subscribe((resultOrError: UserVerifyResultModel | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'doUserVerifyPhone resulted in error.');
          onFailValidationPhone(resultOrError);
          return;
        }
        // Succeed.
        _LOGGER.debug(_TAG, __SUBTAG, 'Validating phone On-Line result:', resultOrError);
        switch (resultOrError.status) {
          case 'OK':
            this.phoneOnLineValidationResult = true;
            break;
          case 'VERIFY': // Fallthrough to 'REJECT'
          /** Nav to SMS verification code screen. Currently not enabled. */
          case 'REJECT':  // Fallthrough to default.
          /** Do not allow continue and show detail msg. */
          default: // Case 'REJECT'.
            onFailValidationPhone(resultOrError.detail);
        }
      }, (error: any) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error on doPhoneValidate:', error);
        onFailValidationPhone(error);
      }, () => {
        this.validatingPhone = false;
      });
    }

    const onFailValidationEmail = (error: any) => {
      this.emailOnLineValidationResult = false;
      _LOGGER.debug(_TAG, __SUBTAG, 'Fail email validation due to:', this.errorService.getAppError(error));
    };
    if (this.isEmailValid()) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Validating email On-Line...');
      this.validatingEmail = true;
      this.emailOnLineValidationResult = null;
      this.userService.doUserVerifyMail(this.personEmail).subscribe((resultOrError: UserVerifyResultModel | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'doUserVerifyMail resulted in error.');
          onFailValidationEmail(resultOrError);
          return;
        }
        // Succeed.
        _LOGGER.debug(_TAG, __SUBTAG, 'Validating email On-Line result:', resultOrError);
        switch (resultOrError.status) {
          case 'OK':
            this.emailOnLineValidationResult = true;
            break;
          case 'VERIFY': // Fallthrough to 'REJECT'
          /** Nav to SMS verification code screen. Currently not enabled. */
          case 'REJECT':  // Fallthrough to default.
          /** Do not allow continue and show detail msg. */
          default: // Case 'REJECT'.
            onFailValidationEmail(resultOrError.detail);
        }
      }, (error: any) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error on doUserVerifyMail:', error);
        onFailValidationEmail(error);
      }, () => {
        this.validatingEmail = false;
      });
    }
  }

  /**
   * Navs to Step #2-1 (More About You) page.
   */
  protected onContinue(): void {
    const __SUBTAG = 'onContinue';
    _LOGGER.info(_TAG, __SUBTAG, 'started.');

    this.validatePhoneAndEmail();
    this.onConfirmContactData();
    this.router.navigate(['/insurance/more-about-you']);
  }

  /**
   * Navs to Continue By Phone page.
   */
  protected onContinueByPhone(): void {
    const __SUBTAG = 'onContinueByPhone';
    _LOGGER.info(_TAG, __SUBTAG, 'started.');

    if (this.disableContinueByPhone) {
      this.onError('No es posible continuar la compra por teléfono. Disculpe las molestias.');
      return;
    }

    this.onConfirmContactData();
    this.solicitudService.doContinueByPhone().subscribe((resultOrError: boolean | BaseAppError) => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Solicitud doContinueByPhone resulted in error.');
        this.onError(resultOrError);
        return;
      }

      // Succeed.
      if (!resultOrError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot doContinueByPhone Solicitud.');
        this.onError(new Error('Ha ocurrido un error durante la finalización del proceso de cotización.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'Solicitud doContinueByPhone OK.');
        this.router.navigate(['/insurance/continue-by-phone']);
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on Solicitud doContinueByPhone.');
      this.onError(error);
    });
  }

  /**
   * Navs back to Pick (Insurance) Family page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/pick-family']);
  }

  /**
   * User changes Person Email data handler.
   */
  protected onChangePersonEmail(silent: boolean = false): void {
    if (!silent) {
      this.personEmailIsModified = true;
    }
    this.changedPersonEmail = true;
    if (this.personEmail) {
      this.personEmail = this.personEmail.toLowerCase();
    }
    if (!this.isEmailValid()) {
      this.markEmailAsIncorrect();
    }
  }

  /**
   * Person Email blur handler.
   */
  protected onBlurPersonEmail(): void {
    if (this.personEmail && this.changedPersonEmail) {
      this.changedPersonEmail = false;
      setTimeout(() => {
        if (this.inputPersonCellphoneForSelection && this.inputPersonCellphoneForSelection.nativeElement &&
          this.inputPersonCellphoneForSelection.nativeElement.focus) {
          this.inputPersonCellphoneForSelection.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Email input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonEmail(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonEmail();
    }
  }

  /**
   * User changes Person Cellphone data handler.
   */
  protected onChangePersonCellphone(silent: boolean = false): void {
    if (!silent) {
      this.personCellphoneIsModified = true;
    }
    if (!this.isCellphoneValid()) {
      this.markCellphoneAsIncorrect();
    }
  }
}
