// Core.
import { Component, OnInit } from '@angular/core';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'LoadingModalComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Loading Modal component.
 */
@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
