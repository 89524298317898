// Core.
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models & Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { CardModel } from '../../models/card.model';
import { IDoCardDetectResponse } from './i-do-card-detect-response';
// Services.
import { CotizadorService } from '../cotizador/cotizador.service';
import { ErrorService } from '../error/error.service';
import { SessionService } from '../session/session.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'TarjetaService';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Card Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CardService {
  private readonly baseURL = `${_CONFIG.apiBaseURL}/pago/tarjeta`;

  constructor(
    private readonly http: HttpClient,
    private readonly errorService: ErrorService,
    private readonly sessionService: SessionService,
    private readonly cotizadorService: CotizadorService
  ) {
  }

  /**
   * Do Card Detect. Inits buy operation.
   */
  public doDetect(cardNumber: string): Observable<CardModel | BaseAppError> {
    const __SUBTAG = 'doDetect';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const moreVehicleData = this.sessionService.getBuyInsuranceStep3MoreVehicleData();
    const addressData = this.sessionService.getBuyInsuranceStep2AddressData();
    const moreAboutYouData = this.sessionService.getBuyInsuranceStep1MoreAboutYou();
    const aboutYouData = this.sessionService.getQuoteInsuranceStep2AboutYou();

    if (!moreVehicleData || !addressData || !moreAboutYouData || !aboutYouData) {
      return of(this.errorService.getAppError(new Error('Datos faltantes en Sessión de usuario.')));
    }

    const endpointURL = `${this.baseURL}/identificar`;
    const payload = {
      numeroTarjeta: cardNumber,
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCardDetectResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoCardDetectResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Return success flag.
        map((response: HttpResponse<IDoCardDetectResponse>) => new CardModel(
          response.body.ptipo,
          response.body.pnumero,
          response.body.pmarca
        )),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
