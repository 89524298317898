// Components.
import {AppComponent} from './app.component';
// i18n config.
import {registerLocaleData} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEsAr from '@angular/common/locales/extra/es-AR';
// Modules.
import {AutocompleteModule} from 'ng2-input-autocomplete';
import {BackOfficeModule} from './modules/backoffice/backoffice.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {InsuranceModule} from './modules/insurance/insurance.module';
import {JwtModule} from '@auth0/angular-jwt';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
// Shared.
import {EnvironmentManager} from './shared/environment-manager.shared';
import {SimpleLogger} from './shared/simple-logger.shared';
// Services.
import {UserService} from './services/user/user.service';
import {PublicAccessSecretInterceptor} from './interceptors/public-access-secret/public-access-secret.interceptor';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'AppModule';
_LOGGER.debug(_TAG, 'loaded.', `-- Config: ${_CONFIG.envName}.`);


/** i18n config. */
const _LOCALES = {es: localeEs, 'es-AR': localeExtraEsAr};
registerLocaleData(_LOCALES[_CONFIG.i18nLocaleShort], _CONFIG.i18nLocaleFull, _LOCALES[_CONFIG.i18nLocaleFull]);

/** Domain part of API endpoints base URL . */
const _DOMAIN_REGEXP: RegExp = /^(?:http[s]?:\/\/)?([^/\n\r]+)\/?(?:[^\n\r]+)?/i;
const _BASE_URL_DOMAIN: string = _CONFIG.apiBaseURL.match(_DOMAIN_REGEXP)[1];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // Core.
    BrowserModule,
    RecaptchaV3Module,
    BrowserAnimationsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: UserService.getCheckinToken,
        whitelistedDomains: [_BASE_URL_DOMAIN],
        blacklistedRoutes: []
      }
    }),
    NgbModule,
    AutocompleteModule.forRoot(),
    // Custom.
    InsuranceModule,
    BackOfficeModule,
    AppRoutingModule // Must be last import.
  ],
  providers: [
    // Localization (i18n).
    {provide: LOCALE_ID, useValue: _CONFIG.i18nLocaleFull},
    // Add Public Access Secret Header to all requests when enabled.
    {provide: HTTP_INTERCEPTORS, useClass: PublicAccessSecretInterceptor, multi: true},
    // reCaptcha config.
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: _CONFIG.recaptchaKey,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
