// Core.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components.
import { AboutYouPageComponent } from './pages/about-you-page/about-you-page.component';
import { AddressDataPageComponent } from './pages/address-data-page/address-data-page.component';
import { ConclusionPageComponent } from './pages/conclusion-page/conclusion-page.component';
import { ContactDataPageComponent } from './pages/contact-data-page/contact-data-page.component';
import { ContinueByPhonePageComponent } from './pages/continue-by-phone-page/continue-by-phone-page.component';
import { MoreAboutYouPageComponent } from './pages/more-about-you-page/more-about-you-page.component';
import { MoreVehicleDataPageComponent } from './pages/more-vehicle-data-page/more-vehicle-data-page.component';
import { PaymentMethodAutomaticDebitPageComponent } from './pages/payment-method-automatic-debit-page/payment-method-automatic-debit-page.component'; // tslint:disable-line:max-line-length
import { PaymentMethodCreditCardPageComponent } from './pages/payment-method-credit-card-page/payment-method-credit-card-page.component';
import { PickBrokerPageComponent } from './pages/pick-broker-page/pick-broker-page.component';
import { PickFamilyPageComponent } from './pages/pick-family-page/pick-family-page.component';
import { PickPaymentMethodPageComponent } from './pages/pick-payment-method-page/pick-payment-method-page.component';
import { ShareInsurancePageComponent } from './pages/share-insurance-page/share-insurance-page.component';
import { VehicleDataPageComponent } from './pages/vehicle-data-page/vehicle-data-page.component';
// Guards.
import { CheckinGuard } from '../../guards/checkin/checkin.guard';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'InsuranceRoutingModule';
_LOGGER.debug(_TAG, 'loaded.');


const _ROUTES: Routes = [
  {
    path: 'insurance',
    canActivate: [CheckinGuard],
    children: [
      {path: 'vehicle-data', component: VehicleDataPageComponent},
      {path: 'about-you', component: AboutYouPageComponent},
      {path: 'more-about-you', component: MoreAboutYouPageComponent},
      {path: 'contact-data', component: ContactDataPageComponent},
      {path: 'pick-broker', component: PickBrokerPageComponent},
      {path: 'pick-family', component: PickFamilyPageComponent},
      {path: 'share', component: ShareInsurancePageComponent},
      {path: 'continue-by-phone', component: ContinueByPhonePageComponent},
      {path: 'address-data', component: AddressDataPageComponent},
      {path: 'more-vehicle-data', component: MoreVehicleDataPageComponent},
      {
        path: 'pick-payment-method',
        children: [
          {path: 'credit-card', component: PaymentMethodCreditCardPageComponent},
          {path: 'automatic-debit', component: PaymentMethodAutomaticDebitPageComponent},
          {path: '', component: PickPaymentMethodPageComponent}
        ]
      },
      {path: 'conclusion', component: ConclusionPageComponent},
      {path: '', redirectTo: 'vehicle-data', pathMatch: 'full'},
      {path: '**', redirectTo: 'vehicle-data', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(_ROUTES)],
  exports: [RouterModule]
})
export class InsuranceRoutingModule {
}
