// Core.
import * as moment from 'moment';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {LocationModel} from '../../../../models/location.model';
import {PostalCodeModel} from '../../../../models/postal-code.model';
import {PersonDocumentTypeModel} from '../../../../models/person-document-type.model';
import {PersonTaxConditionModel} from '../../../../models/person-tax-condition.model';
import {ProvinceModel} from '../../../../models/province.model';
import {ChannelProvinceModel} from '../../../../models/channel-province.model';
import {VehicleReferencePriceModel} from '../../../../models/vehicle-reference-price.model'
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {GeoService} from '../../../../services/geo/geo.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {PersonService} from '../../../../services/person/person.service';
import {SessionService} from '../../../../services/session/session.service';
import {UserService} from '../../../../services/user/user.service';
import {VehiclesService} from '../../../../services/vehicles/vehicles.service';
// Shared.
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import { version } from 'process';
import { VehicleStep2DataModel } from 'src/app/models/vehicle-step2-data.model';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'AboutYouPageComponent';
_LOGGER.debug(_TAG, 'loaded.');

const autoselectProvince = false;
const _DOCUMENT_TYPE_CODE_CUIT = 'CU';
const _DOCUMENT_TYPE_CODE_DNI = 'DN';


/**
 * About You page. "Cotizar > Cotizar Seguro > Paso #1-2: Algo Sobre Vos".
 */
@Component({
  selector: 'app-about-you-page',
  templateUrl: './about-you-page.component.html',
  styleUrls: ['./about-you-page.component.scss']
})
export class AboutYouPageComponent implements OnInit {
  /* Form controls. */
  @ViewChild('aboutYouForm') protected aboutYouForm: NgForm;
  @ViewChild('autocompleteVehicleProvince') protected autocompleteVehicleProvince: ElementRef;
  @ViewChild('autocompleteVehicleLocation') protected autocompleteVehicleLocation: ElementRef;
  @ViewChild('inputPersonCellphoneForSelection') protected inputPersonCellphoneForSelection: ElementRef;
  @ViewChild('inputPersonCellphone') protected inputPersonCellphone: NgModel;
  @ViewChild('inputPersonEmail') protected inputPersonEmail: NgModel;
  protected disableForm: boolean;
  protected provinces: ProvinceModel[];
  protected provincesToSearch: ProvinceModel[] = [];
  protected cahnnelProvince: ChannelProvinceModel = null;
  protected vehicleProvince: ProvinceModel = null;
  protected vehicleProvinceSearchTerm: string;
  protected vehicleLocation: LocationModel = null;
  protected locationsToSearch: LocationModel[] = [];
  protected vehicleLocationSearchTerm: string;
  protected vehiclePostalCode: PostalCodeModel = null;
  protected personTypeIndividualIsSelected: boolean;
  protected personDocumentTypes: PersonDocumentTypeModel[];
  protected personDocumentType: PersonDocumentTypeModel;
  protected personDocument: string;
  protected personGender: string;
  protected personTaxConditions: PersonTaxConditionModel[];
  protected personTaxCondition: PersonTaxConditionModel;
  protected personBirthDateDay: number;
  protected personBirthDateMonth: number;
  protected personBirthDateYear: number;
  protected daysInMonth: number[];
  protected birthDateYears: number[];
  protected personEmail: string;
  protected personCellphone: string;
  protected loadingVehicleProvinces: boolean;
  protected loadingVehicleLocations: boolean;
  protected loadingVehiclePostalCodes: boolean;
  protected loadingPersonDocumentTypes: boolean;
  protected loadingPersonTaxConditions: boolean;
  protected loadingPersonBirthDateYears: boolean;
  protected loadingPersonBirthDateDays: boolean;

  protected docTypeTruncLength = 8;
  protected docPattern: RegExp;
  protected docMaxLength: number;
  protected docMinLength: number;

  private lastSelectedVehicleProvince: ProvinceModel = null;
  private lastSelectedVehicleLocation: LocationModel = null;

  private changedPersonEmail: boolean;
  private validatingPhone: boolean;
  private phoneOnLineValidationResult: boolean;

  private provinceMinPorcentualValue: number;
  private provinceMaxPorcentualValue: number;
  private provinceEnableDeclaredValueInput: boolean;
  private declaredValue: number;
  private declaredValueOutOfRange: boolean;
  private infoAutoReferenceValue: number;
  private maxAcceptableValue: number;
  private minAcceptableValue: number;

  constructor(
    private router: Router,
    private elem: ElementRef,
    private viewportScroller: ViewportScroller,
    private errorService: ErrorService,
    private modalsService: ModalsService,
    private geoService: GeoService,
    private personService: PersonService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private vehicleService: VehiclesService,
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  protected get formIsMissingData(): boolean {
    return (!this.vehicleProvince || !this.vehicleLocation || !this.vehiclePostalCode || !this.personDocumentType || !this.personDocument ||
      !this.personTaxCondition || !this.personEmail || !this.personCellphone || (this.personTypeIndividualIsSelected &&
        ((this.personBirthDateDay === null) || (this.personBirthDateMonth === null) || (this.personBirthDateYear === null))));
  }

  /**
   * Selects the default Document Type configured value for the Individual Person Type.
   * Selects the first Document Type when the configured value is not present in the Document Types collection.
   */
  private selectDefaultIndividualPersonDocumentType(): void {
    this.personDocumentType = this.personDocumentTypes.find(docType => (docType.code === _CONFIG.defaultPersonDocumentTypeCode));
    if (!this.personDocumentType) {
      this.personDocumentType = this.personDocumentTypes[0];
    }
  }

  /**
   * Selects the default Document Type configured value for the Enterprise Person Type.
   * Selects the first Document Type when the configured value is not present in the Document Types collection.
   */
  private selectDefaultEnterprisePersonDocumentType(): void {
    this.personDocumentType = this.personDocumentTypes.find(docType => (docType.code === _CONFIG.defaultEnterpriseDocumentTypeCode));
    if (!this.personDocumentType) {
      this.personDocumentType = this.personDocumentTypes[0];
    }
  }

  /**
   * Selects the default Tax Condition configured value for the Individual Person Type.
   * Selects the first Tax Condition when the configured value is not present in the Tax Conditions collection.
   */
  private selectDefaultIndividualPersonTaxCondition(): void {
    this.personTaxCondition = this.personTaxConditions.find(taxCond => (taxCond.code === _CONFIG.defaultPersonTaxConditionCode));
    if (!this.personTaxCondition) {
      this.personTaxCondition = this.personTaxConditions[0];
    }
  }

  /**
   * Selects the exclusive Tax Condition configured value for the Enterprise Person Type.
   * Throws an error when the configured value is not present in the Tax Conditions collection.
   */
  private selectExclusiveEnterprisePersonTaxCondition(): void {
    this.personTaxCondition = this.personTaxConditions.find(taxCond => (taxCond.code === _CONFIG.enterpriseTaxConditionExclusiveCode));
    if (!this.personTaxCondition) {
      this.onError('No se pudo obtener la condición impositiva apropiada para el tipo de persona Empresa.');
    }
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.aboutYouForm && this.aboutYouForm.pristine) {
      this.aboutYouForm.control.reset();
    }

    const prevData = this.sessionService.getQuoteInsuranceStep2AboutYou();
    const helper = Helper.getInstance();

    this.vehicleProvince = null;
    if (prevData && prevData.vehicleProvince) {
      this.vehicleProvince = this.provinces.find(vp => helper.equalsByProps(vp, prevData.vehicleProvince, ['code', 'name']));
      this.vehicleProvinceSearchTerm = prevData.vehicleProvince.name;
      this.lastSelectedVehicleProvince = prevData.vehicleProvince;
      this.checkIfProvinceEnablesInputForCarDeclaredValue(prevData.vehicleProvince.code);
    }
    if (prevData && prevData.vehicleLocation && prevData.vehiclePostalCode) {
      this.vehicleLocationSearchTerm = prevData.vehicleLocation.name;
      this.lastSelectedVehicleLocation = prevData.vehicleLocation;
      this.onChangeVehicleProvince(prevData.vehicleLocation, prevData.vehiclePostalCode);
    } else {
      this.onChangeVehicleProvince();
    }

    if (prevData) {
      this.personTypeIndividualIsSelected = !!prevData.personTypeIsIndividual;
    } else {
      this.personTypeIndividualIsSelected = true;
    }
    if (this.personTypeIndividualIsSelected) {
      this.onSelectPersonTypeIndividual(true);
    } else {
      this.onSelectPersonTypeEnterprise(true);
    }

    if (prevData && prevData.personDocumentType) {
      this.personDocumentType = this.personDocumentTypes
        .find(pdt => helper.equalsByProps(pdt, prevData.personDocumentType, ['code', 'name']));
      if (!this.personDocumentType) {
        if (this.personTypeIndividualIsSelected) {
          this.selectDefaultIndividualPersonDocumentType();
        } else {
          this.selectDefaultEnterprisePersonDocumentType();
        }
      }
    } else {
      this.selectDefaultIndividualPersonDocumentType();
    }
    this.onChangePersonDocumentType();

    this.personGender = '';
    this.onChangePersonGender();

    if (prevData && prevData.personTaxCondition) {
      this.personTaxCondition = this.personTaxConditions
        .find(ptc => helper.equalsByProps(ptc, prevData.personTaxCondition, ['code', 'name']));
      if (!this.personTaxCondition) {
        if (this.personTypeIndividualIsSelected) {
          this.selectDefaultIndividualPersonTaxCondition();
        } else {
          this.selectExclusiveEnterprisePersonTaxCondition();
        }
      }
    } else {
      this.selectDefaultIndividualPersonTaxCondition();
    }
    this.onChangePersonTaxCondition();

    if (prevData && prevData.personDocument) {
      this.personDocument = prevData.personDocument;
    } else {
      this.personDocument = null;
    }
    this.onChangePersonDocument();

    if (prevData && prevData.personBirthDate) {
      this.personBirthDateDay = prevData.personBirthDate.getDate();
      this.personBirthDateMonth = prevData.personBirthDate.getMonth();
      this.personBirthDateYear = prevData.personBirthDate.getFullYear();
    } else {
      this.personBirthDateDay = null;
      this.personBirthDateMonth = null;
      this.personBirthDateYear = null;
    }
    this.onChangePersonBirthDateDay();
    this.onChangePersonBirthDateMonth();
    this.onChangePersonBirthDateYear();

    if (prevData && prevData.personEmail) {
      this.personEmail = prevData.personEmail;
    } else {
      this.personEmail = null;
    }
    this.onChangePersonEmail();
    this.changedPersonEmail = false;

    this.validatingPhone = false;
    this.phoneOnLineValidationResult = null;
    if (prevData && prevData.personCellphone) {
      this.personCellphone = prevData.personCellphone;
    } else {
      this.personCellphone = null;
    }
    this.onChangePersonCellphone();

    this.disableForm = false;

    // Autoselect 1st input.
    if (autoselectProvince && this.autocompleteVehicleProvince) {
      setTimeout(() => {
        this.autocompleteVehicleProvince.nativeElement.click();
        this.onBlurAutocompleteVehicleProvince();
      }, 10);
    }
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;
    Promise
      .all([
        // Get birth date years.
        new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
          try {
            this.loadingPersonBirthDateYears = true;
            const initialYear = new Date().getFullYear() - _CONFIG.birthDateYearsAgoOffset;
            this.birthDateYears = (Array.apply(null, {
              length: _CONFIG.birthDateYearsAgoToConsider - _CONFIG.birthDateYearsAgoOffset + 1
            }) as any[])
              .map((v, i) => initialYear - i);

            if (!this.birthDateYears || !this.birthDateYears.length || (this.birthDateYears.length < 1)) {
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            }

            resolve();
          } catch (e) {
            _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Birth Date Years.', e);
            reject(e);
          } finally {
            this.loadingPersonBirthDateYears = false;
          }
        }),

        // Get Provinces.
        new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
          try {
            this.loadingVehicleProvinces = true;
            this.geoService.getProvinces()
              .subscribe((resultOrError: ProvinceModel[] | BaseAppError) => {
                // Failed.
                if (resultOrError instanceof BaseAppError) {
                  _LOGGER.error(_TAG, __SUBTAG, 'getProvinces resulted in error.');
                  reject(resultOrError);
                  return;
                }

                // Succeed.
                if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                  _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Provinces.');
                  reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
                } else {
                  _LOGGER.debug(_TAG, __SUBTAG, 'Provinces initialized.');
                  this.provinces = resultOrError;
                  resolve(this.provinces);
                }
              }, (error: any) => {
                _LOGGER.error(_TAG, __SUBTAG, 'Error on getProvinces.');
                reject(error);
              });
          } catch (e) {
            _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Provinces.', e);
            reject(e);
          } finally {
            this.loadingVehicleProvinces = false;
          }
        }),

        // Get Document Types.
        new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
          try {
            this.loadingPersonDocumentTypes = true;
            this.personService.getDocumentTypes()
              .subscribe((resultOrError: PersonDocumentTypeModel[] | BaseAppError) => {
                // Failed.
                if (resultOrError instanceof BaseAppError) {
                  _LOGGER.error(_TAG, __SUBTAG, 'getDocumentTypes resulted in error.');
                  reject(resultOrError);
                  return;
                }

                // Succeed.
                if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                  _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Document Types.');
                  reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
                } else {
                  _LOGGER.debug(_TAG, __SUBTAG, 'Document Types initialized.');
                  this.personDocumentTypes = resultOrError;
                  resolve(this.personDocumentTypes);
                }
              }, (error: any) => {
                _LOGGER.error(_TAG, __SUBTAG, 'Error on getDocumentTypes.');
                reject(error);
              });
          } catch (e) {
            _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Document Types.', e);
            reject(e);
          } finally {
            this.loadingPersonDocumentTypes = false;
          }
        }),

        // Get Tax Conditions.
        new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
          try {
            this.loadingPersonTaxConditions = true;
            this.personService.getTaxConditions()
              .subscribe((resultOrError: PersonTaxConditionModel[] | BaseAppError) => {
                // Failed.
                if (resultOrError instanceof BaseAppError) {
                  _LOGGER.error(_TAG, __SUBTAG, 'getTaxConditions resulted in error.');
                  reject(resultOrError);
                  return;
                }

                // Succeed.
                if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                  _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Tax Conditions.');
                  reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
                } else {
                  _LOGGER.debug(_TAG, __SUBTAG, 'Tax Conditions initialized.');
                  this.personTaxConditions = resultOrError;
                  resolve(this.personTaxConditions);
                }
              }, (error: any) => {
                _LOGGER.error(_TAG, __SUBTAG, 'Error on getTaxConditions.');
                reject(error);
              });
          } catch (e) {
            _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Tax Conditions.', e);
            reject(e);
          } finally {
            this.loadingPersonTaxConditions = false;
          }
        })
      ])
      .then(() => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
        this.formReset();
      })
      .catch(error => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
        this.onError(error);
      });
  }

  /**
   * Updates the days count in the birth date month/year currently selected by the user (or the current month/year as default).
   */
  private getDaysInMonth(): void {
    this.loadingPersonBirthDateDays = true;
    let length = 31;
    if (this.personBirthDateMonth) {
      const now = moment();
      now.month(this.personBirthDateMonth);
      if (this.personBirthDateYear) {
        now.year(this.personBirthDateYear);
      }
      length = now.daysInMonth();
    }

    this.daysInMonth = (Array.apply(null, {length}) as any[]).map((v, i) => i + 1);
    this.loadingPersonBirthDateDays = false;

    if (this.personBirthDateDay > this.daysInMonth.length) {
      this.personBirthDateDay = this.daysInMonth.length;
      this.onChangePersonBirthDateDay();
    }
  }

  /**
   * Returns TRUE if the current selected Birth Date day/month/year by the user is a valid date. Returns FALSE otherwise.
   * Also returns FALSE if the date is in the future (greater than the current date).
   */
  protected isBirthDateValid(): boolean {
    if (!this.personTypeIndividualIsSelected) {
      return true;
    }

    if ((this.personBirthDateDay === null) || (this.personBirthDateMonth === null) || (this.personBirthDateYear === null)) {
      return false;
    }
    return moment(`${this.personBirthDateDay}-${this.personBirthDateMonth + 1}-${this.personBirthDateYear}`, 'D-M-YYYY')
      .isSameOrBefore(moment());
  }

  /**
   * Returns TRUE if the user input E-mail is in a valid format (RFC2822).
   * Returns FALSE otherwise.
   */
  protected isEmailValid(): boolean {
    if (!this.personEmail) {
      return false;
    }

    // RFC2822 Email Validation.
    const regExp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/); // tslint:disable-line:max-line-length
    const regMatch = this.personEmail.match(regExp);
    return regExp.test(this.personEmail) && !!regMatch && (regMatch.length === 1) && (regMatch[0] === regMatch.input);
  }

  /**
   * Returns TRUE if the user input Cellphone is in a valid format (checks characters used, initial digits, and digits count).
   * Returns FALSE otherwise.
   * These validations are Off-Line.
   */
  protected isCellphoneValid(): boolean {
    if (!this.personCellphone) {
      return false;
    }

    // Check invalid chars.
    if (this.personCellphone.match(/[^\d \-()]/g)) {
      return false;
    }

    // Extract all numbers.
    let normalized = this.personCellphone.trim().replace(/[\D]/g, '');

    // Remove initial 0 (once).
    if (normalized.startsWith('0')) {
      normalized = normalized.substr(1);
    }

    // Invalid second 0.
    if (normalized.startsWith('0')) {
      return false;
    }

    // Check digit count.
    return (normalized.length <= 12) && (normalized.length >= 8);
  }

  /**
   * Returns TRUE if the document type selected is not CUIT.
   * If the document type is CUIT, returns TRUE if the CUIT is valid.
   */
  protected isDocumentValid(): boolean {
    if (!this.personDocument || !this.personDocumentType) {
      return false;
    }

    if (this.personDocumentType.code !== _DOCUMENT_TYPE_CODE_CUIT) {
      return true;
    }

    // CUIT validation.
    if (!this.personDocument || (this.personDocument.length !== 11)) {
      return false;
    }

    if (!this.personDocument.startsWith('30') && !this.personDocument.startsWith('33') &&
      !this.personDocument.startsWith('34')) {
      return false;
    }

    let accumulated = 0;
    const digits = this.personDocument.split('').map(d => parseInt(d, 10));
    const digit = digits.pop();
    for (let i = 0; i < digits.length; i++) {
      accumulated += digits[9 - i] * (2 + (i % 6));
    }
    let verifier = 11 - (accumulated % 11);
    if (verifier === 11) {
      verifier = 0;
    }
    return digit === verifier;
  }

  /**
   * Returns TRUE only if the user input Cellphone failed the On-Line validations.
   */
  protected isCellphoneFailedOnLineValidations(): boolean {
    if (this.validatingPhone) {
      return false;
    }

    return (this.phoneOnLineValidationResult !== null) && !this.phoneOnLineValidationResult;
  }

  /**
   * Marks the view form as dirty.
   */
  private markFormAsDirty(): void {
    if (this.aboutYouForm && this.aboutYouForm.pristine) {
      this.aboutYouForm.control.markAsDirty();
    }
  }

  /**
   * Marks the input Cellphone as incorrect.
   */
  private markCellphoneAsIncorrect(): void {
    setTimeout(() => {
      if (this.inputPersonCellphone && this.inputPersonCellphone.control && this.inputPersonCellphone.control.setErrors) {
        this.inputPersonCellphone.control.setErrors({incorrect: true});
      }
    }, 10);
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step 1 data.
   * Inits or resets form.
   */
  public ngOnInit(): void {
    this.provinceEnableDeclaredValueInput = false;
    if (this.sessionService.getQuoteInsuranceStep1VehicleData()) {
      this.formInit();
    } else {
      this.router.navigate(['/insurance/vehicle-data']);
    }
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any, doNotNavigate: boolean = false): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router
        .navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}})
        .finally(() => {
          this.viewportScroller.scrollToAnchor('app-main-header');
        });
      return;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      }).finally(() => {
        if (doNotNavigate) {
          this.viewportScroller.scrollToAnchor('app-main-header');
        } else {
          this.sessionService.reset();
          CotizadorService.doReset();
          this.router.navigate(['/home']).finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
        }
      });
    });
  }

  /**
   * Navs to Pick Broker page.
   */
  public onContinue(): void {
    const __SUBTAG = 'onContinue';

    // Pre-load Brokers (uses cache).
    this.cotizadorService.getBrokers();

    this.sessionService.setQuoteInsuranceStep2AboutYou({
      vehicleProvince: this.vehicleProvince,
      vehicleLocation: this.vehicleLocation,
      vehiclePostalCode: this.vehiclePostalCode,
      personBirthDate: moment(`${this.personBirthDateDay}-${this.personBirthDateMonth + 1}-${this.personBirthDateYear}`,
        'D-M-YYYY').toDate(),
      personTypeIsIndividual: this.personTypeIndividualIsSelected,
      personDocumentType: this.personDocumentType,
      personDocument: this.personDocument,
      personGender: this.personTypeIndividualIsSelected ? this.personGender : '',
      personTaxCondition: this.personTaxCondition,
      personEmail: this.personEmail,
      personCellphone: this.personCellphone,
      contactDataConfirmed: false
    });

    this.disableForm = true;
    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsuranceStep2AboutYou_Continue')
      .subscribe((captchaToken: string) => {
        recaptchaSubscription.unsubscribe();
        this.cotizadorService.doInit(captchaToken).subscribe((resultOrError: boolean | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cotizador doInit resulted in error.');
            this.onError(resultOrError, true);
            return;
          }

          // Succeed.
          if (!resultOrError) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize Cotizador.');
            this.onError(new Error('Ha ocurrido un error durante la inicialización del proceso de cotización.'), true);
          } else {
            _LOGGER.debug(_TAG, __SUBTAG, 'Cotizador initialized.');
            this.router.navigate(['/insurance/pick-broker']);
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador doInit.');
          this.onError(error, true);
        }, () => {
          this.disableForm = false;
        });
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador captcha execute.');
        this.onError(error, true);
        this.disableForm = false;
      });
  }

  /**
   * Navs to Step #1-1 (Vehicle Data).
   */
  public onStepBack(): void {
    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsuranceStep2AboutYou_Back')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });
    this.router.navigate(['/insurance/vehicle-data']);
  }

  /**
   * User selects Vehicle Province from Autocomplete component.
   *
   * @param item  Item selected.
   */
  protected onSelectAutocompleteVehicleProvince(item: any): void {
    const __SUBTAG = 'onSelectAutocompleteVehicleProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    if (!this.lastSelectedVehicleProvince || (this.lastSelectedVehicleProvince.code !== item.code)) {
      this.lastSelectedVehicleProvince = item as ProvinceModel;
      this.vehicleProvince = this.lastSelectedVehicleProvince;
      this.onChangeVehicleProvince(null, null, true);
      this.vehicleLocationSearchTerm = null;
      this.provincesToSearch = [];
      this.checkIfProvinceEnablesInputForCarDeclaredValue(this.vehicleProvince.code);
    }
  }

  protected checkIfProvinceEnablesInputForCarDeclaredValue(provinceId?: string): void {

   const __SUBTAG = "checkIfProvinceEnablesInputForCarDeclaredValue";
    if(provinceId){
      this.geoService.enableDeclaredValueInput(provinceId)
      .subscribe((resultOrError: ChannelProvinceModel | BaseAppError) => {
        
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'enableDeclaredValueInput() resulted in error.');
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        if (resultOrError == null) {
          _LOGGER.error(_TAG, __SUBTAG, 'data not retrieved.', 'ChannelProvinceModel: null');
          this.provinceEnableDeclaredValueInput = false;
          return;
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'data retrieved.', 'ChannelProvinceModel.enableCarValue:', resultOrError);
          this.provinceEnableDeclaredValueInput= resultOrError.enableCarValue;
          this.provinceMinPorcentualValue =resultOrError.minPorcentualValue;
          this.provinceMaxPorcentualValue =resultOrError.maxPorcentualValue;
          
          
          if(this.provinceEnableDeclaredValueInput){
            let versionId = this.sessionService.getQuoteInsuranceStep1VehicleData().vehicleVersion.idInfoAuto;
            let year = this.sessionService.getQuoteInsuranceStep1VehicleData().vehicleYear.year;
            this.getReferenceValueFromInfoAuto(versionId, year);
          } else {
            _LOGGER.info("No se requiere declarar el valor del vehiculo.");
            this.declaredValueOutOfRange = false;
            let data = new VehicleStep2DataModel();// declaredValue = 0, referenceValueFromInfoAuto = 0
            this.sessionService.setVehicleStep2Data(data);
          }
        } (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on getLocations.');
          this.onError(error);
        }
      })
    } 
  }

  protected getReferenceValueFromInfoAuto(versionId: string, anio: number): void{

    const __SUBTAG = "getReferenceValueFromInfoAuto";
    if(!!versionId && !!anio){
      _LOGGER.info("Se busca el valor de referencia en APS para el vehiculo : ID=" + versionId +" - anio="+ anio)
      this.vehicleService.getReferencePriceFromInfoAuto(versionId, anio)
      .subscribe((resultOrError: VehicleReferencePriceModel | BaseAppError) => {
        
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, ' resulted in error.');
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        if (resultOrError.responseCode == 404) {
          _LOGGER.error(_TAG, __SUBTAG,'data not retrieved.', resultOrError.message)
          //TODO: handle this error.
          return;
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'data retrieved.', 'VehicleReferencePriceModel:', resultOrError);
          this.infoAutoReferenceValue= resultOrError.referencePriceInfoAuto;
          _LOGGER.info("valor de referencia:", this.infoAutoReferenceValue);

        } (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on getLocations.');
          this.onError(error);
        }
      })
    } 
  }

  protected isDeclaredValueWithinTheRange(): void{
    this.declaredValue; // el valor ingresado en el input
    this.maxAcceptableValue = this.infoAutoReferenceValue*(1 + this.provinceMaxPorcentualValue/100)
    this.minAcceptableValue = this.infoAutoReferenceValue*(1 - this.provinceMinPorcentualValue/100)

    if ( this.minAcceptableValue <= this.declaredValue && this.declaredValue <= this.maxAcceptableValue){
      this.declaredValueOutOfRange = false;
      let data: VehicleStep2DataModel = {
        "declaredValue" : this.declaredValue,
        "referenceValueFromInfoAuto" : this.infoAutoReferenceValue,
      }
      _LOGGER.debug("Se guarda en sessionService: ", this.declaredValue, this.infoAutoReferenceValue)
      this.sessionService.setVehicleStep2Data(data);
    } else {
      this.declaredValueOutOfRange = true;
    }

  }

  /**
   * User changes search term on Vehicle Province Autocomplete input.
   */
  protected onInputChangedEventAutocompleteVehicleProvince(): void {
    const __SUBTAG = 'onSelectAutocompleteVehicleProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    if (!this.vehicleProvinceSearchTerm || !this.vehicleProvinceSearchTerm.length || (this.vehicleProvinceSearchTerm.length < 3)) {
      this.provincesToSearch = [];
    } else {
      this.provincesToSearch = this.provinces;
    }
  }

  /**
   * This event fires on user Click on Vehicle Province Autocomplete input (due to Autocomplete's plugin logic).
   * Searches for the hidden Autocomplete input (plugin's) and attaches a "blur" event listener to it.
   */
  protected onBlurAutocompleteVehicleProvince(): void {
    const __SUBTAG = 'onBlurAutocompleteVehicleProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    setTimeout(() => {
      const elements: NodeList = this.elem.nativeElement.querySelectorAll('.autocomplete.inputVehicleProvince > input');
      if (elements.length === 1) {
        _LOGGER.debug(_TAG, __SUBTAG, 'Attach event listener.');
        const inputEl = elements.item(0) as HTMLInputElement;
        inputEl.addEventListener('blur', () => {
          _LOGGER.debug(_TAG, __SUBTAG, 'Event fired.');
          if (this.vehicleProvince) {
            this.vehicleProvinceSearchTerm = this.vehicleProvince.name;
          }
        });
        inputEl.select();
      }
    }, 10);
  }

  /**
   * User changes Vehicle Province data handler.
   * Gets province's Locations.
   */
  protected onChangeVehicleProvince(selectVehicleLocation: LocationModel = null, selectVehiclePostalCode: PostalCodeModel = null,
                                    selectAutocompleteLocationInput: boolean = false): void {
    const __SUBTAG = 'onChangeVehicleProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.', '; Selected:', selectVehicleLocation);

    this.vehicleLocation = null;
    this.lastSelectedVehicleLocation = null;
    this.locationsToSearch = [];
    this.vehicleLocationSearchTerm = null;
    this.provinceEnableDeclaredValueInput = false;

    if (this.vehicleProvince) {
      this.loadingVehicleLocations = true;
      this.geoService.getLocationsByProvince(this.vehicleProvince)
        .subscribe((resultOrError: LocationModel[] | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'getLocations resulted in error.');
            this.onError(resultOrError);
            return;
          }

          // Succeed.
          if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Locations.');
            this.onError(new Error('Ha ocurrido un error durante la obtención de localidades.'));
          } else {
            _LOGGER.debug(_TAG, __SUBTAG, 'Locations retrieved.', '; Vehicle Prov.:', this.vehicleProvince);
            if (this.vehicleProvince.locations && this.vehicleProvince.locations.length) {
              if (selectVehicleLocation) {
                this.vehicleLocation = this.vehicleProvince.locations
                  .find(vl => Helper.getInstance().equalsByProps(vl, selectVehicleLocation, ['code', 'name']));
              }
              if (!this.vehicleLocation && (this.vehicleProvince.locations.length === 1)) {
                _LOGGER.debug(_TAG, __SUBTAG, 'Selecting 2st Location.');
                this.vehicleLocation = this.vehicleProvince.locations[0];
              }
              this.lastSelectedVehicleLocation = this.vehicleLocation;
            }
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on getLocations.');
          this.onError(error);
        }, () => {
          this.loadingVehicleLocations = false;
          this.onChangeVehicleLocation(selectVehiclePostalCode);
          // Autoselect next input.
          if (selectAutocompleteLocationInput) {
            if (this.autocompleteVehicleLocation) {
              setTimeout(() => {
                this.autocompleteVehicleLocation.nativeElement.click();
                this.onBlurAutocompleteVehicleLocation();
              }, 10);
            }
          }
        });
    } else {
      this.onChangeVehicleLocation();
    }
  }

  /**
   * User selects Vehicle Location from Autocomplete component.
   *
   * @param item  Location selected.
   */
  protected onSelectAutocompleteVehicleLocation(item: any): void {
    const __SUBTAG = 'onSelectAutocompleteVehicleLocation';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.', '; Item:', item, '; Last selected:', this.lastSelectedVehicleLocation);

    if (!this.lastSelectedVehicleLocation || (this.lastSelectedVehicleLocation.code !== item.code)) {
      this.lastSelectedVehicleLocation = item as LocationModel;
      this.vehicleLocation = this.lastSelectedVehicleLocation;
      this.onChangeVehicleLocation();
      this.locationsToSearch = [];
    }
  }

  /**
   * User changes search term on Vehicle Location Autocomplete input.
   */
  protected onInputChangedEventAutocompleteVehicleLocation(): void {
    if (!this.vehicleLocationSearchTerm || !this.vehicleLocationSearchTerm.length || (this.vehicleLocationSearchTerm.length < 3)) {
      this.locationsToSearch = [];
    } else {
      this.locationsToSearch = this.vehicleProvince.locations;
    }
  }

  /**
   * This event fires on user Click on Vehicle Location Autocomplete input (due to Autocomplete's plugin logic).
   * Searches for the hidden Autocomplete input (plugin's) and attaches a "blur" event listener to it.
   */
  protected onBlurAutocompleteVehicleLocation(): void {
    setTimeout(() => {
      const elements: NodeList = this.elem.nativeElement.querySelectorAll('.autocomplete.inputVehicleLocation > input');
      if (elements.length === 1) {
        const inputEl = elements.item(0) as HTMLInputElement;
        inputEl.addEventListener('blur', () => {
          if (this.vehicleLocation) {
            this.vehicleLocationSearchTerm = this.vehicleLocation.name;
          }
        });
        inputEl.select();
      }
    }, 10);
  }

  /**
   * User changes Vehicle Location data handler.
   * Gets location's Postal Codes.
   */
  protected onChangeVehicleLocation(selectVehiclePostalCode: PostalCodeModel = null): void {
    const __SUBTAG = 'onChangeVehicleLocation';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.', '; Selected:', selectVehiclePostalCode);

    this.vehiclePostalCode = null;

    _LOGGER.debug(_TAG, __SUBTAG, 'checking vehicle location postal codes.', '; Vehicle Loc.:', this.vehicleLocation);
    if (this.vehicleLocation) {
      this.vehicleLocationSearchTerm = this.vehicleLocation.name;
      _LOGGER.debug(_TAG, __SUBTAG, 'loading vehicle location postal codes.');
      this.loadingVehiclePostalCodes = true;
      if (!this.vehicleLocation.postalCodes.length || (this.vehicleLocation.postalCodes.length < 1)) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Postal Codes.');
        this.onError(new Error('Ha ocurrido un error durante la obtención de códigos postales.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'Postal Codes are OK.');
        if (selectVehiclePostalCode) {
          this.vehiclePostalCode = this.vehicleLocation.postalCodes
            .find(vpc => Helper.getInstance().equalsByProps(vpc, selectVehiclePostalCode, ['code', 'name']));
        }
        if (!this.vehiclePostalCode && (this.vehicleLocation.postalCodes.length === 1)) {
          _LOGGER.debug(_TAG, __SUBTAG, 'Selecting 1st postal code.');
          this.vehiclePostalCode = this.vehicleLocation.postalCodes[0];
        }
      }
      this.loadingVehiclePostalCodes = false;
    }

    _LOGGER.debug(_TAG, __SUBTAG, 'triggering change vehicle location postal codes.');
    this.onChangeVehiclePostalCode();
  }

  /**
   * User changes Vehicle Postal Code data handler.
   */
  protected onChangeVehiclePostalCode(): void {
    // No action needed.
  }

  /**
   * User selects Person Type Individual handler.
   * Marks the form as dirty because there is no input changed on user action and the NgForm does not detect the person type change.
   *
   * @param silent    Flag that controls whether the status of the form should be marked (as dirty) or not.
   */
  protected onSelectPersonTypeIndividual(silent: boolean = false): void {
    this.personTypeIndividualIsSelected = true;
    if (!silent) {
      this.markFormAsDirty();
    }
    this.onChangePersonType();
  }

  /**
   * User selects Person Type Enterprise handler.
   * Marks the form as dirty because there is no input changed on user action and the NgForm does not detect the person type change.
   *
   * @param silent    Flag that controls whether the status of the form should be marked (as dirty) or not.
   */
  protected onSelectPersonTypeEnterprise(silent: boolean = false): void {
    this.personTypeIndividualIsSelected = false;
    if (!silent) {
      this.markFormAsDirty();
    }
    this.onChangePersonType();
  }

  /**
   * User changes Person Type data handler.
   */
  protected onChangePersonType(): void {
    if (this.personTypeIndividualIsSelected) {
      this.selectDefaultIndividualPersonDocumentType();
      this.selectDefaultIndividualPersonTaxCondition();
    } else {
      this.selectDefaultEnterprisePersonDocumentType();
      this.selectExclusiveEnterprisePersonTaxCondition();
    }
    this.onChangePersonDocumentType();
    this.onChangePersonTaxCondition();
  }

  /**
   * User changes Person Document Type data handler.
   */
  protected onChangePersonDocumentType(): void {
    if (this.personDocumentType.code === _DOCUMENT_TYPE_CODE_DNI) {
      this.docPattern = /[\d]{8}/;
      this.docMaxLength = 8;
      this.docMinLength = 8;
      if (this.personDocument) {
        this.personDocument = this.personDocument.substr(0, 8);
      }
    } else if (this.personDocumentType.code === _DOCUMENT_TYPE_CODE_CUIT) {
      this.docPattern = /[\d]{11}/;
      this.docMaxLength = 11;
      this.docMinLength = 11;
      if (this.personDocument) {
        this.personDocument = this.personDocument.substr(0, 11);
      }
    } else {
      this.docPattern = /[\d]*/;
      this.docMaxLength = 100;
      this.docMinLength = 1;
    }
  }

  /**
   * User changes Person Document data handler.
   */
  protected onChangePersonDocument(): void {
    // No action needed.
  }

  /**
   * User changes Person Birth Date Day data handler.
   */
  protected onChangePersonBirthDateDay(): void {
    // No action needed.
  }

  /**
   * User changes Person Birth Date Month data handler.
   * Updates days in month count.
   */
  protected onChangePersonBirthDateMonth(): void {
    this.getDaysInMonth();
  }

  /**
   * User changes Person Birth Date Year data handler.
   * Updates days in month count.
   */
  protected onChangePersonBirthDateYear(): void {
    this.getDaysInMonth();
  }

  /**
   * User changes Person Email data handler.
   */
  protected onChangePersonEmail(): void {
    this.changedPersonEmail = true;
    if (this.personEmail) {
      this.personEmail = this.personEmail.toLowerCase();
    }
    if (!this.isEmailValid()) {
      setTimeout(() => {
        if (this.inputPersonEmail && this.inputPersonEmail.control && this.inputPersonEmail.control.setErrors) {
          this.inputPersonEmail.control.setErrors({incorrect: true});
        }
      }, 10);
    }
  }

  /**
   * Person Email input blur handler.
   */
  protected onBlurPersonEmail(): void {
    if (this.personEmail && this.changedPersonEmail) {
      this.changedPersonEmail = false;
      setTimeout(() => {
        if (this.inputPersonCellphoneForSelection && this.inputPersonCellphoneForSelection.nativeElement &&
          this.inputPersonCellphoneForSelection.nativeElement.focus) {
          this.inputPersonCellphoneForSelection.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Email input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonEmail(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonEmail();
    }
  }

  /**
   * User changes Person Cellphone data handler.
   */
  protected onChangePersonCellphone(): void {
    const __SUBTAG = 'onChangePersonCellphone';

    const onFailValidation = (error: any) => {
      this.phoneOnLineValidationResult = false;

      // Display errors 400 as FAILED, without message. Other errors: show modal with custom message.
      const appError: BaseAppError = this.errorService.getAppError(error);
      if (appError.getCode() !== ErrorService.getApiHttpErrorCode(400)) {
        this.onError(appError, true);
      }
    };

    if (this.isCellphoneValid()) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Validating phone On-Line...');
      this.validatingPhone = true;
      this.phoneOnLineValidationResult = null;
      this.userService.doPhoneValidate(this.personCellphone)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'doPhoneValidate resulted in error.');
            onFailValidation(resultOrError);
            return;
          }

          // Succeed.
          _LOGGER.debug(_TAG, __SUBTAG, 'Validating phone On-Line result:', resultOrError);
          this.phoneOnLineValidationResult = resultOrError;
        }, (error: any) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'Error on doPhoneValidate:', error);
          onFailValidation(error);
        }, () => {
          this.validatingPhone = false;
          if (this.isCellphoneFailedOnLineValidations()) {
            this.markCellphoneAsIncorrect();
          }
        });
    } else {
      this.markCellphoneAsIncorrect();
    }
  }

  /**
   * User changes Person Tax Condition data handler.
   */
  protected onChangePersonTaxCondition(): void {
    // No action needed.
  }

  /**
   * User changes Person Gender data handler.
   */
  protected onChangePersonGender(): void {
    // No action needed.
  }
}
