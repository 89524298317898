// Core.
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {BrokerModel} from '../../../../models/broker.model';
import {IQuoteInsuranceStep1VehicleData} from '../../../../services/session/i-quote-insurance-step1-vehicle-data';
import {IQuoteInsuranceStep2AboutYou} from '../../../../services/session/i-quote-insurance-step2-about-you';
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {UserService} from '../../../../services/user/user.service';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ShareInsurancePageComponent';
_LOGGER.debug(_TAG, 'loaded.');

/**
 * Share Insurance page.
 */
@Component({
  selector: 'app-share-insurance-page',
  templateUrl: './share-insurance-page.component.html',
  styleUrls: ['./share-insurance-page.component.scss']
})
export class ShareInsurancePageComponent implements OnInit {
  protected vehicleData: IQuoteInsuranceStep1VehicleData;
  protected personData: IQuoteInsuranceStep2AboutYou;
  protected insuranceId: number;
  protected activeBrokers: BrokerModel[];
  public dealerName: string;
  protected quoteDate: Date;
  protected lawText: string;
  protected sendingEmail: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private modalsService: ModalsService,
    private errorService: ErrorService,
    public userService: UserService
  ) {
  }

  protected get downloadInsuranceUrl(): string {
    return this.cotizadorService.getInsuranceQuotationDownloadUrl();
  }

  protected get printInsuranceUrl(): string {
    return this.cotizadorService.getInsuranceQuotationPrintUrl();
  }

  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks steps #1 & #2 data.
   * Checks Broker data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';

    if (CotizadorService.isInitProcessCompleted()) {
      this.vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      this.insuranceId = CotizadorService.getInsuranceQuoteMainId();
      this.dealerName = CotizadorService.getInsuranceQuoteOriginDealer() || 'N/A';
      this.quoteDate = CotizadorService.getInsuranceQuoteDate() || new Date();
      this.lawText = CotizadorService.getInsuranceLawText() || '';
      this.activeBrokers = this.sessionService.getAllActiveBrokers();
      if (this.vehicleData && this.personData && this.activeBrokers && this.activeBrokers.length && (this.activeBrokers.length >= 1) &&
        this.dealerName && this.quoteDate) {
        return;
      }
    }

    _LOGGER.debug(_TAG, __SUBTAG, 'Nav back.',
      '; Veh. data:', this.vehicleData,
      '; Per. data:', this.personData,
      '; Act. Brokers:', this.activeBrokers,
      '; Dealer Name:', this.dealerName,
      '; Dealer Quote Date:', this.quoteDate,
      '; Law Text:', this.lawText);
    this.onStepBack();
  }

  /**
   * Navs back to Pick Broker page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/pick-broker']);
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data on 401 errors.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * Email Insurance click handler.
   */
  protected onEmailInsurance(): void {
    const __SUBTAG = 'onEmailInsurance';

    this.sendingEmail = true;
    this.cotizadorService.doEmailInsurance()
      .subscribe((resultOrError: string | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Cotizador doEmailInsurance resulted in error.');
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        _LOGGER.debug(_TAG, __SUBTAG, 'Cotizador doEmailInsurance completed.');
        this.modalsService.showSuccessModal(resultOrError || 'La cotización ha sido enviada.', 'Envío exitoso');
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador doEmailInsurance.');
        this.onError(error);
      }, () => {
        this.sendingEmail = false;
      });
  }

}
