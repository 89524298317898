// Core.
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {ClientDataModel} from '../../../../models/client-data.model';
import {IQuoteInsuranceStep2AboutYou} from '../../../../services/session/i-quote-insurance-step2-about-you';
import {PersonActivityModel} from '../../../../models/person-activity.model';
import {PersonMaritalStatusModel} from '../../../../models/person-marital-status.model';
import {PersonNationalityModel} from '../../../../models/person-nationality.model';
import {PersonSocietyKindModel} from '../../../../models/person-society-kind.model';
// Services.
import {CompraService} from '../../../../services/compra/compra.service';
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {PersonService} from '../../../../services/person/person.service';
import {SessionService} from '../../../../services/session/session.service';
import {TaxValidationService} from '../../../../services/tax-validation/tax-validation.service';
// Shared.
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'MoreAboutYouPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * More About You page. "Cotizar > Solicitar Seguro > Paso #2-1: Contanos más de vos".
 */
@Component({
  selector: 'app-more-about-you-page',
  templateUrl: './more-about-you-page.component.html',
  styleUrls: ['./more-about-you-page.component.scss']
})
export class MoreAboutYouPageComponent implements OnInit {
  protected personData: IQuoteInsuranceStep2AboutYou;

  /* Form controls. */
  @ViewChild('moreAboutYouForm') protected moreAboutYouForm: NgForm;
  @ViewChild('inputPersonSurname') protected inputPersonSurname: ElementRef;
  @ViewChild('inputPersonCuit') protected inputPersonCuit: ElementRef;
  protected disableForm: boolean;
  protected personName: string;
  protected personBusinessName: string;
  protected personSurname: string;
  protected personCuit: string;
  protected clientData: ClientDataModel;
  protected nationalities: PersonNationalityModel[];
  protected personCitizenship: PersonNationalityModel;
  protected maritalStatusCollection: PersonMaritalStatusModel[];
  protected personMaritalStatus: PersonMaritalStatusModel;
  protected personActivities: PersonActivityModel[];
  protected personActivity: PersonActivityModel;
  protected personSocietyKinds: PersonSocietyKindModel[];
  protected personSocietyKind: PersonSocietyKindModel;
  protected loadingPersonCitizenships: boolean;
  protected loadingPersonMaritalStatuses: boolean;
  protected loadingPersonActivities: boolean;
  protected loadingPersonSocietyKinds: boolean;
  protected loadingClientData: boolean;

  private changedPersonName: boolean;
  private changedPersonSurname: boolean;

  protected lockPersonName: boolean;
  protected lockPersonBusinessName: boolean;
  protected lockPersonSurname: boolean;
  protected lockPersonCuit: boolean;
  protected lockPersonCitizenship: boolean;
  protected lockPersonSocietyKind: boolean;

  private firstActivityCodeReturned: string;

  constructor(
    private router: Router,
    private errorService: ErrorService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private personService: PersonService,
    private compraService: CompraService,
    public taxValidationService: TaxValidationService
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  protected get formIsMissingData(): boolean {
    const isIndividual: boolean = !!(this.personData && this.personData.personTypeIsIndividual);
    return ((isIndividual && !this.personName) || (!isIndividual && !this.personBusinessName) ||
      !this.personActivity || (isIndividual && (!this.personSurname || !this.personCuit || !this.personCitizenship ||
        !this.personMaritalStatus)));
  }

  /**
   * Returns TRUE if the CUIT is valid or if the person is not type Individual.
   */
  public isDocumentValid(): boolean {
    return !this.personData || !this.personData.personTypeIsIndividual || this.taxValidationService.isCuitValid(this.personCuit,
      this.personData.personTypeIsIndividual ? 'F' : 'J', this.personData.personDocument);
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;
    Promise.all([
      // Get Client Data.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingClientData = true;
          this.compraService.doObtainClientData().subscribe((resultOrError: ClientDataModel | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getClientData resulted in error.');
              reject(resultOrError);
              return;
            }

            // Succeed.
            _LOGGER.debug(_TAG, __SUBTAG, 'ClientData initialized.');
            this.clientData = resultOrError;
            resolve(this.clientData);
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getClientData.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing ClientData.', e);
          reject(e);
        } finally {
          this.loadingClientData = false;
        }
      }),

      // Get Nationalities.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        // Skip when person is Enterprise type.
        if (!this.personData.personTypeIsIndividual) {
          this.nationalities = [];
          resolve(this.nationalities);
          return;
        }

        try {
          this.loadingPersonCitizenships = true;
          this.personService.getNationalities()
            .subscribe((resultOrError: PersonNationalityModel[] | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getNationalities resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Nationalities.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Nationalities initialized.');
                this.nationalities = resultOrError;
                resolve(this.nationalities);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getNationalities.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Nationalities.', e);
          reject(e);
        } finally {
          this.loadingPersonCitizenships = false;
        }
      }),

      // Get Marital Status.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        // Skip when person is Enterprise type.
        if (!this.personData.personTypeIsIndividual) {
          this.maritalStatusCollection = [];
          resolve(this.maritalStatusCollection);
          return;
        }

        try {
          this.loadingPersonMaritalStatuses = true;
          this.personService.getMaritalStatus()
            .subscribe((resultOrError: PersonMaritalStatusModel[] | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getMaritalStatus resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Marital Status.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Marital Status initialized.');
                this.maritalStatusCollection = resultOrError;
                resolve(this.maritalStatusCollection);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getMaritalStatus.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Marital Status.', e);
          reject(e);
        } finally {
          this.loadingPersonMaritalStatuses = false;
        }
      }),

      // Get Activities.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingPersonActivities = true;
          const personType = this.personData.personTypeIsIndividual ? 'person' : 'company';
          this.personService.getActivities(personType).subscribe((resultOrError: PersonActivityModel[] | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getActivities resulted in error.');
              reject(resultOrError);
              return;
            }

            // Succeed.
            if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Activities.');
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Activities initialized.');
              this.personActivities = resultOrError;
              this.firstActivityCodeReturned = this.personActivities[0].code;
              this.orderPersonActivitiesByName();
              resolve(this.personActivities);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getActivities.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Activities.', e);
          reject(e);
        } finally {
          this.loadingPersonActivities = false;
        }
      }),

      // Get Societies Kind.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingPersonSocietyKinds = true;
          this.personService.getSocietyKinds()
            .subscribe((resultOrError: PersonSocietyKindModel[] | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getSocietyKinds resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Society Kinds.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Society Kinds initialized.');
                this.personSocietyKinds = resultOrError;
                resolve(this.personSocietyKinds);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getSocietyKinds.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Society Kinds.', e);
          reject(e);
        } finally {
          this.loadingPersonSocietyKinds = false;
        }
      })
    ])
      .then(() => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
        this.formReset();
      })
      .catch(error => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
        this.onError(error);
      });
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.moreAboutYouForm && this.moreAboutYouForm.pristine) {
      this.moreAboutYouForm.control.reset();
    }

    const prevData = this.sessionService.getBuyInsuranceStep1MoreAboutYou();

    this.lockPersonName = !!(this.personData.personTypeIsIndividual && this.clientData && this.clientData.name);
    this.personName = (this.lockPersonName && this.clientData.name) || (prevData && prevData.personName) || null;
    this.onChangePersonName();
    this.changedPersonName = false;

    this.lockPersonBusinessName = !!(!this.personData.personTypeIsIndividual && this.clientData && this.clientData.businessName);
    this.personBusinessName = (this.lockPersonBusinessName && this.clientData.businessName) || (prevData && prevData.personBusinessName) || null;
    this.onChangePersonBusinessName();

    this.lockPersonSurname = !!(this.personData.personTypeIsIndividual && this.clientData && this.clientData.lastName);
    this.personSurname = (this.lockPersonSurname && this.clientData.lastName) || (prevData && prevData.personSurname) || null;
    this.onChangePersonSurname();
    this.changedPersonSurname = false;

    this.lockPersonCuit = !!(this.personData.personTypeIsIndividual && this.clientData && this.clientData.cuit && this.clientData.cuit != '0');
    this.personCuit = (this.lockPersonCuit && this.clientData.cuit) || (prevData && prevData.personCuit) || null;
    this.onChangePersonCuit();

    this.lockPersonCitizenship = !!(this.personData.personTypeIsIndividual && this.clientData && this.clientData.citizenshipCode);
    const selectPersonCitizenshipCode: string = (this.lockPersonCitizenship && this.clientData.citizenshipCode) || (prevData &&
      prevData.personCitizenship && prevData.personCitizenship.code) || _CONFIG.individualNationalityCode;
    this.personCitizenship = (this.personData.personTypeIsIndividual && this.nationalities.find(
      nation => (nation.code === selectPersonCitizenshipCode))) || null;
    this.onChangePersonCitizenship();
    if (this.lockPersonCitizenship && !this.personCitizenship) {
      this.onError('No se pudo recuperar la nacionalidad correspondiente al cliente obtenido.', false, true);
      this.lockPersonCitizenship = false;
    }

    const selectPersonMaritalStatusCode: string = (this.personData.personTypeIsIndividual && this.clientData &&
      this.clientData.maritalStatusCode) || (prevData && prevData.personMaritalStatus && prevData.personMaritalStatus.code);
    this.personMaritalStatus = (this.personData.personTypeIsIndividual && selectPersonMaritalStatusCode && this.maritalStatusCollection
      .find(status => (status.code === selectPersonMaritalStatusCode))) || null;
    this.onChangePersonMaritalStatus();

    const selectPersonActivityCode: string = (this.clientData && this.clientData.activityCode) || (prevData &&
      prevData.personActivity && prevData.personActivity.code) || this.firstActivityCodeReturned || null;
    this.personActivity = (selectPersonActivityCode && this.personActivities.find(act => (act.code === selectPersonActivityCode))) || null;
    this.onChangePersonActivity();

    this.lockPersonSocietyKind = !!(!this.personData.personTypeIsIndividual && this.clientData && this.clientData.societyTypeCode);
    const selectPersonSocietyKindCode: string = (this.lockPersonSocietyKind && this.clientData.societyTypeCode) || (prevData &&
      prevData.personSocietyKind && prevData.personSocietyKind.code);
    this.personSocietyKind = (!this.personData.personTypeIsIndividual && selectPersonSocietyKindCode && this.personSocietyKinds.find(
      psk => (psk.code === selectPersonSocietyKindCode))) || null;
    this.onChangePersonSocietyKind();
    if (this.lockPersonSocietyKind && !this.personSocietyKind) {
      this.onError('No se pudo recuperar el tipo de sociedad correspondiente al cliente obtenido.', false, true);
      this.lockPersonSocietyKind = false;
    }

    this.disableForm = false;
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks steps #2 data.
   * Initializes form.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      if (this.personData) {
        this.formInit();
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Navs back on errors.
   * Resets session & Cotizador data on 401 or fatal errors.
   */
  private onError(error: any, isFatalError: boolean = false, stayOnPage: boolean = false): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (isFatalError || (appError.getCode() === ErrorService.getApiHttpErrorCode(401))) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            if (!stayOnPage) {
              this.onStepBack();
            }
          });
      });
  }

  /**
   * Navs to Address Data page.
   */
  protected onContinue(): void {
    const __SUBTAG = 'onContinue';

    this.sessionService.setBuyInsuranceStep1MoreAboutYou({
      personName: this.personData.personTypeIsIndividual ? this.personName : null,
      personBusinessName: this.personData.personTypeIsIndividual ? null : this.personBusinessName,
      personSurname: this.personData.personTypeIsIndividual ? this.personSurname : null,
      personCuit: this.personData.personTypeIsIndividual ? this.personCuit : null,
      personCitizenship: this.personData.personTypeIsIndividual ? this.personCitizenship : null,
      personMaritalStatus: this.personData.personTypeIsIndividual ? this.personMaritalStatus : null,
      personActivity: this.personActivity,
      personSocietyKind: this.personData.personTypeIsIndividual ? null : this.personSocietyKind
    });

    // Person fraud listing check.
    this.personService.doCheckPersonFraud(this.personData.personTypeIsIndividual,
      (this.personData.personTypeIsIndividual ? this.personSurname : null),
      (this.personData.personTypeIsIndividual ? this.personName : this.personBusinessName),
      parseInt((this.personData.personTypeIsIndividual ? this.personCuit : this.personData.personDocument), 10)
    )
      .subscribe((resultOrError: boolean | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'doCheckPersonFraud resulted in error.');
          this.onError(resultOrError, true);
          return;
        }

        // Succeed.
        if (resultOrError) {
          _LOGGER.debug(_TAG, __SUBTAG, 'doCheckPersonFraud completed.');
          if (this.sessionService.isPersonFraudListed()) {
            this.onError(new Error('La persona ingresada no ha superado la validación de fraude.'), true);
          }
        } else {
          _LOGGER.error(_TAG, __SUBTAG, 'doCheckPersonFraud failed.');
          this.onError(new Error('Ha ocurrido un error durante la validación de fraude.'), true);
        }
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on doCheckPersonFraud.');
        this.onError(error, true);
      });

    this.router.navigate(['/insurance/address-data']);
  }

  /**
   * Navs back to Contact Data.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/contact-data']);
  }

  /**
   * User changes Person Name data handler.
   */
  protected onChangePersonName(): void {
    if (this.personName) {
      this.personName = Helper.getInstance().sanitizeInputAlphabetic(this.personName).toUpperCase();
    }
    this.changedPersonName = true;
  }

  /**
   * Person Name blur handler.
   */
  protected onBlurPersonName(): void {
    if (this.personName) {
      this.personName = Helper.getInstance().sanitizeInputAlphabetic(this.personName);
    }
    if (this.personData.personTypeIsIndividual && this.personName && this.changedPersonName) {
      this.changedPersonName = false;
      setTimeout(() => {
        if (this.inputPersonSurname && this.inputPersonSurname.nativeElement &&
          this.inputPersonSurname.nativeElement.focus) {
          this.inputPersonSurname.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Name input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonName(event: KeyboardEvent): void {
    setTimeout(() => {
      if (this.personName) {
        this.personName = Helper.getInstance().sanitizeInputAlphabetic(this.personName);
      }
    }, 10);

    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonName();
    }
  }

  /**
   * User changes Person Business Name data handler.
   */
  protected onChangePersonBusinessName(): void {
    if (this.personBusinessName) {
      this.personBusinessName = this.personBusinessName.toUpperCase().trim();
    }
  }

  /**
   * User changes Person Surname data handler.
   */
  protected onChangePersonSurname(): void {
    if (this.personSurname) {
      // Sanitize input.
      this.personSurname = Helper.getInstance().sanitizeInputAlphabetic(this.personSurname).toUpperCase();
    }
    this.changedPersonSurname = true;
  }

  /**
   * Person Surname blur handler.
   */
  protected onBlurPersonSurname(): void {
    if (this.personSurname) {
      this.personSurname = Helper.getInstance().sanitizeInputAlphabetic(this.personSurname);
    }
    if (this.personData.personTypeIsIndividual && this.personSurname && this.changedPersonSurname) {
      this.changedPersonSurname = false;
      setTimeout(() => {
        if (this.inputPersonCuit && this.inputPersonCuit.nativeElement &&
          this.inputPersonCuit.nativeElement.focus) {
          this.inputPersonCuit.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Surname input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonSurname(event: KeyboardEvent): void {
    setTimeout(() => {
      if (this.personSurname) {
        this.personSurname = Helper.getInstance().sanitizeInputAlphabetic(this.personSurname);
      }
    }, 10);

    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonSurname();
    }
  }

  /**
   * User changes Person CUIT data handler.
   */
  protected onChangePersonCuit(): void {
    // No action needed.
  }

  /**
   * User changes Person Citizenship data handler.
   */
  protected onChangePersonCitizenship(): void {
    // No action needed.
  }

  /**
   * User changes Person Marital Status data handler.
   */
  protected onChangePersonMaritalStatus(): void {
    // No action needed.
  }

  /**
   * User changes Person Activity data handler.
   */
  protected onChangePersonActivity(): void {
    if (this.personActivity === null && this.personActivities && this.personActivities.length > 0) {
      this.personActivity = this.personActivities[0];
    }
  }

  /**
   * User changes Person Society Kind data handler.
   */
  protected onChangePersonSocietyKind(): void {
    // No action needed.
  }

  /**
   * Order array bank by name
   */
  protected orderPersonActivitiesByName(): void {
    this.personActivities.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }
}
