// Core.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Components.
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'MainRoutingModule';
_LOGGER.debug(_TAG, 'loaded.');


const _ROUTES: Routes = [
  {path: 'home', component: HomePageComponent},
  {path: 'error', component: ErrorPageComponent}
];

@NgModule({
  imports: [RouterModule.forChild(_ROUTES)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
