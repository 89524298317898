// Core.
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
// Shared.
import { EnvironmentManager } from '../../../../shared/environment-manager.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _ALLOWED_KEY_CODES: string[] = [
  'Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumpadEnter', 'Home', 'End', 'ArrowLeft', 'ArrowRight'
];
const _ALLOWED_KEY_CTRL_CODES: string[] = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];
const _DIGIT_KEY_CODES: string[] = [
  'Digit0', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
  'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
];

const _ALLOWED_KEY_NUMBERS: number[] = [
  46, // Delete.
  8, // Backspace.
  9, // Tab.
  27, // Escape.
  13, // Enter/NumpadEnter.
  35, // End.
  36, // Home.
  37, // ArrowLeft.
  39 // ArrowRight.
];
const _ALLOWED_KEY_CTRL_NUMBERS: number[] = [
  65, // "A".
  67, // "C".
  86, // "V".
  88 // "X".
];
const _DIGIT_KEY_NUMBERS: number[] = [
  48, 49, 50, 51, 52, 53, 54, 55, 56, 57, // Numbers 0-9.
  96, 97, 98, 99, 100, 101, 102, 103, 104, 105 // Numpad 0-9.
];


/**
 * Directive that limits the characters allowed on an input element to be only digits 0-9.
 * Also limit the command controls and copy&paste and drag&drop operations to only insert digits 0-9.
 */
@Directive({
  selector: '[appNumericOnlyInput]'
})
export class NumericOnlyInputDirective {
  @Input()
  public allowMilesSymbol = false;
  @Input()
  public allowDecimalsSymbol = false;

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if ( // Single keys.
      (_ALLOWED_KEY_NUMBERS.indexOf(e.keyCode) !== -1) || (_ALLOWED_KEY_CODES.indexOf(e.code) !== -1) ||
      // Control/Commands.
      (((_ALLOWED_KEY_CTRL_NUMBERS.indexOf(e.keyCode) !== -1) || (_ALLOWED_KEY_CTRL_CODES.indexOf(e.code) !== -1)) &&
        (e.ctrlKey || e.metaKey)) ||
      // Separators.
      (this.allowDecimalsSymbol && (e.key === _CONFIG.decimalSeparator)) ||
      (this.allowMilesSymbol && (e.key === _CONFIG.milesSeparator))) {
      return; // Allow.
    }

    // Ensure that it is a number and stop the keypress.
    if (e.shiftKey || ((_DIGIT_KEY_NUMBERS.indexOf(e.keyCode) === -1) && (_DIGIT_KEY_CODES.indexOf(e.code) === -1))) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(e: ClipboardEvent): void {
    e.preventDefault();

    const pastedInput: string = e.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // Extract only the numbers.
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  public onDrop(e: any): void { // NOTE: "any" should be "DragEvent", but Angular doesn't load on Safari if "DragEvent" is used.
    e.preventDefault();

    const textData = e.dataTransfer
      .getData('text')
      .replace(/\D/g, ''); // Extract only the numbers.
    this.el.focus();
    document.execCommand('insertText', false, textData);
  }
}
