import {Injectable} from '@angular/core';
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {SimpleLogger} from '../../shared/simple-logger.shared';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {ErrorService} from '../error/error.service';
import {Observable, of} from 'rxjs';
import {BaseAppError} from '../error/base-app-error';
import {catchError, map, tap} from 'rxjs/operators';
import {PublicAccessContinueByInfo} from './public-access-continue-by-info';
import {GetPublicAccessInfoResponse, GetPublicAccessInfoResponseTimeSlot} from './get-public-access-info-response';
import {PublicAccessContinueByMethod} from './public-access-continue-by-method';
import {CotizadorService} from '../cotizador/cotizador.service';
import {SessionService} from '../session/session.service';

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PublicAccessService';
_LOGGER.debug(_TAG, 'loaded.');

const CONTINUE_BY_TIME_SEPARATOR = ':';
const CONTINUE_BY_DEFAULT_INFO = {
  USER_CALL_PHONE: null,
  USER_CALL_OUT_OF_HOURS_MSG: null,
  FROM_DAY: 1,
  TO_DAY: 5,
  FROM_HOUR: 9,
  FROM_MINUTE: 0,
  TO_HOUR: 18,
  TO_MINUTE: 0,
  WHATSAPP_PHONE: null,
  WHATSAPP_MSG: null,
  CALLBACK_TIME_SLOTS: []
};

/**
 * Public Access Service.
 */
@Injectable({providedIn: 'root'})
export class PublicAccessService {
  private baseURL = `${_CONFIG.apiBaseURL}/solicitud`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private cotizadorService: CotizadorService, // Do not remove this dependency even if not used.
    private sessionService: SessionService
  ) {
    const __SUBTAG = 'constructor';
    _LOGGER.debug(_TAG, __SUBTAG, 'Service created.');
  }

  /**
   * Get Public Access' channel Continue By information for various methods.
   */
  public getContinueByInfo(): Observable<PublicAccessContinueByInfo | BaseAppError> {
    const __SUBTAG = 'getContinueByInfo';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/continueby/info`;
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

    return this.http.get<GetPublicAccessInfoResponse>(endpointURL, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<GetPublicAccessInfoResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<GetPublicAccessInfoResponse>) => {
        if (!response.body || !response.body.user_call || !response.body.whatsapp || !response.body.callback) {
          throw new Error('Se produjo un error al recuperar los datos de contacto. Intente nuevamente más tarde.');
        }
        const hoursMinutesFrom = ((response.body.user_call.business_hour_from ||
            `${CONTINUE_BY_DEFAULT_INFO.FROM_HOUR}${CONTINUE_BY_TIME_SEPARATOR}${CONTINUE_BY_DEFAULT_INFO.FROM_MINUTE}`)
          + CONTINUE_BY_TIME_SEPARATOR).split(CONTINUE_BY_TIME_SEPARATOR);
        const hoursMinutesTo = ((response.body.user_call.business_hour_to ||
            `${CONTINUE_BY_DEFAULT_INFO.TO_HOUR}${CONTINUE_BY_TIME_SEPARATOR}${CONTINUE_BY_DEFAULT_INFO.TO_MINUTE}`)
          + CONTINUE_BY_TIME_SEPARATOR).split(CONTINUE_BY_TIME_SEPARATOR);
        return {
          userCall: {
            businessPhone: response.body.user_call.business_phone || CONTINUE_BY_DEFAULT_INFO.USER_CALL_PHONE,
            outOfBusinessHourMessage: response.body.user_call.out_of_business_hour_message ||
              CONTINUE_BY_DEFAULT_INFO.USER_CALL_OUT_OF_HOURS_MSG,
            businessDayFrom: response.body.user_call.business_day_from || CONTINUE_BY_DEFAULT_INFO.FROM_DAY,
            businessDayTo: response.body.user_call.business_day_to || CONTINUE_BY_DEFAULT_INFO.TO_DAY,
            businessHourFrom: parseInt(hoursMinutesFrom[0], 10) || CONTINUE_BY_DEFAULT_INFO.FROM_HOUR,
            businessHourTo: parseInt(hoursMinutesTo[0], 10) || CONTINUE_BY_DEFAULT_INFO.TO_HOUR,
            businessMinuteFrom: parseInt(hoursMinutesFrom[1], 10) || CONTINUE_BY_DEFAULT_INFO.FROM_MINUTE,
            businessMinuteTo: parseInt(hoursMinutesTo[1], 10) || CONTINUE_BY_DEFAULT_INFO.TO_MINUTE
          },
          whatsapp: {
            phone: response.body.whatsapp.phone || CONTINUE_BY_DEFAULT_INFO.WHATSAPP_PHONE,
            message: response.body.whatsapp.message || CONTINUE_BY_DEFAULT_INFO.WHATSAPP_MSG
          },
          callback: {
            timeSlots: response.body.callback.time_slots || CONTINUE_BY_DEFAULT_INFO.CALLBACK_TIME_SLOTS
          }
        };
      }),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Signal User Continue By method selection.
   */
  public selectContinueByMethod(method: PublicAccessContinueByMethod,
                                timeSlot: GetPublicAccessInfoResponseTimeSlot): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'selectContinueByMethod';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const broker = this.sessionService.getSelectedBroker();
    const endpointURL = `${this.baseURL}/solicitar/cliente/${broker.code}/${broker.insuranceQuote.id}/${broker.selectedInsuranceOption.id}`;
    const payload = {
      mode: method,
      trackId: CotizadorService.getInsuranceQuoteTrackId(),
      slot: null
    };
    if (method === PublicAccessContinueByMethod.CALLBACK) {
      payload.slot = timeSlot.id;
    } else {
      delete payload.slot;
    }
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<any>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<any>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<any>) => response.ok),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }
}
