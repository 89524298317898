// Components.
import {AlertModalComponent} from './components/alert-modal/alert-modal.component';
import {ErrorModalComponent} from './components/error-modal/error-modal.component';
import {ErrorPageComponent} from './pages/error-page/error-page.component';
import {LoadingModalComponent} from './components/loading-modal/loading-modal.component';
import {PageHeaderComponent} from './components/page-header/page-header.component';
import { PageHeaderQuoteInfoComponent } from './components/page-header-quote-info/page-header-quote-info.component';
import {PageFooterComponent} from './components/page-footer/page-footer.component';
import {SuccesModalComponent} from './components/succes-modal/succes-modal.component';
import {WorkflowComponent} from './components/workflow/workflow.component';
// Directives.
import {AlphabeticOnlyInputDirective} from './directives/alphabetic-only-input/alphabetic-only-input.directive';
import {FloatFormatterInputDirective} from './directives/float-formatter-input/float-formatter-input.directive';
import {NumericOnlyInputDirective} from './directives/numeric-only-input/numeric-only-input.directive';
// Modules.
import {CommonModule} from '@angular/common';
import {MainRoutingModule} from './main-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
// Pages.
import {HomePageComponent} from './pages/home-page/home-page.component';
// Pipes.
import {TextTruncatePipe} from './pipes/text-truncate/text-truncate.pipe';
// Shared.
import {SimpleLogger} from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'MainModule';
_LOGGER.debug(_TAG, 'loaded.');


@NgModule({
  declarations: [
    AlertModalComponent,
    AlphabeticOnlyInputDirective,
    ErrorModalComponent,
    ErrorPageComponent,
    FloatFormatterInputDirective,
    HomePageComponent,
    LoadingModalComponent,
    NumericOnlyInputDirective,
    PageHeaderComponent,
    PageFooterComponent,
    SuccesModalComponent,
    TextTruncatePipe,
    WorkflowComponent,
    PageHeaderQuoteInfoComponent,
  ],
  imports: [
    // Core.
    CommonModule,
    NgbModule,
    FormsModule,
    // Custom.
    MainRoutingModule
  ],
  exports: [
    AlertModalComponent,
    AlphabeticOnlyInputDirective,
    ErrorModalComponent,
    FloatFormatterInputDirective,
    LoadingModalComponent,
    NumericOnlyInputDirective,
    PageHeaderComponent,
    PageFooterComponent,
    SuccesModalComponent,
    TextTruncatePipe,
    WorkflowComponent,
    PageHeaderQuoteInfoComponent,
  ]
})
export class MainModule {
}
