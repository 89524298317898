export class PersonDocumentTypeModel {
  public code: string;
  public name: string;
  public exclusiveForPersonTypeIndividual: boolean;
  public exclusiveForPersonTypeEnterprise: boolean;

  constructor(code: string, name: string, exclusiveForPersonTypeIndividual: boolean = true,
              exclusiveForPersonTypeEnterprise: boolean = false) {
    this.code = code;
    this.name = name;
    this.exclusiveForPersonTypeIndividual = exclusiveForPersonTypeIndividual;
    this.exclusiveForPersonTypeEnterprise = exclusiveForPersonTypeEnterprise;
  }
}
