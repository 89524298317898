export class CardModel {
  public type: string;
  public number: string;
  public brand: string;

  constructor(type: string, number: string, brand: string) {
    this.type = type;
    this.number = number;
    this.brand = brand;
  }
}
