// Core.
import { Component, Input, OnInit } from '@angular/core';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'SuccesModalComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Success Modal component.
 */
@Component({
  selector: 'app-succes-modal',
  templateUrl: './succes-modal.component.html',
  styleUrls: ['./succes-modal.component.scss']
})
export class SuccesModalComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }


  /* Handlers. */

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.activeModal.close('Accept');
  }
}
