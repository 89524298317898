// Core.
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
// Directives.
import {FloatFormatterInputDirective} from '../../../main/directives/float-formatter-input/float-formatter-input.directive';
// Models.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {VehicleBrandModel} from '../../../../models/vehicle-brand.model';
import {VehicleBrandsWeightedModel} from '../../../../models/vehicle-brands-weighted.model';
import {VehicleGncTypeModel} from '../../../../models/vehicle-gnc-type.model';
import {VehicleModelModel} from '../../../../models/vehicle-model.model';
import {VehicleUseTypeModel} from '../../../../models/vehicle-use-type.model';
import {VehicleVersionModel} from '../../../../models/vehicle-version.model';
import {VehicleYearModel} from '../../../../models/vehicle-year.model';
// Services.
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
import {VehiclesService} from '../../../../services/vehicles/vehicles.service';
// Shared.
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerToDateAdapter} from '../../../../services/date-picker-to-date.adapter';
import {DatePickerStringParserFormatter} from '../../../../services/date-picker-string-parser.formatter';
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {DatePickerUtilsService} from '../../../../services/date-picker-utils.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'VehicleDataPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Vehicle Data page. "Cotizar - Paso #1-1: Datos del vehículo".
 */
@Component({
  selector: 'app-vehicle-data-page',
  templateUrl: './vehicle-data-page.component.html',
  styleUrls: ['./vehicle-data-page.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: DatePickerToDateAdapter},
    {provide: NgbDateParserFormatter, useClass: DatePickerStringParserFormatter}
  ]
})
export class VehicleDataPageComponent implements OnInit {
  /* Form controls. */
  public disableForm: boolean;
  public vehicleYears: VehicleYearModel[];
  public vehicleYear: VehicleYearModel;
  public vehicleBrandsWeighted: VehicleBrandsWeightedModel;
  public vehicleBrand: VehicleBrandModel;
  public vehicleModel: VehicleModelModel;
  public vehicleVersion: VehicleVersionModel;
  public vehicleHasGnc: boolean;
  public vehicleGncTypes: VehicleGncTypeModel[];
  public vehicleGncType: VehicleGncTypeModel;
  public vehicleGncValue: string;
  public vehicleGncValueAsNumber: number;
  public vehicleUseTypes: VehicleUseTypeModel[];
  public vehicleUseType: VehicleUseTypeModel;
  public insuranceValidityDateSince: Date;
  public insuranceValidityDateSinceStartDate: NgbDateStruct;
  public insuranceValidityDateSinceMinDate: NgbDateStruct;
  public insuranceValidityDateSinceMaxDate: NgbDateStruct;
  public isValidityDateSinceEnabled: boolean;
  public loadingVehicleYears: boolean;
  public loadingVehicleBrands: boolean;
  public loadingVehicleModels: boolean;
  public loadingVehicleVersions: boolean;
  public loadingVehicleGncTypes: boolean;
  public loadingVehicleUseTypes: boolean;
  public loadingInsuranceValidityDateSinceInfo: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private errorService: ErrorService,
    private modalsService: ModalsService,
    private vehiclesService: VehiclesService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService,
    private datePickerUtilsService: DatePickerUtilsService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  public get formIsMissingData(): boolean {
    return (!this.vehicleYear || !this.vehicleBrand || !this.vehicleModel || !this.vehicleVersion || !this.vehicleUseType ||
      (this.vehicleHasGnc && (!this.vehicleGncType || !this.vehicleGncValueAsNumber)) ||
      (this.isValidityDateSinceEnabled && !this.insuranceValidityDateSince));
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.debug(_TAG, __SUBTAG, 'Start.');

    const prevData = this.sessionService.getQuoteInsuranceStep1VehicleData();
    const helper = Helper.getInstance();

    this.vehicleYear = null;
    if (prevData && prevData.vehicleYear) {
      this.vehicleYear = this.vehicleYears.find(vy => helper.equalsByProps(vy, prevData.vehicleYear, ['year', 'brandNew']));
    }
    if (!this.vehicleYear) {
      this.vehicleYear = this.vehicleYears[1];
    }
    this.onChangeVehicleYear();

    this.vehicleBrand = null;
    if (prevData && prevData.vehicleBrand) {
      this.vehicleBrand = [this.vehicleBrandsWeighted.mainBrand]
        .concat(this.vehicleBrandsWeighted.frequentBrands)
        .concat(this.vehicleBrandsWeighted.otherBrands)
        .find(vb => helper.equalsByProps(vb, prevData.vehicleBrand, ['id']));
    }
    if (prevData && prevData.vehicleModel && prevData.vehicleVersion) {
      this.onChangeVehicleBrand(prevData.vehicleModel, prevData.vehicleVersion);
    } else {
      this.onChangeVehicleBrand();
    }

    if (prevData) {
      this.vehicleHasGnc = !!prevData.vehicleHasGnc;
    } else {
      this.vehicleHasGnc = false;
    }
    this.onChangeVehicleHasGnc(true);

    let selectGncTypeCode: string = _CONFIG.defaultVehicleGncTypeCode;
    if (prevData && prevData.vehicleHasGnc && prevData.vehicleGncType) {
      selectGncTypeCode = prevData.vehicleGncType.code;
    }
    this.vehicleGncType = this.vehicleGncTypes.find(gncType => (gncType.code === selectGncTypeCode));
    if (!this.vehicleGncType) {
      this.vehicleGncType = this.vehicleGncTypes[0];
    }
    this.onChangeVehicleGncType();

    if (prevData && prevData.vehicleHasGnc) {
      this.vehicleGncValue = FloatFormatterInputDirective.parseInputToString(prevData.vehicleGncValue, true);
      this.onChangeVehicleGncValue();
    }

    let selectUseTypeCode: string = _CONFIG.defaultVehicleUseTypeCode;
    if (prevData && prevData.vehicleUseType) {
      selectUseTypeCode = prevData.vehicleUseType.code;
    }
    this.vehicleUseType = this.vehicleUseTypes.find(useType => (useType.code === selectUseTypeCode));
    if (!this.vehicleUseType) {
      this.vehicleUseType = this.vehicleUseTypes[0];
    }
    this.onChangeVehicleUseType();

    if (prevData && prevData.insuranceValidityDateSince) {
      this.insuranceValidityDateSince = prevData.insuranceValidityDateSince;
      this.onChangeInsuranceValidityDateSince(true);
    }

    this.disableForm = false;
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.debug(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;
    Promise.all([
      // Get vehicle years.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingVehicleYears = true;
          const currentYear = new Date().getFullYear();
          const currentYears: VehicleYearModel[] = [new VehicleYearModel(currentYear, false), new VehicleYearModel(currentYear, true)];
          this.vehicleYears = currentYears.concat(
            (Array.apply(null, {length: _CONFIG.vehicleYearsAgoToConsider}) as any[])
              .map((v, i) => new VehicleYearModel(currentYear - (i + 1))));

          if (!this.vehicleYears || !this.vehicleYears.length || (this.vehicleYears.length < 1)) {
            reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
          }

          resolve();
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Vehicle Years.', e);
          reject(e);
        } finally {
          this.loadingVehicleYears = false;
        }
      }),

      // Get Vehicle Brands.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingVehicleBrands = true;
          this.vehiclesService.getVehicleBrandsWeighted()
            .subscribe((resultOrError: VehicleBrandsWeightedModel | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getVehicleBrands resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.mainBrand) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Vehicle Brands.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Vehicle Brands initialized.');
                this.vehicleBrandsWeighted = resultOrError;
                resolve(this.vehicleBrandsWeighted);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getVehicleBrands.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Vehicle Brands.', e);
          reject(e);
        } finally {
          this.loadingVehicleBrands = false;
        }
      }),

      // Get Vehicle GNC Types values.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingVehicleGncTypes = true;
          this.vehiclesService.getVehicleGncTypes()
            .subscribe((resultOrError: VehicleGncTypeModel[] | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getVehicleGncTypes resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Vehicle GNC Types.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Vehicle GNC Types initialized.');
                this.vehicleGncTypes = resultOrError;
                resolve(this.vehicleGncTypes);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getVehicleGncTypes.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Vehicle GNC/Use Types.', e);
          reject(e);
        } finally {
          this.loadingVehicleGncTypes = false;
        }
      }),

      // Get Vehicle Use Types values.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingVehicleUseTypes = true;
          this.vehiclesService.getVehicleUseTypes()
            .subscribe((resultOrError: VehicleUseTypeModel[] | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getVehicleUseTypes resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Vehicle Use Types.');
                reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
              } else {
                _LOGGER.debug(_TAG, __SUBTAG, 'Vehicle Use Types initialized.');
                this.vehicleUseTypes = resultOrError;
                resolve(this.vehicleUseTypes);
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getVehicleUseTypes.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Vehicle GNC/Use Types.', e);
          reject(e);
        } finally {
          this.loadingVehicleUseTypes = false;
        }
      }),

      // Get Insurance Validity Date Since info.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingInsuranceValidityDateSinceInfo = true;
          this.insuranceValidityDateSinceMinDate = this.datePickerUtilsService.dateToNgbDateStruct(new Date());
          this.cotizadorService.getInsuranceValidityDateInfo()
            .subscribe((resultOrError: { maxDays: number; isEnabled: boolean; } | BaseAppError) => {
              // Failed.
              if (resultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'getInsuranceValidityDateInfo resulted in error.');
                reject(resultOrError);
                return;
              }

              // Succeed.
              _LOGGER.debug(_TAG, __SUBTAG, 'Insurance Validity Date Since initialized.');
              this.isValidityDateSinceEnabled = resultOrError.isEnabled;
              const maxDate = new Date();
              maxDate.setDate(maxDate.getDate() + resultOrError.maxDays);
              this.insuranceValidityDateSinceMaxDate = this.datePickerUtilsService.dateToNgbDateStruct(maxDate);
              this.insuranceValidityDateSince = this.datePickerUtilsService.ngbDateStructToDate(this.insuranceValidityDateSinceMinDate);
              this.onChangeInsuranceValidityDateSince(true);
              resolve(this.isValidityDateSinceEnabled);
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on getInsuranceValidityDateInfo.');
              reject(error);
            });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Insurance Validity Date Since.', e);
          reject(e);
        } finally {
          this.loadingInsuranceValidityDateSinceInfo = false;
        }
      })
    ])
      .then(() => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
        this.formReset();
      })
      .catch(error => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
        this.onError(error);
      });
  }


  /* Handlers. */

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Resets session data on errors.
   */
  private onError(error: any, stayOnThisPage: boolean = false): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    this.sessionService.reset();

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.router
        .navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}})
        .finally(() => {
          this.viewportScroller.scrollToAnchor('app-main-header');
        });
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            if (!stayOnThisPage) {
              this.router
                .navigate(['/home'])
                .finally(() => {
                  this.viewportScroller.scrollToAnchor('app-main-header');
                });
            }
          });
      });
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    this.formInit();
  }

  /**
   * Navs to Step #1-2 (About You).
   */
  public onContinue(): void {
    this.sessionService.setQuoteInsuranceStep1VehicleData({
      vehicleYear: this.vehicleYear,
      vehicleBrand: this.vehicleBrand,
      vehicleModel: this.vehicleModel,
      vehicleVersion: this.vehicleVersion,
      vehicleHasGnc: this.vehicleHasGnc,
      vehicleGncType: this.vehicleHasGnc ? this.vehicleGncType : null,
      vehicleGncValue: this.vehicleHasGnc ? this.vehicleGncValueAsNumber : null,
      vehicleUseType: this.vehicleUseType,
      insuranceValidityDateSince: this.isValidityDateSinceEnabled ? this.insuranceValidityDateSince : null
    });
    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsuranceStep1VehicleData_Continue')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });
    this.router.navigate(['/insurance/about-you']);
  }

  /**
   * User changes Vehicle Year data handler.
   */
  public onChangeVehicleYear(): void {
    this.vehicleBrand = null;
    this.onChangeVehicleBrand();
  }

  /**
   * User changes Vehicle Brand data handler.
   */
  public onChangeVehicleBrand(selectVehicleModel: VehicleModelModel = null, selectVehicleVersion: VehicleVersionModel = null): void {
    const __SUBTAG = 'onChangeVehicleBrand';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    this.vehicleModel = null;

    if (this.vehicleBrand) {
      this.loadingVehicleModels = true;
      this.vehiclesService.getVehicleModelsByBrand(this.vehicleBrand, this.vehicleYear)
        .subscribe((resultOrError: VehicleModelModel[] | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'getVehicleModelsByBrand resulted in error.');
            this.onError(resultOrError);
            return;
          }

          // Succeed.
          if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Vehicle Models.');
            this.onError(new Error('No se obtuvieron modelos para la marca y año seleccionados. Por favor, realice otra selección.'),
              true);
          } else {
            _LOGGER.debug(_TAG, __SUBTAG, 'Vehicle Models retrieved.');
            if (this.vehicleBrand.models && this.vehicleBrand.models.length) {
              if (selectVehicleModel) {
                this.vehicleModel = this.vehicleBrand.models.find(vm => Helper.getInstance().equalsByProps(vm, selectVehicleModel, ['id']));
              }
              if (!this.vehicleModel && (this.vehicleBrand.models.length === 1)) {
                this.vehicleModel = this.vehicleBrand.models[0];
              }
            }
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on getVehicleModelsByBrand.');
          this.onError(error);
        }, () => {
          this.loadingVehicleModels = false;
          this.onChangeVehicleModel(selectVehicleVersion);
        });
    } else {
      this.onChangeVehicleModel();
    }
  }

  /**
   * User changes Vehicle Model data handler.
   */
  public onChangeVehicleModel(selectVehicleVersion: VehicleVersionModel = null): void {
    const __SUBTAG = 'onChangeVehicleModel';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    this.vehicleVersion = null;

    if (this.vehicleModel) {
      this.loadingVehicleVersions = true;
      this.vehiclesService.getVehicleVersionsByModel(this.vehicleModel, this.vehicleYear)
        .subscribe((resultOrError: VehicleVersionModel[] | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'getVehicleVersionsByModel resulted in error.');
            this.onError(resultOrError);
            return;
          }

          // Succeed.
          if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Vehicle Versions.');
            this.onError(new Error('No se obtuvieron versiones para el modelo y año seleccionados. Por favor, realice otra selección.'),
              true);
          } else {
            this.vehicleModel.versions = resultOrError;
            _LOGGER.debug(_TAG, __SUBTAG, 'Vehicle Versions retrieved.', resultOrError, this.vehicleModel.versions);
            if (this.vehicleModel.versions && this.vehicleModel.versions.length) {
              if (selectVehicleVersion) {
                this.vehicleVersion = this.vehicleModel.versions
                  .find(vv => Helper.getInstance().equalsByProps(vv, selectVehicleVersion, ['id']));
              }
              if (!this.vehicleVersion && (this.vehicleModel.versions.length === 1)) {
                this.vehicleVersion = this.vehicleModel.versions[0];
              }
            }
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on getVehicleVersionsByModel.');
          this.onError(error);
        }, () => {
          this.loadingVehicleVersions = false;
          this.onChangeVehicleVersion();
        });
    } else {
      this.onChangeVehicleVersion();
    }
  }

  /**
   * User changes Vehicle Version data handler.
   */
  public onChangeVehicleVersion(): void {
    // No action needed.
  }

  /**
   * User changes Vehicle Has GNC data handler.
   */
  public onChangeVehicleHasGnc(silent: boolean = false): void {
    // Resets related inputs value.
    if (!this.vehicleHasGnc && !silent) {
      this.vehicleGncType = this.vehicleGncTypes.find(gncType => (gncType.code === _CONFIG.defaultVehicleGncTypeCode));
      if (!this.vehicleGncType) {
        this.vehicleGncType = this.vehicleGncTypes[0];
      }
      this.onChangeVehicleGncType();

      this.vehicleGncValue = null;
      this.onChangeVehicleGncValue();
    }
  }

  /**
   * User changes Vehicle GNC Type data handler.
   */
  public onChangeVehicleGncType(): void {
    // No action needed.
  }

  /**
   * User changes Vehicle GNC Value data handler.
   */
  public onChangeVehicleGncValue(): void {
    this.vehicleGncValueAsNumber = FloatFormatterInputDirective.parseInputToFloat(this.vehicleGncValue);
  }

  /**
   * User changes Vehicle Use Type data handler.
   */
  public onChangeVehicleUseType(): void {
    // No action needed.
  }

  /**
   * User focus Insurance Validity Date Since handler.
   */
  public onFocusInsuranceValidityDateSince(datePicker: NgbInputDatepicker): void {
    if (!datePicker.isOpen()) {
      datePicker.open();
    }
  }

  /**
   * User changes Insurance Validity Date Since data handler.
   */
  public onChangeInsuranceValidityDateSince(silent: boolean = false): void {
    if (!silent) {
      // The purpose of this block is to trigger the input validation animation. The base date-picker component doesn't trigger it.
      const dateCache = this.insuranceValidityDateSince;
      this.insuranceValidityDateSince = null;
      setTimeout(() => {
        this.insuranceValidityDateSince = dateCache;
      }, 0);
    }
    this.insuranceValidityDateSinceStartDate = this.datePickerUtilsService.dateToNgbDateStruct(this.insuranceValidityDateSince);
  }
}
