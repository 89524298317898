// Core.
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models &  Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { IGetPolizaPoliciesIssuedResponse } from './i-get-poliza-policies-issued-response';
import { PoliciesIssuedResultModel } from '../../models/policies-issued-result.model';
import { PolicyIssuedModel } from '../../models/policy-issued.model';
// Services.
import { CotizadorService } from '../cotizador/cotizador.service';
import { ErrorService } from '../error/error.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'CotizadorService';
_LOGGER.debug(_TAG, 'loaded.');

/**
 * Póliza Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PolizaService {
  private baseURL = `${_CONFIG.apiBaseURL}/poliza`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {
  }

  /**
   * Returns the Policies issued by Dealers filtered by person document number and vehicle plate number.
   */
  public getPoliciesIssuesFiltered(documentNumber: string, vehiclePlate: string,
                                   page: number = 0, size: number = 10): Observable<PoliciesIssuedResultModel | BaseAppError> {
    const __SUBTAG = 'getPoliciesIssuesFiltered';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/filter?nroDocumento=${documentNumber ? documentNumber : ''}&patente=${vehiclePlate ? vehiclePlate : ''}&page=${page}&size=${size}`; // tslint:disable-line:max-line-length
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

    return this.http.get<IGetPolizaPoliciesIssuedResponse>(endpointURL, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IGetPolizaPoliciesIssuedResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Return success flag.
      map((response: HttpResponse<IGetPolizaPoliciesIssuedResponse>) =>
        new PoliciesIssuedResultModel(response.body.col.map(p => new PolicyIssuedModel(parseInt(p.nroPoliza, 10), p.titular,
            p.nroDocumento, p.compania, p.metodoPago, p.cobertura, p.marca, p.modelo, _CONFIG.apiBaseURL + p.urn,
            _CONFIG.apiBaseURL + p.urnSolicitud, _CONFIG.apiBaseURL + p.urnCertificadoMercosur,
            _CONFIG.apiBaseURL + p.urnTarjetaCirculacion, _CONFIG.apiBaseURL + p.urnCuponPago
            )).sort((a, b) => a.policyId - b.policyId),
          response.body.total)
      ),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }
}
