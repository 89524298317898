import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DatePickerUtilsService {
  private readonly DELIMITER = '/';

  constructor() {
  }

  private zeroPrefix(n: number): string {
    return (n < 10) ? `0${n}` : `${n}`;
  }

  public dateToNgbDateStruct(dateInput: Date | null): NgbDateStruct | null {
    if (!!dateInput) {
      return {
        day: dateInput.getDate(),
        month: dateInput.getMonth() + 1,
        year: dateInput.getFullYear()
      };
    }
    return null;
  }

  public dateToString(dateInput: Date | null): string | null {
    return dateInput ? `${this.zeroPrefix(dateInput.getDate())}${this.DELIMITER}${this.zeroPrefix(dateInput.getMonth() + 1)}${this.DELIMITER}${dateInput.getFullYear()}` : '';
  }

  public ngbDateStructToDate(dateInput: NgbDateStruct | null): Date | null {
    if (!!dateInput) {
      return new Date(dateInput.year, dateInput.month - 1, dateInput.day, 0, 0, 0, 0);
    }
    return null;
  }

  public ngbDateStructToString(dateInput: NgbDateStruct | null): string {
    return dateInput ? `${this.zeroPrefix(dateInput.day)}${this.DELIMITER}${this.zeroPrefix(dateInput.month)}${this.DELIMITER}${this.zeroPrefix(dateInput.year)}` : '';
  }

  public stringToNgbDateStruct(dateInput: string): NgbDateStruct | null {
    if (dateInput) {
      const [day, month, year] = dateInput.split(this.DELIMITER);
      return {
        day: parseInt(day, 10),
        month: parseInt(month, 10),
        year: parseInt(year, 10)
      };
    }
    return null;
  }
}
