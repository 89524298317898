// Core.
import {Component, OnInit} from '@angular/core';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {UserService} from '../../../../services/user/user.service';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PageHeaderComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Page Header component to place top of most views.
 */
@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  constructor(
    public userService: UserService
  ) {
  }
}
