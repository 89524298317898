// Core.
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// Models & Interfaces.
import { BaseAppError } from '../../../../services/error/base-app-error';
import { IQuoteInsuranceStep2AboutYou } from '../../../../services/session/i-quote-insurance-step2-about-you';
import { BrokerModel } from '../../../../models/broker.model';
// Services.
import { CompraService } from '../../../../services/compra/compra.service';
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { ErrorService } from '../../../../services/error/error.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { SessionService } from '../../../../services/session/session.service';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


interface DownloadFlags {
  policy: boolean;
  certificate: boolean;
  request: boolean;
  card: boolean;
  coupon: boolean;
}

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ConclusionPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Conclusion page. Last step (view) of Insurance process.
 */
@Component({
  selector: 'app-conclusion-page',
  templateUrl: './conclusion-page.component.html',
  styleUrls: ['./conclusion-page.component.scss']
})
export class ConclusionPageComponent implements OnInit {
  @ViewChild('download') protected download: ElementRef;

  private personData: IQuoteInsuranceStep2AboutYou;
  protected personEmail: string;
  private downloadInsurancePolicyUrl: string;
  private downloadInsuranceRequestUrl: string;
  private downloadInsuranceCertificateUrl: string;
  private downloadInsuranceCardUrl: string;
  private downloadInsuranceCouponUrl: string;
  public downloadFlags: DownloadFlags;
  public selectedBroker: BrokerModel;
  public selectedPayment: string;
  protected emailDocumentsUrl: string[];
  public sendingEmails: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService,
    private modalsService: ModalsService,
    private errorService: ErrorService,
    private compraService: CompraService
  ) {
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
    this.formReset();
    this.documentsReset();
  }

  private documentsReset(): void {
    this.emailDocumentsUrl = [];
  }

  /**
   * Resets the form & the session.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    // Backup data & reset session data.
    this.personEmail = this.personData.personEmail;
    this.downloadInsurancePolicyUrl = this.compraService.getPolicyDownloadUrl();
    this.downloadInsuranceRequestUrl = this.compraService.getRequestDownloadUrl();
    this.downloadInsuranceCertificateUrl = this.compraService.getCertificateDownloadUrl();
    this.downloadInsuranceCardUrl = this.compraService.getCardDownloadUrl();
    this.downloadInsuranceCouponUrl = this.compraService.getCouponDownloadUrl();
    this.sessionService.reset();

    if (!this.personEmail || !this.downloadInsurancePolicyUrl || !this.downloadInsuranceRequestUrl) {
      this.onError('Hubo un problema al recuperar los datos de la compra.');
    }
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step #2-3 data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      this.selectedBroker = this.sessionService.getSelectedBroker();
      this.selectedPayment = this.sessionService.getSelectedPaymentMethod();
      this.downloadFlags = {
        policy: false,
        certificate: false,
        request: false,
        card: false,
        coupon: false
      };
      if (this.personData && this.sessionService.getBuyInsuranceStep3MoreVehicleData()) {
        this.formInit();
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * Navs back to Pick Payment Method page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/pick-payment-method']);
  }

  /**
   * Navs to Cotizar Seguro - Step #1-1 (Vehicle Data).
   */
  public onCotizar(): void {
    this.router.navigate(['/insurance/vehicle-data']);
  }

  public startDownload(): void {
    if (this.downloadFlags.policy) {
      this.download.nativeElement.setAttribute('href', this.downloadInsurancePolicyUrl);
      this.download.nativeElement.click();
    }
    if (this.downloadFlags.request) {
      this.download.nativeElement.setAttribute('href', this.downloadInsuranceRequestUrl);
      this.download.nativeElement.click();
    }
    if (this.downloadFlags.certificate) {
      this.download.nativeElement.setAttribute('href', this.downloadInsuranceCertificateUrl);
      this.download.nativeElement.click();
    }
    if (this.downloadFlags.card) {
      this.download.nativeElement.setAttribute('href', this.downloadInsuranceCardUrl);
      this.download.nativeElement.click();
    }
    if (this.downloadFlags.coupon) {
      this.download.nativeElement.setAttribute('href', this.downloadInsuranceCouponUrl);
      this.download.nativeElement.click();
    }
  }

  public sendEmail(): void {
    const __SUBTAG = 'sendEmail';
    this.sendingEmails = true;
    if (this.downloadFlags.policy) {
      this.emailDocumentsUrl.push('POLIZA');
    }
    if (this.downloadFlags.request) {
      this.emailDocumentsUrl.push('SOLICITUD');
    }
    if (this.downloadFlags.certificate) {
      this.emailDocumentsUrl.push('CERTIFICADO_MERCOSUR');
    }
    if (this.downloadFlags.card) {
      this.emailDocumentsUrl.push('TARJETA_CIRCULACION');
    }
    if (this.downloadFlags.coupon) {
      this.emailDocumentsUrl.push('CUPON_DE_PAGO');
    }
    this.compraService.doComprarSendEmail(this.emailDocumentsUrl).subscribe((resultOrError: boolean | BaseAppError) => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'CompraService doPagar resulted in error.');
        this.onError(resultOrError);
        return;
      }

      // Succeed.
      if (!resultOrError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CompraService.');
        this.onError(new Error('Ha ocurrido un error durante el proceso de envio de correo.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'CompraService doComprarSendEmail ended.');
        this.modalsService.showSuccessModal('Se ha enviado el correo de manera satisfactoria.', 'COMPRA DE SEGURO');
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on CompraService doComprarSendEmail.');
      this.onError(error);
    }, () => {
      this.sendingEmails = false;
      this.documentsReset();
    });
  }
}
