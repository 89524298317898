// Models.
import { LocationModel } from './location.model';


export class ProvinceModel {
  public code: string;
  public name: string;
  public locations: LocationModel[];

  constructor(code: string, name: string, locations: LocationModel[] = []) {
    this.code = code;
    this.name = name;
    this.locations = locations;
  }
}
