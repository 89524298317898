import {Injectable} from '@angular/core';
import {SimpleLogger} from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'TaxValidationService';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Tax validation Service.
 */
@Injectable({providedIn: 'root'})
export class TaxValidationService {
  constructor() {
  }

  public cuitHasValidLegth(cuit: string): boolean {
    return !!cuit && !!cuit.length && (cuit.length === 11);
  }

  public cuitHasOnlyNumbers(cuit: string): boolean {
    return (cuit || '').replace(/\d/g, '').length === 0;
  }

  public cuitMatchesPersonType(cuit: string, personType: 'F' | 'J'): boolean {
    switch (personType) {
      case 'F':
        return (cuit[0] === '2');
      case 'J':
        return (cuit[0] === '3');
      default:
        return false;
    }
  }

  public cuitMatchesDocumentNumber(cuit: string, personType: 'F' | 'J', personDocument: string): boolean {
    switch (personType) {
      case 'F':
        if (personDocument.length === 7) {
          personDocument = '0' + personDocument;
        }
        return (cuit.substr(2, 8) === personDocument);
      case 'J':
        return true;
      default:
        return false;
    }
  }

  public cuitHasValidVerifierDigit(cuit: string): boolean {
    let accumulated = 0;
    const digits = cuit.split('').map(d => parseInt(d, 10));
    const digit = digits.pop();
    for (let i = 0; i < digits.length; i++) {
      accumulated += digits[9 - i] * (2 + (i % 6));
    }
    let verifier = 11 - (accumulated % 11);
    if (verifier === 11) {
      verifier = 0;
    }
    return (digit === verifier);
  }

  public isCuitValid(cuit: string, personType: 'F' | 'J', personDocument: string): boolean {
    return this.cuitHasValidLegth(cuit) && this.cuitHasOnlyNumbers(cuit) && this.cuitMatchesPersonType(cuit, personType)
      && this.cuitMatchesDocumentNumber(cuit, personType, personDocument) && this.cuitHasValidVerifierDigit(cuit);
  }
}
