// Core.
import {Component, Input} from '@angular/core';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'AlertModalComponent';
_LOGGER.debug(_TAG, 'loaded.');

/**
 * Alter Modal component.
 */
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  @Input() message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  /* Handlers. */

  /**
   * Closes modal.
   */
  public onAccept(): void {
    this.activeModal.close('Accept');
  }
}
