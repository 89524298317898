// Core.
import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {IAPIErrorResponse} from '../../../../services/error/i-api-error-response';
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
import {UserService} from '../../../../services/user/user.service';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'HomePageComponent';
_LOGGER.debug(_TAG, 'loaded.');

const QUERY_PARAM_TOKEN_KEY = 't';
const QUERY_PARAM_SECRET_KEY = 's';

/**
 * Home page. Initial view (landing).
 */
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  protected showHome = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private userService: UserService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService
  ) {
  }

  /* Handlers. */

  private handleTokenSessionAccess(checkinToken: string): void {
    const __SUBTAG = 'handleTokenSessionAccess';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');
    this.showHome = false;
    const loadingModal = this.modalsService.showLoadingModal().finally(() => {
      setTimeout(() => {
        this.userService.doCheckIn(checkinToken).subscribe((resultOrError: boolean | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'doCheckIn resulted in error.');
            this.onError(resultOrError);
            return;
          }

          // Succeed.
          if (resultOrError) {
            _LOGGER.debug(_TAG, __SUBTAG, 'Checkin completed.');
          } else {
            _LOGGER.error(_TAG, __SUBTAG, 'Checkin failed.');
            this.onError(new Error('Ha ocurrido un error durante el inicio de sesión.'));
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on doCheckIn.');
          this.onError(error);
        }, () => {
          loadingModal.then(modal => modal.close());
          this.showHome = true;
        });
      }, 3000);
    });
  }

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    // Reset session data.
    this.sessionService.reset();
    CotizadorService.doReset();

    // Get session token.
    this.route.queryParamMap.subscribe(params => {
      if (this.userService.isUserCheckinCompleted()) {
        this.showHome = true;
        return;
      }
      try { // Access via TOKEN.
        const checkinToken: string = params.get(QUERY_PARAM_TOKEN_KEY);
        if (!!checkinToken) {
          _LOGGER.debug(_TAG, __SUBTAG, 'checking token.');
          this.handleTokenSessionAccess(checkinToken);
        } else {
          // Check stored token (page reload check).
          const pageAccessedByReload = ((!!window.performance.navigation && (window.performance.navigation.type === 1)) ||
            window.performance.getEntriesByType('navigation').map((nav: PerformanceNavigationTiming) => nav.type)
              .includes('reload'));
          if (pageAccessedByReload && this.userService.retrieveCheckIn()) {
            _LOGGER.debug(_TAG, __SUBTAG, 'token retrieved.');
            this.showHome = true;
          } else { // Public access.
            _LOGGER.debug(_TAG, __SUBTAG, 'public access.');
            this.userService.enableUserPublicAccess();
            // Public access with SECRET.
            const secretToken: string = params.get(QUERY_PARAM_SECRET_KEY);
            if (!!secretToken) {
              this.userService.setUserPublicAccessSecret(secretToken);
            }
            this.router.navigate(['/insurance/vehicle-data'], {replaceUrl: true});
          }
        }
      } catch (e) {
        this.onError(e);
      }
    });
  }


  /**
   * Handles errors on this view.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
  }

  /**
   * Navs to Step #1-1 (Vehicle Data).
   */
  protected onCotizar(): void {
    this.router.navigate(['/insurance/vehicle-data']);
  }
}
