// Core.
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
// Components.
import {PoliciesIssuedPageComponent} from './pages/policies-issued-page/policies-issued-page.component';
// Guards.
import {CheckinGuard} from '../../guards/checkin/checkin.guard';
import {NoPublicAccessGuard} from '../../guards/no-public-access/no-public-access.guard';
// Shared.
import {SimpleLogger} from '../../shared/simple-logger.shared';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'BackOfficeRoutingModule ';
_LOGGER.debug(_TAG, 'loaded.');

const _ROUTES: Routes = [
  {
    path: 'backoffice',
    canActivate: [NoPublicAccessGuard, CheckinGuard],
    children: [
      {path: 'policies-issued', component: PoliciesIssuedPageComponent},
      {path: '', redirectTo: 'policies-issued', pathMatch: 'full'},
      {path: '**', redirectTo: 'policies-issued', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(_ROUTES)],
  exports: [RouterModule]
})
export class BackOfficeRoutingModule {
}
