// Core.
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// Components.
import { AlertModalComponent } from '../../modules/main/components/alert-modal/alert-modal.component';
import { ErrorModalComponent } from '../../modules/main/components/error-modal/error-modal.component';
import { LoadingModalComponent } from '../../modules/main/components/loading-modal/loading-modal.component';
import { SuccesModalComponent } from '../../modules/main/components/succes-modal/succes-modal.component';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ModalsService';
_LOGGER.debug(_TAG, 'loaded.');

export enum ModalType {
  LOADING = 0,
  ERROR = 1,
  ALERT = 2,
  SUCCESS = 3
}

@Injectable({providedIn: 'root'})
export class ModalsService {
  constructor(
    private modalService: NgbModal
  ) {
  }

  private showModal(modalType: ModalType, message?: string, title?: string): Promise<NgbModalRef> {
    let modalContent: any;
    let modalWindowClassSuffix: string;
    let keyboard: boolean;

    switch (modalType) {
      case ModalType.LOADING:
        modalContent = LoadingModalComponent;
        modalWindowClassSuffix = 'loading';
        keyboard = false;
        break;
      case ModalType.ERROR:
        modalContent = ErrorModalComponent;
        modalWindowClassSuffix = 'error';
        keyboard = true;
        break;
      case ModalType.SUCCESS:
        modalContent = SuccesModalComponent;
        modalWindowClassSuffix = 'success';
        keyboard = true;
        break;
      default:
        modalContent = AlertModalComponent;
        modalWindowClassSuffix = 'alert';
        keyboard = false;
    }

    return new Promise<NgbModalRef>(resolve => {
      setTimeout(() => {
        const modal = this.modalService.open(modalContent, {
          ariaLabelledBy: 'modal-basic-title',
          windowClass: `modal-common ${modalWindowClassSuffix}-modal modal-animate`,
          keyboard,
          backdrop: (!keyboard && 'static') || true,
          centered: true
        });
        if (message && (modalType !== ModalType.LOADING)) {
          modal.componentInstance.message = message;
        }
        if (title && (modalType === ModalType.SUCCESS)) {
          modal.componentInstance.title = title;
        }
        resolve(modal);
      });
    });
  }

  public showLoadingModal(message?: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.LOADING, message);
  }

  public showErrorModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ERROR, message);
  }

  public showAlertModal(message: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.ALERT, message);
  }

  public showSuccessModal(message: string, title: string): Promise<NgbModalRef> {
    return this.showModal(ModalType.SUCCESS, message, title);
  }

  public showCustomModal(modalContent: any, additionalClasses?: string, size?: 'lg' | 'sm', centered?: boolean,
                         accessFromButtonDownload?: boolean): NgbModalRef {
    const modal = this.modalService.open(modalContent, additionalClasses ? {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: additionalClasses,
      size,
      centered,
      backdrop: 'static',
      keyboard: false
    } : {ariaLabelledBy: 'modal-basic-title'});
    if (!!modal.componentInstance) {
      modal.componentInstance.accessFromButtonDownload = accessFromButtonDownload;
    }
    return modal;
  }
}
