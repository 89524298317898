// Core.
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// Components.
import {HowToContinuePublicAccessComponent} from '../../components/how-to-continue-public-access/how-to-continue-public-access.component';
// Models & Interfaces.
import {BrokerInsuranceQuoteOptionModel} from '../../../../models/broker-insurance-quote-option.model';
import {BrokerModel} from '../../../../models/broker.model';
import {IQuoteInsuranceStep1VehicleData} from '../../../../services/session/i-quote-insurance-step1-vehicle-data';
import {IQuoteInsuranceStep2AboutYou} from '../../../../services/session/i-quote-insurance-step2-about-you';
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
import {SolicitudService} from '../../../../services/solicitud/solicitud.service';
import {UserService} from '../../../../services/user/user.service';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {BaseAppError} from '../../../../services/error/base-app-error';
import {ReCaptchaV3Service} from 'ng-recaptcha';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PickFamilyPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Pick (Insurance/Broker) Family page.
 */
@Component({
  selector: 'app-pick-family-page',
  templateUrl: './pick-family-page.component.html',
  styleUrls: ['./pick-family-page.component.scss']
})
export class PickFamilyPageComponent implements OnInit {
  public vehicleData: IQuoteInsuranceStep1VehicleData;
  public personData: IQuoteInsuranceStep2AboutYou;
  public selectedBroker: BrokerModel;
  public selectedBrokerFamilyOptions: BrokerInsuranceQuoteOptionModel[];

  constructor(
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly modalsService: ModalsService,
    private readonly sessionService: SessionService,
    private readonly cotizadorService: CotizadorService,
    private readonly solicitudService: SolicitudService,
    private readonly userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks steps #1 & #2 data.
   * Checks Broker data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      this.selectedBroker = this.sessionService.getSelectedBroker();
      this.selectedBrokerFamilyOptions = this.selectedBroker.selectedInsuranceFamilyOptions;
      if (this.vehicleData && this.personData && this.selectedBroker && this.selectedBrokerFamilyOptions) {
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Navs back to Pick Broker page.
   */
  public onStepBack(): void {
    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsurancePickFamily_Back')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });
    this.router.navigate(['/insurance/pick-broker']);
  }

  /**
   * Navs to Contact Data Page.
   */
  public onSelectInsuranceOption(selectedOption: BrokerInsuranceQuoteOptionModel): void {
    const __SUBTAG = 'onSelectInsuranceOption';
    _LOGGER.info(_TAG, __SUBTAG, 'started.');

    this.selectedBroker.selectedInsuranceOption = selectedOption;

    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsurancePickFamily_Select')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });

    if (this.userService.isUserPublicAccess()) {
      const modalRef = this.modalsService.showCustomModal(HowToContinuePublicAccessComponent, 'how-to-continue-public-access', 'lg', true);
      modalRef.result.then((result) => {
        _LOGGER.debug('Public access Modal closed with:', result);
      }).catch((error) => {
        _LOGGER.debug('Public access Modal dimissed with:', error);
      });
    } else {
      this.solicitudService.doSolicitar().subscribe((resultOrError: boolean | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Solicitud doSolicitar resulted in error.');
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        if (!resultOrError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Cannot doSolicitar Solicitud.');
          this.onError(new Error('Ha ocurrido un error durante la selección de la cotización.'));
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'Solicitud doSolicitar OK.');
          this.router.navigate(['/insurance/contact-data']);
        }
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on Solicitud doSolicitar.');
        this.onError(error);
      });
    }
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Resets session & Cotizador data on errors.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    this.selectedBroker.selectedInsuranceOption = null;

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      });
    });
  }
}
