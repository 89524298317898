export class PersonTaxConditionModel {
  public code: string;
  public name: string;
  public exclusiveForPersonTypeIndividual: boolean;

  constructor(code: string, name: string, exclusiveForPersonTypeIndividual: boolean = true) {
    this.code = code;
    this.name = name;
    this.exclusiveForPersonTypeIndividual = exclusiveForPersonTypeIndividual;
  }
}
