import { PolicyIssuedModel } from './policy-issued.model';


export class PoliciesIssuedResultModel {
  public pageResults: PolicyIssuedModel[];
  public totalItems: number;

  constructor(pageResults: PolicyIssuedModel[], totalItems: number) {
    this.pageResults = pageResults;
    this.totalItems = totalItems;
  }
}
