// Core.
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models & Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { IGetVehicleBrandsResponseBrandItem } from './i-get-vehicle-brands-response-brand-item';
import { IGetVehicleBrandsWeighedResponse } from './i-get-vehicle-brands-weighed-response';
import { IGetVehicleGncTypesResponseGncTypeItem } from './i-get-vehicle-gnc-types-response-gnc-type-item';
import { IGetVehicleModelsResponseModelItem } from './i-get-vehicle-models-response-model-item';
import { IGetVehicleUseTypesResponseUseTypeItem } from './i-get-vehicle-use-types-response-use-type-item';
import { IGetVehicleVersionsResponseVersionItem } from './i-get-vehicle-versions-response-version-item';
import {IGetVehicleReferencePriceResponse} from './i-get-vehicle-reference-price-response';
import { VehicleBrandModel } from '../../models/vehicle-brand.model';
import { VehicleBrandsWeightedModel } from '../../models/vehicle-brands-weighted.model';
import { VehicleGncTypeModel } from '../../models/vehicle-gnc-type.model';
import { VehicleModelModel } from '../../models/vehicle-model.model';
import { VehicleUseTypeModel } from '../../models/vehicle-use-type.model';
import { VehicleVersionModel } from '../../models/vehicle-version.model';
// Services.
import { ErrorService } from '../error/error.service';
import { PersistenceService } from '../persistence/persistence.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { VehicleYearModel } from '../../models/vehicle-year.model';
import { VehicleReferencePriceModel } from 'src/app/models/vehicle-reference-price.model';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'VehiclesService';
_LOGGER.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_BRANDS: {
    KEY: 'get_brands',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_BRANDS_WEIGHTED: {
    KEY: 'get_brands_weighted',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_MODELS: {
    KEY: 'get_models',
    AGE: 10 * 60 * 1000 // 10m * 60s * 1000ms.
  },
  GET_VERSIONS: {
    KEY: 'get_versions',
    AGE: 5 * 60 * 1000 // 5m * 60s * 1000ms.
  },
  GET_GNC_TYPES: {
    KEY: 'get_gnc_types',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_USE_TYPES: {
    KEY: 'get_use_types',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};

const _TOYOTA_BRAND_KEY = 'TOYOTA';


/**
 * Vehicles Data Service (Brands, Models, Versions).
 */
@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  private baseURL = `${_CONFIG.apiBaseURL}/auto`;

  private cacheGetBrands: Observable<VehicleBrandModel[] | BaseAppError>;
  private cacheGetBrandsWeighted: Observable<VehicleBrandsWeightedModel | BaseAppError>;
  private cacheGetModels: { [key: string]: Observable<VehicleModelModel[] | BaseAppError> } = {};
  private cacheGetVersions: { [key: string]: Observable<VehicleVersionModel[] | BaseAppError> } = {};
  private cacheGetGncTypes: Observable<VehicleGncTypeModel[] | BaseAppError>;
  private cacheGetUseTypes: Observable<VehicleUseTypeModel[] | BaseAppError>;
  private vehicleReferencePrice: Observable<VehicleReferencePriceModel | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistenceService: PersistenceService
  ) {
  }

  private sortItemsByName(a: VehicleBrandModel | VehicleModelModel | VehicleVersionModel,
                          b: VehicleBrandModel | VehicleModelModel | VehicleVersionModel): number {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  // Brand "Toyota" must be first.
  private sortToyotaBrandFirst(a: VehicleBrandModel, b: VehicleBrandModel): number {
    if (a.name.toUpperCase() === _TOYOTA_BRAND_KEY) {
      return -1;
    } else if (b.name.toUpperCase() === _TOYOTA_BRAND_KEY) {
      return 1;
    }
    return 0;
  }

  /**
   * Gets all the available Vehicle Brands.
   */
  public getVehicleBrands(): Observable<VehicleBrandModel[] | BaseAppError> {
    const __SUBTAG = 'getVehicleBrands';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetVehicleBrandsResponseBrandItem[]) => VehicleBrandModel[] = (data) => data
      .map(brand => new VehicleBrandModel(brand.marcaId, brand.marca))
      .sort(this.sortItemsByName)
      .sort(this.sortToyotaBrandFirst);

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_BRANDS.KEY, _CACHE_CONFIG.GET_BRANDS.AGE)) {
      const endpointURL = `${this.baseURL}/marcas`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetBrands = this.http.get<IGetVehicleBrandsResponseBrandItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleBrandsResponseBrandItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_BRANDS.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleBrandsResponseBrandItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetBrands) {
        const cacheData: IGetVehicleBrandsResponseBrandItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_BRANDS.KEY,
          _CACHE_CONFIG.GET_BRANDS.AGE);
        this.cacheGetBrands = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleBrandsResponseBrandItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleBrandsResponseBrandItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetBrands;
  }

  /**
   * Gets all the available Vehicle Brands weighted.
   */
  public getVehicleBrandsWeighted(): Observable<VehicleBrandsWeightedModel | BaseAppError> {
    const __SUBTAG = 'getVehicleBrandsWeighted';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetVehicleBrandsWeighedResponse) => VehicleBrandsWeightedModel = (data) => new VehicleBrandsWeightedModel(
      new VehicleBrandModel(data.main.marcaId, data.main.marca),
      data.marcaFrecuentes.map(b => new VehicleBrandModel(b.marcaId, b.marca)),
      data.marcaOtras.map(b => new VehicleBrandModel(b.marcaId, b.marca))
    );

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_BRANDS_WEIGHTED.KEY, _CACHE_CONFIG.GET_BRANDS_WEIGHTED.AGE)) {
      const endpointURL = `${this.baseURL}/marcas/frecuentes`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetBrandsWeighted = this.http.get<IGetVehicleBrandsWeighedResponse>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleBrandsWeighedResponse>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_BRANDS_WEIGHTED.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleBrandsWeighedResponse>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetBrandsWeighted) {
        const cacheData: IGetVehicleBrandsWeighedResponse = PersistenceService.retrieve(_CACHE_CONFIG.GET_BRANDS_WEIGHTED.KEY,
          _CACHE_CONFIG.GET_BRANDS_WEIGHTED.AGE);
        this.cacheGetBrandsWeighted = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleBrandsWeighedResponse) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleBrandsWeighedResponse) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetBrandsWeighted;
  }

  /**
   * Gets the available Vehicle Models for a given Brand and Year.
   *
   * @param vehicleBrand    The Vehicle Brand to search for Models.
   * @param vehicleYear     The Vehicle Year to search for Models.
   */
  public getVehicleModelsByBrand(vehicleBrand: VehicleBrandModel,
                                 vehicleYear: VehicleYearModel): Observable<VehicleModelModel[] | BaseAppError> {
    const __SUBTAG = 'getVehicleModelsByBrand';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetVehicleModelsResponseModelItem[]) => VehicleModelModel[] = (data) => data
      .map(vModel => new VehicleModelModel(vModel.modeloId, vModel.modelo))
      .sort(this.sortItemsByName);

    const __CACHE_KEY_SUFFIX = `_${vehicleBrand.id}_${vehicleYear.year}`;

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_MODELS.AGE)) {
      const endpointURL = `${this.baseURL}/modelos/${vehicleBrand.id}/${vehicleYear.year}`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetModels[__CACHE_KEY_SUFFIX] = this.http.get<IGetVehicleModelsResponseModelItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleModelsResponseModelItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleModelsResponseModelItem[]>) => parseData(response.body)),
          // Store data in model.
          tap((data: VehicleModelModel[]) => {
            vehicleBrand.models = data;
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      const cacheData: IGetVehicleModelsResponseModelItem[] = PersistenceService.retrieve(
        _CACHE_CONFIG.GET_MODELS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_MODELS.AGE);
      if (!this.cacheGetModels[__CACHE_KEY_SUFFIX]) {
        this.cacheGetModels[__CACHE_KEY_SUFFIX] = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleModelsResponseModelItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleModelsResponseModelItem[]) => parseData(data)),
            // Store data in model.
            tap((data: VehicleModelModel[]) => {
              vehicleBrand.models = data;
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      } else if (!vehicleBrand.models || !vehicleBrand.models.length) {
        vehicleBrand.models = parseData(cacheData);
      }
    }

    return this.cacheGetModels[__CACHE_KEY_SUFFIX];
  }

  /**
   * Gets the available Vehicle Versions for a given Model and Year.
   *
   * @param vehicleModel    The Vehicle Model to search for Versions.
   * @param vehicleYear     The Vehicle Year to search for Versions.
   */
  public getVehicleVersionsByModel(vehicleModel: VehicleModelModel,
                                   vehicleYear: VehicleYearModel): Observable<VehicleVersionModel[] | BaseAppError> {
    const __SUBTAG = 'getVehicleVersionsByModel';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const __CACHE_KEY_SUFFIX = `_${vehicleModel.id}_${vehicleYear.year}`;

    const parseData: (data: IGetVehicleVersionsResponseVersionItem[]) => VehicleVersionModel[] = (data) => data
      .map(vVersion => new VehicleVersionModel(vVersion.versionId, vVersion.versionIdIA, vVersion.version))
      .sort(this.sortItemsByName);

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_VERSIONS.AGE)) {
      const endpointURL = `${this.baseURL}/versiones/${vehicleModel.id}/${vehicleYear.year}`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetVersions[__CACHE_KEY_SUFFIX] = this.http
        .get<IGetVehicleVersionsResponseVersionItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleVersionsResponseVersionItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleVersionsResponseVersionItem[]>) => parseData(response.body)),
          // Store data in model.
          tap((data: VehicleVersionModel[]) => {
            vehicleModel.versions = data;
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      const cacheData: IGetVehicleVersionsResponseVersionItem[] = PersistenceService.retrieve(
        _CACHE_CONFIG.GET_VERSIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_VERSIONS.AGE);
      if (!this.cacheGetVersions[__CACHE_KEY_SUFFIX]) {
        this.cacheGetVersions[__CACHE_KEY_SUFFIX] = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleVersionsResponseVersionItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleVersionsResponseVersionItem[]) => parseData(data)),
            // Store data in model.
            tap((data: VehicleVersionModel[]) => {
              vehicleModel.versions = data;
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      } else if (!vehicleModel.versions || !vehicleModel.versions.length) {
        vehicleModel.versions = parseData(cacheData);
      }
    }

    return this.cacheGetVersions[__CACHE_KEY_SUFFIX];
  }

  /**
   * Gets the available Vehicle GNC Types.
   */
  public getVehicleGncTypes(): Observable<VehicleGncTypeModel[] | BaseAppError> {
    const __SUBTAG = 'getVehicleGncTypes';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetVehicleGncTypesResponseGncTypeItem[]) => VehicleGncTypeModel[] = (data) => data
      .map(gncType => new VehicleGncTypeModel(gncType.code, gncType.descripcion));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_GNC_TYPES.KEY, _CACHE_CONFIG.GET_GNC_TYPES.AGE)) {
      const endpointURL = `${this.baseURL}/gnc`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetGncTypes = this.http.get<IGetVehicleGncTypesResponseGncTypeItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleGncTypesResponseGncTypeItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_GNC_TYPES.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleGncTypesResponseGncTypeItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetGncTypes) {
        const cacheData: IGetVehicleGncTypesResponseGncTypeItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_GNC_TYPES.KEY,
          _CACHE_CONFIG.GET_GNC_TYPES.AGE);
        this.cacheGetGncTypes = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleGncTypesResponseGncTypeItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleGncTypesResponseGncTypeItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetGncTypes;
  }

  /**
   * Gets the available Vehicle Use Types.
   */
  public getVehicleUseTypes(): Observable<VehicleUseTypeModel[] | BaseAppError> {
    const __SUBTAG = 'getVehicleUseTypes';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetVehicleUseTypesResponseUseTypeItem[]) => VehicleUseTypeModel[] = (data) => data
      .map(useType => new VehicleUseTypeModel(useType.code, useType.descripcion));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_USE_TYPES.KEY, _CACHE_CONFIG.GET_USE_TYPES.AGE)) {
      const endpointURL = `${this.baseURL}/usos`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetUseTypes = this.http.get<IGetVehicleUseTypesResponseUseTypeItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetVehicleUseTypesResponseUseTypeItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_USE_TYPES.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetVehicleUseTypesResponseUseTypeItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetUseTypes) {
        const cacheData: IGetVehicleUseTypesResponseUseTypeItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_USE_TYPES.KEY,
          _CACHE_CONFIG.GET_USE_TYPES.AGE);
        this.cacheGetUseTypes = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetVehicleUseTypesResponseUseTypeItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetVehicleUseTypesResponseUseTypeItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetUseTypes;
  }

  /**
   * Gets the reference price for the selected Vehicle in step 1
   */
  public getReferencePriceFromInfoAuto(versionId: string, year:number): Observable<VehicleReferencePriceModel | BaseAppError>{
    const __SUBTAG = 'getReferencePriceFromInfoAuto';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/referencePrice/${versionId}/${year}`;
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

    this.vehicleReferencePrice = this.http.get<IGetVehicleReferencePriceResponse>(endpointURL, {observe: 'response'})
    .pipe(
        // Log operation & store response
        tap((response: HttpResponse<IGetVehicleReferencePriceResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IGetVehicleReferencePriceResponse>) => new VehicleReferencePriceModel(
          response.body.message, 
          response.body.referencePriceInfoAuto,
          response.body.responseCode)),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
    );

    return this.vehicleReferencePrice;
  }
}