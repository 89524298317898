// Core.
import { Component, OnInit } from '@angular/core';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PageFooterComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Page Footer component to place bottom of most views.
 */
@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
