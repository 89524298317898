// Core.
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// Models.
import { BrokerModel } from '../../../../models/broker.model';
// Services.
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { SessionService } from '../../../../services/session/session.service';
import { CompraService } from '../../../../services/compra/compra.service';
import { ErrorService } from '../../../../services/error/error.service';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';
import { BaseAppError } from '../../../../services/error/base-app-error';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PickPaymentMethodPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Pick Payment Method page.
 */
@Component({
  selector: 'app-pick-payment-method-page',
  templateUrl: './pick-payment-method-page.component.html',
  styleUrls: ['./pick-payment-method-page.component.scss']
})
export class PickPaymentMethodPageComponent implements OnInit {

  protected selectedBroker: BrokerModel;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    protected modalService: ModalsService,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService,
    private compraService: CompraService,
    private errorService: ErrorService
  ) {
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step #2-3 data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.selectedBroker = this.sessionService.getSelectedBroker();
      if (this.sessionService.getBuyInsuranceStep3MoreVehicleData()) {
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Navs back to More Vehicle Data page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/more-vehicle-data']);
  }


  /**
   * Navs to Payment Method - Credit Card page.
   */
  protected onPickCreditCard(): void {
    this.router.navigate(['/insurance/pick-payment-method/credit-card']);
  }

  /**
   * Navs to Payment Method - Automatic Debit page.
   */
  protected onPickAutomaticDebit(): void {
    this.router.navigate(['/insurance/pick-payment-method/automatic-debit']);
  }

  protected onPickCash(): void {
    const __SUBTAG = 'onConfirm';
    this.modalService.showAlertModal(this.selectedBroker.cashPaymentMessage).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Alert modal closed with result:', result);
        const loadingModal = this.modalService.showLoadingModal();
        this.compraService.doPagarWithCash().subscribe((resultOrError: boolean | BaseAppError) => {
          // Failed.
          if (resultOrError instanceof BaseAppError) {
            _LOGGER.error(_TAG, __SUBTAG, 'CompraService doPagar resulted in error.');
            this.onError(resultOrError);
            return;
          }

          // Succeed.
          if (!resultOrError) {
            _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CompraService.');
            this.onError(new Error('Ha ocurrido un error durante el proceso de compra.'));
          } else {
            _LOGGER.debug(_TAG, __SUBTAG, 'CompraService doPagar ended.');
            this.router.navigate(['/insurance/conclusion']);
          }
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on CompraService doPagar.');
          this.onError(error);
        }, () => {
          loadingModal.then(modal => modal.close());
        });
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Alert modal dismissed with reason:', reason);
      });
    });
  }

  protected validateButtomDebit(): boolean {
    return this.selectedBroker.code === 'SAN_CRIST';
  }

  protected validateButtonCash(): boolean {
    return this.selectedBroker.selectedInsuranceOption.acceptCashPayment;
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }
}
