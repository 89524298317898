// Models.
import { VehicleBrandModel } from './vehicle-brand.model';


export class VehicleBrandsWeightedModel {
  public mainBrand: VehicleBrandModel;
  public frequentBrands: VehicleBrandModel[];
  public otherBrands: VehicleBrandModel[];

  constructor(main: VehicleBrandModel, frequent: VehicleBrandModel[] = [], other: VehicleBrandModel[] = []) {
    this.mainBrand = main;
    this.frequentBrands = frequent;
    this.otherBrands = other;
  }
}
