import { Pipe, PipeTransform } from '@angular/core';


/**
 * Pipe that limits the text output length of in an element.
 */
@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {
  /**
   * Truncates text output.
   *
   * @param value           Input text to truncate.
   * @param limit           Characters length limit.
   * @param completeWords   Flag that indicates if the words can be truncated or they should be displayed completely.
   * @param ellipsis        Characters to visualize where the text is truncated.
   */
  transform(value: string, limit: number = 25, completeWords: boolean = false, ellipsis: string = '...'): string {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    return `${value.substr(0, limit)}${value.length > limit ? ellipsis : ''}`;
  }
}
