// Core.
import {catchError, map, publishReplay, refCount, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
// Models & Interfaces.
import {BankAccountTypeModel} from '../../models/bank-account-type.model';
import {BankModel} from '../../models/bank.model';
import {BaseAppError} from '../error/base-app-error';
import {IGetBanksResponseBankItem} from './i-get-banks-response-bank-item';
// Services.
import {CotizadorService} from '../cotizador/cotizador.service';
import {ErrorService} from '../error/error.service';
import {PersistenceService} from '../persistence/persistence.service';
// Shared.
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {SimpleLogger} from '../../shared/simple-logger.shared';
import {IGetPaymentInputsResponse} from './i-get-payment-inputs-response';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'VehiclesService';
_LOGGER.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_BANKS: {
    KEY: 'get_banks',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_ACCOUNT_TYPES: {
    KEY: 'get_b_acc_ty',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Payment Service.
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private baseURL = `${_CONFIG.apiBaseURL}/pago`;

  private cacheGetBanks: Observable<BankModel[] | BaseAppError>;
  private cacheGetBankAccountTypes: Observable<BankAccountTypeModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistenceService: PersistenceService
  ) {
  }

  /**
   * Gets all the available Banks.
   */
  public getBanks(): Observable<BankModel[] | BaseAppError> {
    const __SUBTAG = 'getBanks';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const parseData: (data: IGetBanksResponseBankItem[]) => BankModel[] = (data) => data
      .map(bank => new BankModel(bank.code, bank.descripcion));

    const endpointURL = `${this.baseURL}/bancos/` + CotizadorService.getInsuranceQuoteTrackId();
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

    this.cacheGetBanks = this.http.get<IGetBanksResponseBankItem[]>(endpointURL, {observe: 'response'})
      .pipe(
        // Log operation & store response in cache.
        tap((response: HttpResponse<IGetBanksResponseBankItem[]>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IGetBanksResponseBankItem[]>) => parseData(response.body)),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );

    return this.cacheGetBanks;
  }

  /**
   * Gets available payment inputs.
   */
  public getPaymentInputs(): Observable<IGetPaymentInputsResponse | BaseAppError> {
    const __SUBTAG = 'getPaymentInputs';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');
    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }
    const endpointURL = `${this.baseURL}/inputs/` + CotizadorService.getInsuranceQuoteTrackId();
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);
    return this.http.get<IGetPaymentInputsResponse>(endpointURL, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IGetPaymentInputsResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<IGetPaymentInputsResponse>) => response.body),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Gets all the available Bank Account Types.
   */
  public getBankAccountTypes(): Observable<BankAccountTypeModel[] | BaseAppError> {
    const __SUBTAG = 'getBankAccountTypes';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: { code: string, name: string }[]) => BankAccountTypeModel[] = (data) => data
      .map(bank => new BankAccountTypeModel(bank.code, bank.name));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ACCOUNT_TYPES.KEY, _CACHE_CONFIG.GET_ACCOUNT_TYPES.AGE)) {
      const endpointURL = `${this.baseURL}/bancos`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetBankAccountTypes = of([
        {code: 'CUENTA_CORRIENTE', name: 'Cuenta Corriente'},
        {code: 'CAJA_AHORROS', name: 'Caja de Ahorros'}
      ])
        .pipe(
          // Log operation & store response in cache.
          tap((response: { code: string, name: string }[]) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_ACCOUNT_TYPES.KEY, response);
          }),
          // Parse response.
          map((response: { code: string, name: string }[]) => parseData(response)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetBankAccountTypes) {
        const cacheData: { code: string, name: string }[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_ACCOUNT_TYPES.KEY,
          _CACHE_CONFIG.GET_ACCOUNT_TYPES.AGE);
        this.cacheGetBankAccountTypes = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: { code: string, name: string }[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: { code: string, name: string }[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetBankAccountTypes;
  }
}
