// Core.
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {CardModel} from '../../../../models/card.model';
import {IGetPaymentInputsResponse} from '../../../../services/payment/i-get-payment-inputs-response';
// Services.
import {CompraService} from '../../../../services/compra/compra.service';
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
import {CardService} from '../../../../services/card/card.service';
import {PaymentService} from '../../../../services/payment/payment.service';
// Shared.
import {EnvironmentManager} from '../../../../shared/environment-manager.shared';
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PaymentMethodCreditCardPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Payment Method - Credit Card page.
 */
@Component({
  selector: 'app-payment-method-credit-card-page',
  templateUrl: './payment-method-credit-card-page.component.html',
  styleUrls: ['./payment-method-credit-card-page.component.scss'],
  animations: [
    trigger('creditCardDetectionBrand', [
      state('detected', style({
        opacity: 1
      })),
      state('undetected', style({
        opacity: 0
      })),
      transition('detected => undetected', [
        animate('1ms')
      ]),
      transition('undetected => detected', [
        animate('0.5s 2s')
      ])
    ]),
    trigger('creditCardDetectionText', [
      state('detected', style({
        opacity: 1
      })),
      state('undetected', style({
        opacity: 1
      })),
      transition('detected => undetected', [
        animate('1ms')
      ]),
      transition('undetected => detected', [
        animate('1ms', style({opacity: 0})),
        animate('0.5s 2s', style({opacity: 1}))
      ])
    ])
  ]
})
export class PaymentMethodCreditCardPageComponent implements OnInit {
  /* Form controls. */
  @ViewChild('creditCardForm') public creditCardForm: NgForm;
  public disableForm: boolean;
  public formInputStep: number;
  public cardNumber: string;
  public detectedCardBrand: string;
  public cardDueDateMonth: number;
  public cardDueDateYears: number[];
  public cardDueDateYear: number;
  public cardHolder: string;
  public cardCode: string;
  public loadingPaymentInputs: boolean;
  public paymentInputs: IGetPaymentInputsResponse;

  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
    private readonly sessionService: SessionService,
    public readonly modalsService: ModalsService,
    private readonly errorService: ErrorService,
    private readonly compraService: CompraService,
    private readonly cardService: CardService,
    private paymentService: PaymentService
  ) {
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');
    this.disableForm = true;
    Promise.all([
      // Get Credit Card Due Date Years.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          const currentYear = new Date().getFullYear();
          this.cardDueDateYears = (Array.apply(null, {length: _CONFIG.creditCardDueDateYearsToConsider}) as any[])
            .map((v, i) => currentYear + i);
          if (!this.cardDueDateYears || !this.cardDueDateYears.length || (this.cardDueDateYears.length < 1)) {
            reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
          }
          resolve(this.cardDueDateYears);
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Card Due Date Years.', e);
          reject(e);
        }
      }),

      // Get Payment Inputs.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingPaymentInputs = true;
          this.paymentInputs = null;
          this.paymentService.getPaymentInputs().subscribe((resultOrError: IGetPaymentInputsResponse | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getPaymentInputs resulted in error.');
              reject(resultOrError);
              return;
            }
            // Succeed.
            if (!resultOrError) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Payment Inputs.');
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Payment Inputs initialized.');
              this.paymentInputs = resultOrError;
              resolve(this.paymentInputs);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getPaymentInputs.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Payment Inputs.', e);
          reject(e);
        } finally {
          this.loadingPaymentInputs = false;
        }
      })
    ]).then(() => {
      _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
      this.formReset();
    }).catch(error => {
      _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
      this.onError(error);
    });
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.creditCardForm && this.creditCardForm.pristine) {
      this.creditCardForm.control.reset();
    }

    this.formInputStep = 0;

    this.detectedCardBrand = null;
    this.cardNumber = null;
    this.onChangeCardNumber();
    this.cardDueDateMonth = null;
    this.onChangeCardDueDateMonth();
    this.cardDueDateYear = null;
    this.onChangeCardDueDateYear();
    this.cardHolder = null;
    this.onChangeCardHolder();
    this.cardCode = null;
    this.onChangeCardCode();
    this.disableForm = false;
  }

  /**
   * Returns TRUE if the current selected Due Date month/year by the user is a valid card due date. Returns FALSE otherwise.
   * Also returns FALSE if the date is in the past (less than the current month).
   */
  public isDueDateValid(): boolean {

    if (!this.paymentInputs || !this.paymentInputs.cardExpirationDate) {
      return true;
    }
    if ((this.cardDueDateMonth === null) || (this.cardDueDateYear === null)) {
      return false;
    }
    const now = moment();
    if(this.cardDueDateYear < now.year() || (this.cardDueDateYear == now.year() && this.cardDueDateMonth <= now.month()) ){
      return false;
    }
    return (now.year() < this.cardDueDateYear) || (now.month() <= this.cardDueDateMonth);
  }

  protected detectCreditCard(): void {
    if (this.cardNumber && this.creditCardForm.controls.inputCardNumber.valid) {
      const __SUBTAG = 'onIdentificar';
      this.disableForm = true;
      const loadingModal = this.modalsService.showLoadingModal();
      this.cardService.doDetect(this.cardNumber).subscribe((resultOrError: CardModel | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'CardService doDetect resulted in error.');
          this.onError(resultOrError);
          this.cardNumber = '';
          this.detectedCardBrand = null;
          return;
        }
        // Succeed.
        if (!resultOrError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CardService.');
          this.onError(new Error('Ha ocurrido un error durante el proceso de identificación de tarjeta.'));
          this.cardNumber = '';
          this.detectedCardBrand = null;
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'CardService doDetect ended.');
          if ((resultOrError.brand === 'CABAL_DEBITO') || (resultOrError.brand === 'MASTERCARD_DEBITO') ||
            (resultOrError.brand === 'VISA_DEBITO')) {
            this.cardNumber = '';
            this.detectedCardBrand = null;
            this.modalsService.showErrorModal('El número de tarjeta ingresado ' +
              'no corresponde a una tarjeta de crédito. ' +
              'Por favor ingrese nuevamente un número de tarjeta de crédito valido.').then(modal => {
              this.closeErrorModal(modal, __SUBTAG);
            });
          } else if (resultOrError.brand === 'VISA' || resultOrError.brand === 'MASTERCARD') {
            this.detectedCardBrand = resultOrError.brand;
          } else {
            this.detectedCardBrand = 'GENERIC';
          }
        }
      }, (error: BaseAppError) => {
        this.cardNumber = '';
        this.detectedCardBrand = null;
        _LOGGER.error(_TAG, __SUBTAG, 'Error on CardService doDetect.');
        this.onError(error);
      }, () => {
        loadingModal.then(modal => modal.close());
        this.disableForm = false;
      });
    }
  }

  /**
   * Remove unwanted characters from input Card Holder.
   */
  private sanitizeCardHolder(): void {
    if (this.cardHolder) {
      this.cardHolder = Helper.getInstance().sanitizeInputAlphabetic(this.cardHolder);
    }
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step #2-3 data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      if (this.sessionService.getBuyInsuranceStep3MoreVehicleData()) {
        this.formInit();
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        this.closeErrorModal(modal, __SUBTAG);
      });
  }

  /**
   * Navs back to Pick Payment Method page.
   */
  public onStepBack(): void {
    this.router.navigate(['/insurance/pick-payment-method']);
  }


  /**
   * Resets CreditCardForm. Goes to first step.
   */
  public onReset(): void {
    this.formReset();
  }

  /**
   * Advance one step on CreditCardForm.
   */
  protected onContinue(): void {
    this.formInputStep = this.formInputStep + 1;
    if (this.formInputStep === 1) {
      this.detectCreditCard();
    }
  }

  /**
   * Navs to Conclusion page.
   */
  public onConfirm(): void {
    const __SUBTAG = 'onConfirm';
    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal();
    this.compraService.doPagarWithCreditCard(
      !!this.paymentInputs.cardCode ? parseInt(this.cardCode, 10) : null,
      !!this.paymentInputs.cardNumber ? parseInt(this.cardNumber, 10) : null,
      !!this.paymentInputs.cardHolder ? this.cardHolder : null,
      !!this.paymentInputs.cardExpirationDate ?
        moment(`${this.cardDueDateMonth + 1}-${this.cardDueDateYear}`, 'M-YYYY').toDate() : null
    ).subscribe((resultOrError: boolean | BaseAppError) => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'CompraService doPagar resulted in error.');
        this.onError(resultOrError);
        return;
      }
      // Succeed.
      if (!resultOrError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CompraService.');
        this.onError(new Error('Ha ocurrido un error durante el proceso de compra.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'CompraService doPagar ended.');
        this.router.navigate(['/insurance/conclusion']);
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on CompraService doPagar.');
      this.onError(error);
    }, () => {
      loadingModal.then(modal => modal.close());
      this.disableForm = false;
    });
  }


  /**
   * User changes Card Number data handler.
   */
  public onChangeCardNumber(): void {
    this.detectCreditCard();
  }

  /**
   * User changes Card Due Date (Month) data handler.
   */
  public onChangeCardDueDateMonth(): void {
    // No action needed.
  }

  /**
   * User changes Card Due Date (Year) data handler.
   */
  public onChangeCardDueDateYear(): void {
    // No action needed.
  }

  /**
   * User changes Card Holder data handler.
   */
  public onChangeCardHolder(): void {
    this.sanitizeCardHolder();
    if (this.cardHolder) {
      this.cardHolder = this.cardHolder.toUpperCase();
    }
  }

  /**
   * Card Holder input keydown handler.
   */
  public onKeyDownCardHolder(): void {
    setTimeout(() => {
      this.sanitizeCardHolder();
    }, 10);
  }

  /**
   * Card Holder blur handler.
   */
  public onBlurCardHolder(): void {
    this.sanitizeCardHolder();
  }

  /**
   * User changes Card Code data handler.
   */
  public onChangeCardCode(): void {
    // No action needed.
  }

  public closeErrorModal(modal: NgbModalRef, subtag: string): void {
    modal.result
      .then(result => {
        _LOGGER.debug(_TAG, subtag, 'Error modal closed with result:', result);
      })
      .catch(reason => {
        _LOGGER.debug(_TAG, subtag, 'Error modal dismissed with reason:', reason);
      })
      .finally(() => {
        this.viewportScroller.scrollToAnchor('app-main-header');
      });
  }
}
