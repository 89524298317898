export class ChannelProvinceModel {
    public id: number;
    public channelId: number;
    public channelDescription: string;
    public provinceId: string;
    public provinceDescription: string;
    public enableCarValue: boolean;
    public minPorcentualValue: number;
    public maxPorcentualValue: number;

    constructor(id: number, channelId: number,  channelDescription: string, provinceId: string, provinceDescription: string,
        enableCarValue: boolean, minPorcentualValue: number, maxPorcentualValue: number) {
        this.id = id;
        this.channelId = channelId;
        this.channelDescription = channelDescription;
        this.provinceId = provinceId;
        this.provinceDescription = provinceDescription;
        this.enableCarValue = enableCarValue;
        this.minPorcentualValue = minPorcentualValue;
        this.maxPorcentualValue = maxPorcentualValue;
      }
}