// Core.
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
// Models & Interfaces.
import {BaseAppError} from '../error/base-app-error';
import {UserVerifyResultModel} from '../../models/user-verify-result.model';
import {IDoUserVerifyResponse} from './i-do-user-verify-response';
// Services.
import {ErrorService} from '../error/error.service';
import {PersistenceService} from '../persistence/persistence.service';
import {CotizadorService} from '../cotizador/cotizador.service';
// Shared.
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {SimpleLogger} from '../../shared/simple-logger.shared';

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'UserService';
_LOGGER.debug(_TAG, 'loaded.');


const _CACHE_CONFIG = {
  CHECKIN_TOKEN: {
    KEY: 'ckeckin_token',
    AGE: 60 * 60 * 1000 // 60m * 60s * 1000ms. =1h
  }
};


/**
 * User Service.
 */
@Injectable({providedIn: 'root'})
export class UserService {
  private static singletonInstance: UserService; // Used for JwtModule integration.

  private checkinCompleted = false;
  private checkinToken: string;

  private userPublicAccess = false;
  private userPublicAccessSecret: string;

  private baseURL = `${_CONFIG.apiBaseURL}/user`;

  private cacheValidatedPhones: { [key: string]: boolean } = {};

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private cotizadorService: CotizadorService,
  ) {
    const __SUBTAG = 'constructor';
    _LOGGER.debug(_TAG, __SUBTAG, 'Service created.');
    UserService.singletonInstance = this;
  }

  public static getSingletonInstance(): UserService {
    return UserService.singletonInstance;
  }

  public static getCheckinToken(): string {
    const singletonInstance = UserService.getSingletonInstance();
    if (singletonInstance && singletonInstance.checkinCompleted) {
      return singletonInstance.checkinToken;
    }
    return null;
  }

  private setCheckinToken(token: string): void {
    this.checkinToken = token;
    PersistenceService.store(_CACHE_CONFIG.CHECKIN_TOKEN.KEY, token);
  }

  public isUserCheckinCompleted(): boolean {
    return this.checkinCompleted;
  }

  public isUserPublicAccess(): boolean {
    return this.userPublicAccess;
  }

  public enableUserPublicAccess(): void {
    this.userPublicAccess = true;
  }

  public disableUserPublicAccess(): void {
    this.userPublicAccess = false;
  }

  public setUserPublicAccessSecret(secret: string): void {
    this.userPublicAccessSecret = secret;
  }

  public getUserPublicAccessSecret(): string {
    return this.isUserPublicAccess() ? this.userPublicAccessSecret || null : null;
  }

  /**
   * Do User CheckIn.
   */
  public doCheckIn(token: string): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doCheckIn';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/checkin`;
    const payload = {};
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<any>(endpointURL, payload, {observe: 'response', headers: {Authorization: `Bearer ${token}`}})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<any>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<any>) => response.ok),
        // Store in cache.
        tap((success: boolean) => {
          this.checkinCompleted = success;
          if (this.isUserCheckinCompleted()) {
            this.setCheckinToken(token);
          }
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  /**
   * Retrieve User CheckIn.
   */
  public retrieveCheckIn(): boolean {
    const __SUBTAG = 'retrieveCheckIn';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');
    const token = PersistenceService.retrieve(_CACHE_CONFIG.CHECKIN_TOKEN.KEY, _CACHE_CONFIG.CHECKIN_TOKEN.AGE);
    const isValidToken = !!token;
    if (isValidToken) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Token:', token);
      this.checkinCompleted = true;
      if (this.isUserCheckinCompleted()) {
        this.setCheckinToken(token);
      }
    }
    return isValidToken;
  }

  /**
   * Do User phone validate.
   */
  public doPhoneValidate(phone: string): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doPhoneValidate';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.', 'Phone:', phone);

    if (this.cacheValidatedPhones.hasOwnProperty(phone)) {
      _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', this.cacheValidatedPhones[phone]);
      return of(this.cacheValidatedPhones[phone]);
    }

    const endpointURL = `${this.baseURL}/phone/validate?phone=${phone}`;
    const payload = {};
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<any>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<any>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<any>) => response.ok),
        // Store in cache.
        tap((success: boolean) => {
          this.cacheValidatedPhones[phone] = success;
        }),
        // Error handler.
        catchError(error => {
          _LOGGER.debug(_TAG, __SUBTAG, 'error response:', error);
          const appError: BaseAppError = this.errorService.getAppError(error);
          // Map error 400 as FAILED.
          const failed = (appError.getCode() === ErrorService.getApiHttpErrorCode(400));
          // Store in cache only 400 error cases.
          if (failed) {
            this.cacheValidatedPhones[phone] = !failed;
          }
          return of(appError);
        })
      );
  }

  /**
   * Do User check verification .
   */
  private doUserVerify(endpointURL: string, payload: any): Observable<UserVerifyResultModel | BaseAppError> {
    const __SUBTAG = 'doUserVerify';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);
    return this.http.post<IDoUserVerifyResponse>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IDoUserVerifyResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<IDoUserVerifyResponse>) => new UserVerifyResultModel(response.body.status, response.body.detail)),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Do User check verification phone.
   */
  public doUserVerifyPhone(phone: string): Observable<UserVerifyResultModel | BaseAppError> {
    const __SUBTAG = 'doUserVerifyPhone';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.', 'Phone:', phone);
    const endpointURL = `${this.baseURL}/verify/phone`;
    const payload = {
      phone,
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    return this.doUserVerify(endpointURL, payload);
  }

  /**
   * Do User check verification mail.
   */
  public doUserVerifyMail(mail: string): Observable<UserVerifyResultModel | BaseAppError> {
    const __SUBTAG = 'doUserVerifyMail';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.', 'Email:', mail);
    const endpointURL = `${this.baseURL}/verify/mail`;
    const payload = {
      mail,
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    return this.doUserVerify(endpointURL, payload);
  }
}
