// Models.
import { VehicleVersionModel } from './vehicle-version.model';


export class VehicleModelModel {
  public id: number;
  public name: string;
  public versions: VehicleVersionModel[];

  constructor(id: number, name: string, versions: VehicleVersionModel[] = []) {
    this.id = id;
    this.name = name;
    this.versions = versions;
  }
}
