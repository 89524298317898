// Core.
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
// Models & Interfaces.
import { BaseAppError } from '../../../../services/error/base-app-error';
import { PoliciesIssuedResultModel } from '../../../../models/policies-issued-result.model';
import { PolicyIssuedModel } from '../../../../models/policy-issued.model';
// Services.
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { ErrorService } from '../../../../services/error/error.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { PolizaService } from '../../../../services/poliza/poliza.service';
import { SessionService } from '../../../../services/session/session.service';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


interface DocumentFlags {
  policy: boolean;
  certificate: boolean;
  request: boolean;
  card: boolean;
  coupon: boolean;
}

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PoliciesIssuedPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Policies Issued Page.
 */
@Component({
  selector: 'app-policies-issued-page',
  templateUrl: './policies-issued-page.component.html',
  styleUrls: ['./policies-issued-page.component.scss']
})
export class PoliciesIssuedPageComponent implements OnInit {
  /* Form controls. */
  @ViewChild('filtersForm') protected filtersForm: NgForm;
  @ViewChild('download') protected download: ElementRef;
  @ViewChild('modalContentDownload') public modalContentDownload: ElementRef;
  public disableForm: boolean;
  public loadingResults: boolean;
  public inputDocument: string;
  public inputDomain: string;
  public policiesIssued: PolicyIssuedModel[];
  public pageSize: number;
  public pageNumber: number;
  private lastFilterDocument: string;
  private lastFilterDomain: string;
  public totalResultItemsInQuery: number;
  public firstItemIndexInView: number;
  public lastItemIndexInView: number;
  public lastPageIndex: number;
  public selectedDocuments: DocumentFlags;
  protected modalRef: NgbModalRef;
  public selectedPolicy: PolicyIssuedModel;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private modalsService: ModalsService,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private polizaService: PolizaService
  ) {
  }

  /**
   * Returns TRUE when pagination is permitted.
   */
  public get canPaginate(): boolean {
    if (this.loadingResults || !this.policiesIssued || !this.policiesIssued.length) {
      return false;
    }

    return (this.policiesIssued.length > 0);
  }

  /**
   * Returns TRUE when there is a previous page available.
   */
  public get canPrevPage(): boolean {
    return this.canPaginate && (this.pageNumber > 1);
  }

  /**
   * Returns TRUE when there is a next page available.
   */
  public get canNextPage(): boolean {
    return this.canPaginate && (this.pageNumber < this.lastPageIndex);
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;

    this.lastFilterDocument = null;
    this.lastFilterDomain = null;
    this.pageNumber = 0;
    this.onChangePageNumber(true);
    this.pageSize = 10;
    this.onChangePageSize(true);

    this.policiesIssued = null;
    this.totalResultItemsInQuery = 0;
    this.firstItemIndexInView = 0;
    this.lastItemIndexInView = 0;
    this.lastPageIndex = 0;

    this.formReset();
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.filtersForm && this.filtersForm.pristine) {
      this.filtersForm.control.reset();
    }

    this.inputDocument = null;
    this.onChangeDocument();
    this.inputDomain = null;
    this.onChangeDomain();

    this.disableForm = false;

    setTimeout(() => {
      if (this.filtersForm) {
        this.filtersForm.control.markAsUntouched();
        this.filtersForm.control.markAsPristine();
        if (this.filtersForm.controls && this.filtersForm.controls.inputDocument) {
          this.filtersForm.controls.inputDocument.markAsUntouched();
          this.filtersForm.controls.inputDocument.markAsPristine();
        }
        if (this.filtersForm.controls && this.filtersForm.controls.inputDomain) {
          this.filtersForm.controls.inputDomain.markAsUntouched();
          this.filtersForm.controls.inputDomain.markAsPristine();
        }
      }
    }, 100);

    this.loadingResults = false;
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    this.formInit();
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * Resets form filters.
   */
  public onFiltersClean(): void {
    this.formReset();
  }

  /**
   * User changes Document filter handler.
   */
  public onChangeDocument(): void {
    // No action needed.
  }

  /**
   * User changes Domain filter handler.
   */
  public onChangeDomain(): void {
    // No action needed.
  }

  /**
   * Page Size change handler.
   * Changes page size and triggers filters apply (if not in silent mode).
   *
   * @param silent    Flag that indicates silent mode.
   */
  public onChangePageSize(silent: boolean = false): void {
    if (!this.pageSize || (this.pageSize < 1)) {
      this.pageSize = 1;
    }
    if (this.pageSize > 999) {
      this.pageSize = 999;
    }

    const newLastPageIndex = Math.ceil(this.totalResultItemsInQuery / this.pageSize);
    if (this.pageNumber > newLastPageIndex) {
      this.pageNumber = newLastPageIndex;
      this.onChangePageNumber(true);
    }

    if (!silent) {
      this.onFiltersApply(false, true);
    }
  }

  /**
   * Page Number change handler.
   * Changes page number and triggers filters apply (if not in silent mode).
   *
   * @param silent    Flag that indicates silent mode.
   */
  protected onChangePageNumber(silent: boolean = false): void {
    if (!silent) {
      this.onFiltersApply(false, true);
    }
  }

  /**
   * Gets next page of results.
   */
  public onPageNext(): void {
    this.pageNumber = this.pageNumber + 1;
    this.onFiltersApply(false, true);
  }

  /**
   * Gets previous page of results.
   */
  public onPagePrev(): void {
    this.pageNumber = this.pageNumber - 1;
    this.onFiltersApply(false, true);
  }

  /**
   * Applies form filters.
   */
  public onFiltersApply(resetPage: boolean = false, useLastInput: boolean = false): void {
    const __SUBTAG = 'onFiltersApply';

    this.loadingResults = true;
    this.policiesIssued = null;
    this.totalResultItemsInQuery = 0;
    this.firstItemIndexInView = 0;
    this.lastItemIndexInView = 0;
    this.lastPageIndex = 0;

    if (resetPage) {
      this.pageNumber = 1;
      this.onChangePageNumber(true);
    }

    this.polizaService.getPoliciesIssuesFiltered((useLastInput ? this.lastFilterDocument : this.inputDocument),
      (useLastInput ? this.lastFilterDomain : this.inputDomain), this.pageNumber - 1, this.pageSize)
      .subscribe((resultOrError: PoliciesIssuedResultModel | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Poliza Service getPoliciesIssuesByDealergetPoliciesIssuesByDealer resulted in error.');
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        if (resultOrError) {
          _LOGGER.debug(_TAG, __SUBTAG, 'Poliza Service getPoliciesIssuesByDealer completed.');
          this.policiesIssued = resultOrError.pageResults;
          this.totalResultItemsInQuery = resultOrError.totalItems || 0;
          this.firstItemIndexInView = ((this.pageNumber - 1) * this.pageSize) + 1;
          this.lastItemIndexInView = Math.min(this.pageNumber * this.pageSize, this.totalResultItemsInQuery);
          this.lastPageIndex = Math.ceil(this.totalResultItemsInQuery / this.pageSize);
          if (!useLastInput) {
            this.lastFilterDocument = this.inputDocument || '';
            this.lastFilterDomain = this.inputDomain || '';
          }
        } else {
          _LOGGER.error(_TAG, __SUBTAG, 'Checkin failed.');
          this.onError(new Error('Ha ocurrido un error durante la obtención de las pólizas.'));
        }
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on getPoliciesIssuesByDealer.');
        this.onError(error);
      }, () => {
        this.loadingResults = false;
      });
  }

  public selectPolicy(policyIssued: PolicyIssuedModel): void {
    if (!policyIssued.checked) {
      return;
    }
    this.initDocumentsDownload();
    this.selectedPolicy = policyIssued;
    this.modalRef = this.modalsService.showCustomModal(this.modalContentDownload, 'cont-descarga', 'lg', true);
  }

  public downloadDocuments(): void {
    if (this.selectedDocuments.policy) {
      this.download.nativeElement.setAttribute('href', this.selectedPolicy.policyUrl);
      this.download.nativeElement.click();
    }
    if (this.selectedDocuments.request) {
      this.download.nativeElement.setAttribute('href', this.selectedPolicy.requestUrl);
      this.download.nativeElement.click();
    }
    if (this.selectedDocuments.certificate) {
      this.download.nativeElement.setAttribute('href', this.selectedPolicy.certificateUrl);
      this.download.nativeElement.click();
    }
    if (this.selectedDocuments.card) {
      this.download.nativeElement.setAttribute('href', this.selectedPolicy.cardUrl);
      this.download.nativeElement.click();
    }
    if (this.selectedDocuments.coupon) {
      this.download.nativeElement.setAttribute('href', this.selectedPolicy.couponUrl);
      this.download.nativeElement.click();
    }
    this.closeModal();
  }

  private initDocumentsDownload(): void {
    this.selectedDocuments = {
      policy: false,
      certificate: false,
      request: false,
      card: false,
      coupon: false
    };
  }

  public closeModal(): void {
    this.selectedPolicy.checked = false;
    this.modalRef.close();
  }
}
