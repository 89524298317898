// Core.
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models & Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { IDoSolicitarResponse } from './i-do-solicitar-response';
// Services.
import { CotizadorService } from '../cotizador/cotizador.service';
import { ErrorService } from '../error/error.service';
import { SessionService } from '../session/session.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';
import { IDoContinueByPhoneResponse } from './i-do-continue-by-phone-response';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'SolicitudService';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Insurance Applications Service.
 */
@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  private baseURL = `${_CONFIG.apiBaseURL}/solicitud`;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService
  ) {
  }

  /**
   * Do Solicitar.
   */
  public doSolicitar(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doSolicitar';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const broker = this.sessionService.getSelectedBroker();

    const endpointURL = `${this.baseURL}/solicitar/${broker.code}/${broker.insuranceQuote.id}/${broker.selectedInsuranceOption.id}`;
    const payload = {
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoSolicitarResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoSolicitarResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Return success flag.
        map((response: HttpResponse<IDoSolicitarResponse>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  /**
   * Ends Insurance Quote selection. Resets state.
   */
  public doContinueByPhone(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doContinueByPhone';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const endpointURL = `${this.baseURL}/solicitar/continuaTelefono`;
    const payload = {
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoContinueByPhoneResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoContinueByPhoneResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Return success flag.
        map((response: HttpResponse<IDoContinueByPhoneResponse>) => {
          if (response.ok) {
            CotizadorService.doReset();
            this.sessionService.reset();
          }
          return response.ok;
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
