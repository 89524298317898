// Core.
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// Components.
import {HowToContinuePublicAccessComponent} from '../../components/how-to-continue-public-access/how-to-continue-public-access.component';
// Models & Interfaces.
import {BaseAppError} from '../../../../services/error/base-app-error';
import {BrokerInsuranceQuoteModel} from '../../../../models/broker-insurance-quote.model';
import {BrokerInsuranceQuoteOptionModel} from '../../../../models/broker-insurance-quote-option.model';
import {BROKER_INSURANCE_FAMILY, BrokerModel} from '../../../../models/broker.model';
import {IDoExcelExportWorksheet} from '../../../../services/excel/i-do-excel-export-worksheet';
import {IQuoteInsuranceStep1VehicleData} from '../../../../services/session/i-quote-insurance-step1-vehicle-data';
import {IQuoteInsuranceStep2AboutYou} from '../../../../services/session/i-quote-insurance-step2-about-you';
import {PublicAccessContinueByMethod} from '../../../../services/public-access/public-access-continue-by-method';
// Services.
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {SessionService} from '../../../../services/session/session.service';
import {ExcelService} from '../../../../services/excel/excel.service';
import {UserService} from '../../../../services/user/user.service';
// Shared.
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {ReCaptchaV3Service} from 'ng-recaptcha';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PickBrokerPageComponent';

_LOGGER.debug(_TAG, 'loaded.');


/**
 * Pick Broker page.
 */
@Component({
  selector: 'app-pick-broker-page',
  templateUrl: './pick-broker-page.component.html',
  styleUrls: ['./pick-broker-page.component.scss']
})
export class PickBrokerPageComponent implements OnInit {
  public brokers: BrokerModel[];
  public loadingBrokers = false;
  public vehicleData: IQuoteInsuranceStep1VehicleData;
  public personData: IQuoteInsuranceStep2AboutYou;
  public showAllRisk = false;
  public showThirdParty = false;
  public showThirdPartyFull = false;
  public isDownloading = false;

  constructor(
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly modalsService: ModalsService,
    private readonly sessionService: SessionService,
    private readonly cotizadorService: CotizadorService,
    private readonly excelService: ExcelService,
    private readonly userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
  }

  /**
   * Returns TRUE if there are Brokers with pending operations.
   */
  public get isBrokersPendingCotizacion(): boolean {
    if (this.loadingBrokers) {
      return false;
    }

    if (!this.brokers || !this.brokers.length || (this.brokers.length < 1)) {
      return false;
    }

    return this.brokers.some(b => b.loadingInsuranceQuote);
  }

  /**
   * Returns TRUE if there is at least on Broker with at least one valid Insurance Quote.
   */
  public get brokerQuotesExists(): boolean {
    if (this.loadingBrokers) {
      return false;
    }

    if (!this.brokers || !this.brokers.length || (this.brokers.length < 1)) {
      return false;
    }

    return this.brokers.some(b => b.insuranceQuote &&
      !!(b.insuranceQuote.allRiskFromValue || b.insuranceQuote.thirdPartyFromValue || b.insuranceQuote.thirdPartyFullFromValue));
  }

  /**
   * Gets Brokers.
   */
  private loadBrokers(): void {
    const __SUBTAG = 'loadBrokers';
    _LOGGER.info(_TAG, __SUBTAG, 'started.');

    this.brokers = null;
    this.loadingBrokers = true;
    this.showAllRisk = true;
    this.showThirdParty = true;
    this.showThirdPartyFull = true;
    this.cotizadorService.getBrokers().subscribe((resultOrError: BrokerModel[] | BaseAppError) => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cotizador getBrokers resulted in error.');
        this.onError(resultOrError);
        return;
      }

      // Succeed.
      if (!resultOrError || (!resultOrError.length && (resultOrError.length !== 0))) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Brokers.');
        this.onError(new Error('Ha ocurrido un error durante la obtención de los Brokers.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'Cotizador got Brokers.');
        this.brokers = resultOrError;
        this.loadingBrokers = false;

        // Get all Brokers insurance options.
        let brokersWithoutAllRisk = 0;
        let brokersWithoutThirdParty = 0;
        let brokersWithoutThirdPartyFull = 0;
        this.brokers.forEach(broker => {
          broker.loadingInsuranceQuote = true;
          this.cotizadorService.doCotizarByBroker(broker)
            .subscribe((doCotizarByBrokerResultOrError: BrokerInsuranceQuoteModel | BaseAppError) => {
              // Failed.
              if (doCotizarByBrokerResultOrError instanceof BaseAppError) {
                _LOGGER.error(_TAG, __SUBTAG, 'Cotizador doCotizarByBroker resulted in error.', '; Broker:', broker,
                  '; Error:', doCotizarByBrokerResultOrError);
                broker.successLoadInsuranceQuote = false;
                brokersWithoutAllRisk = brokersWithoutAllRisk + 1;
                brokersWithoutThirdParty = brokersWithoutThirdParty + 1;
                brokersWithoutThirdPartyFull = brokersWithoutThirdPartyFull + 1;
                return;
              }

              // Succeed.
              _LOGGER.debug(_TAG, __SUBTAG, 'Cotizador got Broker Insurance Quotes.', '; Broker:', broker);
              broker.successLoadInsuranceQuote = true;
              if (!broker.insuranceQuote || !broker.insuranceQuote.allRiskFromValue) {
                brokersWithoutAllRisk = brokersWithoutAllRisk + 1;
              }
              if (!broker.insuranceQuote || !broker.insuranceQuote.thirdPartyFromValue) {
                brokersWithoutThirdParty = brokersWithoutThirdParty + 1;
              }
              if (!broker.insuranceQuote || !broker.insuranceQuote.thirdPartyFullFromValue) {
                brokersWithoutThirdPartyFull = brokersWithoutThirdPartyFull + 1;
              }
            }, (error: any) => {
              _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador doCotizarByBroker.', '; Broker:', broker, '; Error:', error);
              broker.successLoadInsuranceQuote = false;
              brokersWithoutAllRisk = brokersWithoutAllRisk + 1;
              brokersWithoutThirdParty = brokersWithoutThirdParty + 1;
              brokersWithoutThirdPartyFull = brokersWithoutThirdPartyFull + 1;
            }, () => {
              broker.loadingInsuranceQuote = false;
              if (brokersWithoutAllRisk >= this.brokers.length) {
                this.showAllRisk = false;
              }
              if (brokersWithoutThirdParty >= this.brokers.length) {
                this.showThirdParty = false;
              }
              if (brokersWithoutThirdPartyFull >= this.brokers.length) {
                this.showThirdPartyFull = false;
              }
            });
        });
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador getBrokers.');
      this.onError(error);
      this.showAllRisk = false;
      this.showThirdParty = false;
      this.showThirdPartyFull = false;
      this.loadingBrokers = false;
    });
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks steps #1 & #2 data.
   * Loads Brokers data.
   * Navs back if there is no insurance data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      if (this.vehicleData && this.personData) {
        this.loadBrokers();
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Navs back to About You page.
   */
  public onStepBack(): void {
    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsurancePickBroker_Back')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });
    this.router.navigate(['/insurance/about-you']);
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Resets session & Cotizador data on errors.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    this.sessionService.reset();
    CotizadorService.doReset();

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      }).finally(() => {
        this.router.navigate(['/home']);
      });
    });
  }

  /**
   * Navs to Share Insurance page.
   */
  public onShare(): void {
    this.sessionService.setAllActiveBrokers(this.brokers);
    this.router.navigate(['/insurance/share']);
  }

  /**
   * Sets selected Broker on Session.
   * Navs to Pick (Insurance) Family page.
   */
  private onPickBroker(broker: BrokerModel): void {
    if (!this.userService.isUserPublicAccess()) {
      broker.selectedInsuranceOption = null;
    }
    this.sessionService.setSelectedBroker(broker);

    const recaptchaSubscription = this.recaptchaV3Service.execute('QuoteInsurancePickBroker_Select')
      .subscribe((/*token: string*/) => {
        recaptchaSubscription.unsubscribe();
      });

    if (this.userService.isUserPublicAccess()) {
      const modalRef = this.modalsService.showCustomModal(HowToContinuePublicAccessComponent, 'how-to-continue-public-access', 'lg', true,false);
      modalRef.result.then((result: PublicAccessContinueByMethod) => {
        _LOGGER.debug('Public access Modal closed with:', result);
      }).catch((error) => {
        _LOGGER.debug('Public access Modal dimissed with:', error);
      });
    } else {
      this.router.navigate(['/insurance/pick-family']);
    }
  }

  /**
   * Selects Third Party Full Insurance Options of a given Broker.
   */
  public onPickBrokerThirdPartyFullOptions(broker: BrokerModel): void {
    broker.selectedInsuranceFamily = BROKER_INSURANCE_FAMILY.THIRD_PARTY_FULL;
    if (this.userService.isUserPublicAccess()) {
      broker.selectedInsuranceOption = broker.insuranceQuote.minThirdPartyFullOption;
    }
    this.onPickBroker(broker);
  }

  /**
   * Selects All Risk Insurance Options of a given Broker.
   */
  public onPickBrokerAllRiskOptions(broker: BrokerModel): void {
    broker.selectedInsuranceFamily = BROKER_INSURANCE_FAMILY.ALL_RISK;
    if (this.userService.isUserPublicAccess()) {
      broker.selectedInsuranceOption = broker.insuranceQuote.minAllRiskOption;
    }
    this.onPickBroker(broker);
  }

  /**
   * Selects Third Party Insurance Options of a given Broker.
   */
  public onPickBrokerThirdPartyOptions(broker: BrokerModel): void {
    broker.selectedInsuranceFamily = BROKER_INSURANCE_FAMILY.THIRD_PARTY;
    if (this.userService.isUserPublicAccess()) {
      broker.selectedInsuranceOption = broker.insuranceQuote.minThirdPartyOption;
    }
    this.onPickBroker(broker);
  }

  public BrokerInsuranceQuoteOptionToDataSheet(brokerInsuranceQuoteOptionModel: BrokerInsuranceQuoteOptionModel,
    insuredAmount: number): any {
    return {
      'Descripción': brokerInsuranceQuoteOptionModel.name ? brokerInsuranceQuoteOptionModel.name : 'Sin descripción',
      Precio: brokerInsuranceQuoteOptionModel.amount,
      Detalles: brokerInsuranceQuoteOptionModel.bullets.join(' | '),
      'Monto Asegurado': insuredAmount
    };
  }
  /*
  * Init Download Excel file with insurance data, setQuotes as Downloaded and Open popup 'HowToContinuePublicAccess'
  */
  public onClickDownload(): void{
    const __SUBTAG = 'onClickDownload';
    _LOGGER.info(_TAG, __SUBTAG, 'started.');
    this.onDownload();
    this.cotizadorService.doSetQuotesDownloaded().subscribe((result: boolean | BaseAppError) => {
      // Failed.
      if (result instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cotizador doSetQuotesDownloaded resulted in error.');
        this.onError(result);
        return;
      }

      // Succeed.
      if (result) {
        _LOGGER.debug(`Set Quotes Downloaded Finished Ok`);
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on Cotizador SetQuotesDownloaded.');
      this.onError(error);
      this.showAllRisk = false;
      this.showThirdParty = false;
      this.showThirdPartyFull = false;
      this.loadingBrokers = false;
    });
    if (this.userService.isUserPublicAccess()) {
      const modalRef = this.modalsService.showCustomModal(HowToContinuePublicAccessComponent, 'how-to-continue-public-access', 'lg', true,true);

      modalRef.result.then((result: PublicAccessContinueByMethod) => {
        _LOGGER.debug('Public access Modal closed with:', result);
      }).catch((error) => {
        _LOGGER.debug('Public access Modal dimissed with:', error);
      });
    }
  }

  /**
   * Download an Excel file with insurance data for all the Brokers
   */
  public onDownload(): void {
    this.isDownloading = true;
    const worksheets: IDoExcelExportWorksheet[] = [{
      name: 'Vehículo',
      data: [
        {
          'Cotización': CotizadorService.getInsuranceQuoteMainId(),
          Concesionario: CotizadorService.getInsuranceQuoteOriginDealer() || 'N/A',
          Fecha: CotizadorService.getInsuranceQuoteDate() || new Date(),
          Marca: this.vehicleData.vehicleBrand.name,
          Modelo: this.vehicleData.vehicleModel.name,
          'Versión': this.vehicleData.vehicleVersion.name,
          'Kilómetros': this.vehicleData.vehicleYear.brandNew ? 0 : 'N/A',
          'Año': this.vehicleData.vehicleYear.year,
          Uso: this.vehicleData.vehicleUseType.name,
          Provincia: this.personData.vehicleProvince.name,
          Ciudad: this.personData.vehicleLocation.name,
          'Código Postal': this.personData.vehiclePostalCode.code
        }
      ]
    }];
    const insuranceWorksheets: IDoExcelExportWorksheet[] = this.brokers
      .filter((broker: BrokerModel) => broker.successLoadInsuranceQuote && broker.insuranceQuote)
      .map((broker: BrokerModel) => {
        return {
          name: broker.name,
          data: broker.insuranceQuote.allRiskOptions.map(
            (allRisk: BrokerInsuranceQuoteOptionModel) => this.BrokerInsuranceQuoteOptionToDataSheet(allRisk, broker.insuranceQuote.insuredAmount)
          ).concat(broker.insuranceQuote.thirdPartyFullOptions.map(
            (thirdPartyFull: BrokerInsuranceQuoteOptionModel) => this.BrokerInsuranceQuoteOptionToDataSheet(thirdPartyFull, broker.insuranceQuote.insuredAmount))
          ).concat(broker.insuranceQuote.thirdPartyOptions.map(
            (thirdParty: BrokerInsuranceQuoteOptionModel) => this.BrokerInsuranceQuoteOptionToDataSheet(thirdParty, broker.insuranceQuote.insuredAmount))
          )
        };
      });
    this.excelService.exportAsExcelFile({
      name: `cotizacion-${CotizadorService.getInsuranceQuoteMainId()}`,
      worksheets: worksheets.concat(insuranceWorksheets)
    });
    this.isDownloading = false;
  }

  public getQuoteId() : number{
    return CotizadorService.getInsuranceQuoteMainId();
  }

}
