import { Directive, ElementRef, HostListener } from '@angular/core';


const _ALLOWED_KEY_CODES: string[] = [
  'Delete', 'Backspace', 'Tab', 'Escape', 'Enter', 'NumpadEnter', 'Home', 'End', 'ArrowLeft', 'ArrowRight'
];
const _ALLOWED_KEY_CTRL_CODES: string[] = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];
const _LETTERS_KEY_CODES: string[] = [
  'Space',
  'KeyA', 'KeyB', 'KeyC', 'KeyD', 'KeyE', 'KeyF', 'KeyG', 'KeyH', 'KeyI', 'KeyJ', 'KeyK', 'KeyL', 'KeyM',
  'KeyN', 'KeyO', 'KeyP', 'KeyQ', 'KeyR', 'KeyS', 'KeyT', 'KeyU', 'KeyV', 'KeyW', 'KeyX', 'KeyY', 'KeyZ'
];
const _LATIN_N_KEY_CODE = 'Semicolon';
const _LATIN_N_KEY = 'Ñ';

const _ALLOWED_KEY_NUMBERS: number[] = [
  46, // Delete.
  8, // Backspace.
  9, // Tab.
  27, // Escape.
  13, // Enter/NumpadEnter.
  35, // End.
  36, // Home.
  37, // ArrowLeft.
  39 // ArrowRight.
];
const _ALLOWED_KEY_CTRL_NUMBERS: number[] = [
  65, // "A".
  67, // "C".
  86, // "V".
  88 // "X".
];
const _LETTERS_KEY_NUMBERS: number[] = [
  32, // Space.
  65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77,
  78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90
];
const _LATIN_N_KEY_NUMBER = 192;


/**
 * Directive that limits the characters allowed on an input element to be only letters aA-zZ and spaces.
 * Also limit the command controls and copy&paste and drag&drop operations to only insert those characters.
 */
@Directive({
  selector: '[appAlphabeticOnlyInput]'
})
export class AlphabeticOnlyInputDirective {

  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef
  ) {
    this.el = this.elementRef.nativeElement;
  }


  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent): void {
    if ( // Single keys.
      (_ALLOWED_KEY_NUMBERS.indexOf(e.keyCode) !== -1) || (_ALLOWED_KEY_CODES.indexOf(e.code) !== -1) ||
      // Control/Commands.
      (((_ALLOWED_KEY_CTRL_NUMBERS.indexOf(e.keyCode) !== -1) || (_ALLOWED_KEY_CTRL_CODES.indexOf(e.code) !== -1)) &&
        (e.ctrlKey || e.metaKey))) {
      return; // Allow.
    }

    // Ensure that it is a letter or space and stop the keypress.
    if (((_LETTERS_KEY_NUMBERS.indexOf(e.keyCode) === -1) && (_LETTERS_KEY_CODES.indexOf(e.code) === -1)) &&
      // Letter Ñ (special case).
      (((e.keyCode !== _LATIN_N_KEY_NUMBER) && (e.code !== _LATIN_N_KEY_CODE)) && (e.key.toUpperCase() !== _LATIN_N_KEY))
    ) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(e: ClipboardEvent): void {
    e.preventDefault();

    const pastedInput: string = e.clipboardData
      .getData('text/plain')
      .replace(/[^a-zA-Z ]/g, ''); // Extract only the letters and spaces.
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  public onDrop(e: any): void { // NOTE: "any" should be "DragEvent", but Angular doesn't load on Safari if "DragEvent" is used.
    e.preventDefault();

    const textData = e.dataTransfer
      .getData('text')
      .replace(/[^a-zA-Z ]/g, ''); // Extract only the letters and spaces.
    this.el.focus();
    document.execCommand('insertText', false, textData);
  }
}
