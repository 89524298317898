// Core.
import * as moment from 'moment';
import { Injectable } from '@angular/core';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PersistenceService';
_LOGGER.debug(_TAG, 'loaded.');

const _TIMESTAMP_KEY_ACCESSOR = '__$timestamp';
const _SUFFIX_KEY_ACCESSOR = '__$suffix';


@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor() {
  }

  public static store(key: string, data: any, suffix: string = ''): void {
    const __SUBTAG = 'store';
    _LOGGER.info(_TAG, __SUBTAG, 'Method invoked. Key:', key, '; Data:', data, '; Suffix:', suffix);

    try {
      localStorage.setItem(key + _SUFFIX_KEY_ACCESSOR, suffix);
      localStorage.setItem(key + suffix, JSON.stringify(data));
      localStorage.setItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR, moment().format());
    } catch (e) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Error while storing data. Key:', key, '; Error:', e);
    }
  }

  public static clear(key: string, data: any, suffix: string = ''): void {
    const __SUBTAG = 'clear';
    _LOGGER.info(_TAG, __SUBTAG, 'Method invoked. Key:', key, '; Data:', data, '; Suffix:', suffix);

    try {
      localStorage.removeItem(key + _SUFFIX_KEY_ACCESSOR);
      localStorage.removeItem(key + suffix);
      localStorage.removeItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR);
    } catch (e) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Error while clearing data. Key:', key, '; Error:', e);
    }
  }

  public static retrieve(key: string, maxAge: number, suffix: string = ''): any {
    const __SUBTAG = 'retrieve';
    _LOGGER.info(_TAG, __SUBTAG, 'Method invoked. Key:', key, '; Age:', maxAge, '; Suffix:', suffix);

    try {
      const storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR));
      const now = moment();
      if (now.diff(storedTimestamp) <= maxAge) {
        _LOGGER.debug(_TAG, __SUBTAG, 'Retrieving item. Key:', key);
        return JSON.parse(localStorage.getItem(key + suffix));
      }
      _LOGGER.debug(_TAG, __SUBTAG, 'Item expired or invalid. Key:', key);
    } catch (e) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Error while retrieving data. Key:', key, '; Error:', e);
    }
    return null;
  }

  public static isExpiredOrEmpty(key: string, maxAge: number, suffix: string = ''): boolean {
    const __SUBTAG = 'isExpiredOrEmpty';
    _LOGGER.info(_TAG, __SUBTAG, 'Method invoked. Key:', key, '; Age:', maxAge, '; Suffix:', suffix);

    try {
      const storedTimestamp = moment(localStorage.getItem(key + suffix + _TIMESTAMP_KEY_ACCESSOR));
      const now = moment();
      if (now.diff(storedTimestamp) <= maxAge) {
        _LOGGER.debug(_TAG, __SUBTAG, 'Cache data is not expired. Key:', key);
        const data = JSON.parse(localStorage.getItem(key + suffix));
        const dataIsNull = (data === null) || (data === undefined) || (data.hasOwnProperty('length') && (data.length === 0));
        _LOGGER.debug(_TAG, __SUBTAG, 'Cache data is null:', dataIsNull);
        return dataIsNull;
      }
    } catch (e) {
      _LOGGER.debug(_TAG, __SUBTAG, 'Error while checking data. Key:', key, '; Error:', e);
    }
    return true;
  }
}
