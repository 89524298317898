// Core.
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
// Services.
import {UserService} from '../../services/user/user.service';
// Shared.
import {SimpleLogger} from '../../shared/simple-logger.shared';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'CheckinGuard';
_LOGGER.debug(_TAG, 'loaded.');

/**
 * Checkin Guard.
 */
@Injectable({
  providedIn: 'root'
})
export class CheckinGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const __SUBTAG = 'canActivate';
    _LOGGER.info(_TAG, __SUBTAG, 'called.', state);

    if (this.userService.isUserCheckinCompleted() || this.userService.isUserPublicAccess()) {
      return true;
    }

    this.router.navigate(['/home'], {queryParams: state.root.queryParams});
    return false;
  }
}
