// Core.
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// Models & Interface.
import { BaseAppError } from '../../../../services/error/base-app-error';
import { IQuoteInsuranceStep1VehicleData } from '../../../../services/session/i-quote-insurance-step1-vehicle-data';
// Services.
import { CompraService } from '../../../../services/compra/compra.service';
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { ErrorService } from '../../../../services/error/error.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { SessionService } from '../../../../services/session/session.service';
// Shared.
import { Helper } from '../../../../shared/helper.shared';
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'MoreVehicleDataPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * More About You page. "Cotizar > Solicitar Seguro > Paso #2-3: Más sobre el vehículo".
 */
@Component({
  selector: 'app-more-vehicle-data-page',
  templateUrl: './more-vehicle-data-page.component.html',
  styleUrls: ['./more-vehicle-data-page.component.scss']
})
export class MoreVehicleDataPageComponent implements OnInit {
  protected vehicleData: IQuoteInsuranceStep1VehicleData;

  /* Form controls. */
  @ViewChild('moreVehicleDataForm') protected moreVehicleDataForm: NgForm;
  @ViewChild('inputVehicleVin') protected inputVehicleVin: ElementRef;
  @ViewChild('inputVehicleEngine') protected inputVehicleEngine: ElementRef;
  protected disableForm: boolean;
  protected vehiclePlate: string;
  protected vehicleVin: string;
  protected vehicleEngine: string;
  protected regexRule: string;

  private changedVehiclePlate: boolean;
  private changedVehicleVin: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService,
    private modalsService: ModalsService,
    private errorService: ErrorService,
    private compraService: CompraService
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  protected get formIsMissingData(): boolean {
    return (!this.vehicleEngine || !this.vehicleVin || !this.vehiclePlate);
  }

  /**
   * Returns TRUE while the Person fraud check is not completed. Also returns TRUE when the fraud check fails.
   */
  protected get personIsFraudListed(): boolean {
    return this.isPersonFraudCheckPending || this.sessionService.isPersonFraudListed();
  }

  /**
   * Returns TRUE while the Person fraud check is not completed.
   */
  protected get isPersonFraudCheckPending(): boolean {
    return !this.sessionService.isPersonFraudCheckCompleted();
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;
    // No init needed.
    this.formReset();
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.moreVehicleDataForm && this.moreVehicleDataForm.pristine) {
      this.moreVehicleDataForm.control.reset();
    }

    const prevData = this.sessionService.getBuyInsuranceStep3MoreVehicleData();

    if (prevData && prevData.vehiclePlate) {
      this.vehiclePlate = prevData.vehiclePlate;
    } else {
      this.vehiclePlate = null;
    }
    this.onChangeVehiclePlate();
    this.changedVehiclePlate = false;

    if (prevData && prevData.vehicleVin) {
      this.vehicleVin = prevData.vehicleVin;
    } else {
      this.vehicleVin = null;
    }
    this.onChangeVehicleVin();
    this.changedVehicleVin = false;

    if (prevData && prevData.vehicleEngine) {
      this.vehicleEngine = prevData.vehicleEngine;
    } else {
      this.vehicleEngine = null;
    }
    this.onChangeVehicleEngine();

    this.disableForm = false;
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step #2-2 data.
   * Initializes form.
   * Navs back if there is missing data.
   * Navs back to 1st page and resets session if there is BuyInsuranceStep3MoreVehicleData stored on session.
   */
  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';

    if (CotizadorService.isInitProcessCompleted()) {
      this.vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
      if (this.vehicleData && this.sessionService.getBuyInsuranceStep2AddressData()) {
        this.formInit();
        this.setRuleRegexByYear();
        return;
      }
    }

    this.onStepBack();
  }

  /**
   * Navs to Pick Payment Method page.
   */
  protected onContinue(): void {
    const __SUBTAG = 'onContinue';

    // Resets BuyInsuranceStep3MoreVehicleData on errors.
    const onBeforeShowError = () => {
      this.sessionService.setBuyInsuranceStep3MoreVehicleData(null);
    };

    this.sessionService.setBuyInsuranceStep3MoreVehicleData({
      vehiclePlate: this.vehiclePlate,
      vehicleVin: this.vehicleVin,
      vehicleEngine: this.vehicleEngine
    });

    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal();
    this.compraService.doComprar()
      .subscribe((resultOrError: boolean | BaseAppError) => {
        // Failed.
        if (resultOrError instanceof BaseAppError) {
          _LOGGER.error(_TAG, __SUBTAG, 'CompraService doComprar resulted in error.');
          onBeforeShowError();
          this.onError(resultOrError);
          return;
        }

        // Succeed.
        if (!resultOrError) {
          _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CompraService.');
          onBeforeShowError();
          this.onError(new Error('Ha ocurrido un error durante la inicialización del proceso de compra.'));
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'CompraService initialized.');
          this.router.navigate(['/insurance/pick-payment-method']);
        }
      }, (error: any) => {
        _LOGGER.error(_TAG, __SUBTAG, 'Error on CompraService doComprar.');
        onBeforeShowError();
        this.onError(error);
      }, () => {
        loadingModal.then(modal => modal.close());
        this.disableForm = false;
      });
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data on 401 errors.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
  }

  /**
   * Navs back to Address Data page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/address-data']);
  }

  /**
   * User changes Vehicle Plate data handler.
   */
  protected onChangeVehiclePlate(): void {
    this.changedVehiclePlate = true;
    if (this.vehiclePlate) {
      this.vehiclePlate = this.vehiclePlate.toUpperCase();
    }
  }

  /**
   * Vehicle Plate blur handler.
   */
  protected onBlurVehiclePlate(): void {
    if (this.vehiclePlate && this.changedVehiclePlate) {
      this.changedVehiclePlate = false;
      setTimeout(() => {
        if (this.inputVehicleVin && this.inputVehicleVin.nativeElement &&
          this.inputVehicleVin.nativeElement.focus) {
          this.inputVehicleVin.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Vehicle Plate input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownVehiclePlate(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurVehiclePlate();
    }
  }

  /**
   * User changes Vehicle VIN data handler.
   */
  protected onChangeVehicleVin(): void {
    this.changedVehicleVin = true;
    if (this.vehicleVin) {
      this.vehicleVin = this.vehicleVin.toUpperCase();
    }
  }

  /**
   * Vehicle VIN blur handler.
   */
  protected onBlurVehicleVin(): void {
    if (this.vehicleVin && this.changedVehicleVin) {
      this.changedVehicleVin = false;
      setTimeout(() => {
        if (this.inputVehicleEngine && this.inputVehicleEngine.nativeElement &&
          this.inputVehicleEngine.nativeElement.focus) {
          this.inputVehicleEngine.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Vehicle VIN input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownVehicleVin(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurVehicleVin();
    }
  }

  /**
   * User changes Vehicle Engine data handler.
   */
  protected onChangeVehicleEngine(): void {
    if (this.vehicleEngine) {
      this.vehicleEngine = this.vehicleEngine.toUpperCase();
    }
  }

  /**
   * Set Rule Regex by Year Vehicle.
   */
   protected setRuleRegexByYear(): void {
    _LOGGER.info('setRuleRegexByYear.', this.vehicleData.vehicleYear.year, this.vehicleData.vehicleYear.brandNew);
    if (this.vehicleData.vehicleYear.year < 2016) {
      this.regexRule = '^([a-zA-Z]{3}[0-9]{3})';
    }
    if (this.vehicleData.vehicleYear.year > 2016) {
      this.regexRule = '^([a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2})';
    }
    if (this.vehicleData.vehicleYear.year === 2016) {
      this.regexRule = '^([a-zA-Z]{3}[0-9]{3})|([a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2})';
    }
    if (this.vehicleData.vehicleYear.brandNew) {
      this.regexRule = '^([a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2})|(ET)$';
    }
   }
}
