// Core.
import * as moment from 'moment';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// Models & Interfaces.
import {BaseAppError} from '../error/base-app-error';
import {ClientDataModel} from '../../models/client-data.model';
import {IDoCompraComprarResponse} from './i-do-compra-comprar-response';
import {IDoCompraPagarResponse} from './i-do-compra-pagar-response';
import {IDoCompraMailResponse} from './i-do-compra-mail-response';
import {IDoCompraObtainClientDataResponse} from './i-do-compra-obtain-client-data-response';
// Services.
import {CotizadorService} from '../cotizador/cotizador.service';
import {ErrorService} from '../error/error.service';
import {SessionService} from '../session/session.service';
import {ModalsService} from '../modals/modals.service';
// Shared.
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {SimpleLogger} from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'CotizadorService';
_LOGGER.debug(_TAG, 'loaded.');

const PAYMENT_METHOD_CASH = 'EFECTIVO';
const PAYMENT_METHOD_CREDIT_CARD = 'TARJETA_CREDITO';
const PAYMENT_METHOD_AUTOMATIC_DEBIT = 'DEBITO_AUTOMATICO';


/**
 * Compra Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CompraService {
  private baseURL = `${_CONFIG.apiBaseURL}/compra`;

  constructor(
    private router: Router,
    private http: HttpClient,
    private errorService: ErrorService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private viewportScroller: ViewportScroller,
    private cotizadorService: CotizadorService
  ) {
  }

  /**
   * Do Compra Comprar. Inits buy operation.
   */
  public doComprar(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doComprar';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const moreVehicleData = this.sessionService.getBuyInsuranceStep3MoreVehicleData();
    const addressData = this.sessionService.getBuyInsuranceStep2AddressData();
    const moreAboutYouData = this.sessionService.getBuyInsuranceStep1MoreAboutYou();
    const aboutYouData = this.sessionService.getQuoteInsuranceStep2AboutYou();

    if (!moreVehicleData || !addressData || !moreAboutYouData || !aboutYouData) {
      return of(this.errorService.getAppError(new Error('Datos faltantes en Sessión de usuario.')));
    }

    const endpointURL = `${this.baseURL}/comprar`;
    const payload = {
      auto: {
        chasis: moreVehicleData.vehicleVin,
        lugar: {
          codigoPostal: addressData.vehicleAddressSameAsPerson ? addressData.personPostalCode.code : addressData.vehiclePostalCode.code,
          direccion: addressData.vehicleAddressSameAsPerson ? addressData.personAddress : addressData.vehicleAddress,
          dpto: addressData.vehicleAddressSameAsPerson ? addressData.personAddressFlat : addressData.vehicleAddressFlat,
          localidad: addressData.vehicleAddressSameAsPerson ? addressData.personLocation.name : addressData.vehicleLocation.name,
          localidadCode: addressData.vehicleAddressSameAsPerson ? addressData.personLocation.code : addressData.vehicleLocation.code,
          nro: addressData.vehicleAddressSameAsPerson ? addressData.personAddressNumber : addressData.vehicleAddressNumber,
          piso: addressData.vehicleAddressSameAsPerson ? addressData.personAddressFloor : addressData.vehicleAddressFloor,
          provincia: addressData.vehicleAddressSameAsPerson ? addressData.personProvince.name : addressData.vehicleProvince.name,
          provinciaCode: addressData.vehicleAddressSameAsPerson ? addressData.personPostalCode.code : addressData.vehicleProvince.code
        },
        motor: moreVehicleData.vehicleEngine,
        patente: moreVehicleData.vehiclePlate
      },
      persona: {
        actividadCode: moreAboutYouData.personActivity.code,
        apellido: aboutYouData.personTypeIsIndividual ? moreAboutYouData.personSurname : null,
        confirmCelular: aboutYouData.personCellphone,
        confirmEmail: aboutYouData.personEmail,
        cuit: aboutYouData.personTypeIsIndividual ? moreAboutYouData.personCuit : aboutYouData.personDocument,
        estadoCivilCode: aboutYouData.personTypeIsIndividual ? moreAboutYouData.personMaritalStatus.code : null,
        lugar: {
          codigoPostal: addressData.personPostalCode.code,
          direccion: addressData.personAddress,
          dpto: addressData.personAddressFlat,
          localidad: addressData.personLocation.name,
          localidadCode: addressData.personLocation.code,
          nro: addressData.personAddressNumber,
          piso: addressData.personAddressFloor,
          provincia: addressData.personProvince.name,
          provinciaCode: addressData.personProvince.code
        },
        nacionalidadCode: aboutYouData.personTypeIsIndividual ? moreAboutYouData.personCitizenship.code : null,
        nombre: aboutYouData.personTypeIsIndividual ? moreAboutYouData.personName : null,
        razonSocial: !aboutYouData.personTypeIsIndividual ? moreAboutYouData.personBusinessName : null,
        tipoSociedadCode: aboutYouData.personTypeIsIndividual ? null :
          (moreAboutYouData.personSocietyKind && moreAboutYouData.personSocietyKind.code)
      },
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCompraComprarResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoCompraComprarResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Return success flag.
        map((response: HttpResponse<IDoCompraComprarResponse>) => response.ok),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  /**
   * Do Compra Pagar. Ends buy operation.
   */
  private doPagar(paymetMethod: string, paymentData: any): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doPagar';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.', '; paymetMethod:', paymetMethod);
    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }
    let vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
    let today = new Date();
    today.setHours(0,0,0,0);
    if (vehicleData.insuranceValidityDateSince < today) {
      this.modalsService
      .showAlertModal('La fecha de inicio de vigencia del seguro no puede ser menor a la fecha actual.')
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.viewportScroller.scrollToAnchor('app-main-header');
          });
      });
      this.router.navigate(['/insurance/vehicle-data']);
    }
    const endpointURL = `${this.baseURL}/pagar`;
    const payload = {
      debitoAutomatico: (paymetMethod === PAYMENT_METHOD_AUTOMATIC_DEBIT) ? paymentData : null,
      medioPago: paymetMethod,
      tarjetaCredito: (paymetMethod === PAYMENT_METHOD_CREDIT_CARD) ? paymentData : null,
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCompraPagarResponse>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IDoCompraPagarResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Return success flag.
      map((response: HttpResponse<IDoCompraPagarResponse>) => response.ok),

      tap(success => {
        if (success) {
          this.sessionService.setSelectedPaymentMethod(paymetMethod);
        }
      }),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Do Compra Pagar with Credit Card payment method.
   */
  public doPagarWithCreditCard(cardCode: number, cardNumber: number, cardOwner: string,
                               cardDate: Date): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doPagarWithCreditCard';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    return this.doPagar(PAYMENT_METHOD_CREDIT_CARD, {
      codigoSeguridad: cardCode || 0,
      nro: cardNumber || 0,
      titular: cardOwner,
      vencimiento: !!cardDate ? moment(cardDate).format('MM/YYYY') : null
    });
  }

  /**
   * Do Compra Pagar with Automatic Debit payment method.
   */
  public doPagarWithAutomaticDebit(bankCode: string, cbu: string, accountNumber: string,
                                   accountTypeCode: string): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doPagarWithAutomaticDebit';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');
    return this.doPagar(PAYMENT_METHOD_AUTOMATIC_DEBIT, {
      bancoCode: bankCode,
      cbu,
      nroCuenta: accountNumber,
      tipoCuenta: accountTypeCode
    });
  }

  /**
   * Do Compra Pagar with Cash payment method.
   */
  public doPagarWithCash(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doPagarWithCash';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    return this.doPagar(PAYMENT_METHOD_CASH, {});
  }

  /**
   * Do Compra Send Email method
   */
  public doComprarSendEmail(documents: string[]): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doComprarSendEmail';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const endpointURL = `${this.baseURL}/mail`;
    const payload = {
      tipoDocumento: documents,
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };

    if (documents.length === 0) {
      return of(this.errorService.getAppError(new Error('Debe seleccionar al menos una opción.')));
    }

    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);
    return this.http.post<IDoCompraMailResponse>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IDoCompraMailResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Return success flag.
      map((response: HttpResponse<IDoCompraMailResponse>) => response.ok),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Returns the Compra Policy Download URL.
   */
  public getPolicyDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }
    return `${this.baseURL}/poliza/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Returns the Compra Solicitud Download URL.
   */
  public getRequestDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }
    return `${this.baseURL}/solicitud/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Returns the Certificado Mercosur Download URL.
   */
  public getCertificateDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }
    return `${this.baseURL}/certificadoMercosur/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Returns the Tarjeta de Circulación Download URL.
   */
  public getCardDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }
    return `${this.baseURL}/tarjetaCirculacion/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Returns the Cupón de Pago Download URL.
   */
  public getCouponDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }
    return `${this.baseURL}/cuponPago/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Gets Client data from backend (FU DB).
   */
  public doObtainClientData(): Observable<ClientDataModel | BaseAppError> {
    const __SUBTAG = 'doObtainClientData';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/user/clientdata`;
    const payload = {
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCompraObtainClientDataResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoCompraObtainClientDataResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IDoCompraObtainClientDataResponse>) => new ClientDataModel(response.body.name,
          response.body.activityCode, response.body.lastName, response.body.cuit, response.body.citizenshipCode,
          response.body.maritalStatusCode, response.body.societyTypeCode, response.body.razonSocial)),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

}
