export class VehicleReferencePriceModel {
    public message: string;
    public referencePriceInfoAuto: number;
    public responseCode: number;

    constructor(message: string, referencePriceInfoAuto: number, responseCode: number) {
        this.message = message;
        this.referencePriceInfoAuto = referencePriceInfoAuto;
        this.responseCode = responseCode;
    }
}