// Modules.
import { BackOfficeRoutingModule } from './backoffice-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MainModule } from '../main/main.module';
import { NgModule } from '@angular/core';
// Components.
import { PoliciesIssuedPageComponent } from './pages/policies-issued-page/policies-issued-page.component';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'BackOfficeModule';
_LOGGER.debug(_TAG, 'loaded.');


@NgModule({
  declarations: [
    PoliciesIssuedPageComponent
  ],
  imports: [
    // Core.
    CommonModule,
    FormsModule,
    // Custom.
    MainModule,
    BackOfficeRoutingModule
  ]
})
export class BackOfficeModule {
}
