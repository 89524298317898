export class VehicleVersionModel {
  public id: number;
  public idInfoAuto: string;
  public name: string;

  constructor(id: number, idInfoAuto: string, name: string) {
    this.id = id;
    this.idInfoAuto = idInfoAuto;
    this.name = name;
  }
}
