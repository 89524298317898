// Core.
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
// Models & Interfaces.
import { BaseAppError } from '../../../../services/error/base-app-error';
import { IQuoteInsuranceStep2AboutYou } from '../../../../services/session/i-quote-insurance-step2-about-you';
import { LocationModel } from '../../../../models/location.model';
import { PostalCodeModel } from '../../../../models/postal-code.model';
import { ProvinceModel } from '../../../../models/province.model';
// Services.
import { CotizadorService } from '../../../../services/cotizador/cotizador.service';
import { ErrorService } from '../../../../services/error/error.service';
import { GeoService } from '../../../../services/geo/geo.service';
import { ModalsService } from '../../../../services/modals/modals.service';
import { SessionService } from '../../../../services/session/session.service';
// Shared.
import { Helper } from '../../../../shared/helper.shared';
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'AddressDataPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Address Data page. "Cotizar > Solicitar Seguro > Paso #2-2: Domicilio de guarda del vehículo".
 */
@Component({
  selector: 'app-address-data-page',
  templateUrl: './address-data-page.component.html',
  styleUrls: ['./address-data-page.component.scss']
})
export class AddressDataPageComponent implements OnInit {
  protected personData: IQuoteInsuranceStep2AboutYou;

  /* Form controls. */
  @ViewChild('addressDataForm') protected addressDataForm: NgForm;
  @ViewChild('inputPersonAddressNumber') protected inputPersonAddressNumber: ElementRef;
  @ViewChild('inputPersonAddressFloor') protected inputPersonAddressFloor: ElementRef;
  @ViewChild('inputPersonAddressFlat') protected inputPersonAddressFlat: ElementRef;
  @ViewChild('autocompleteVehicleLocation') protected autocompleteVehicleLocation: ElementRef;
  @ViewChild('inputVehicleAddress') protected inputVehicleAddress: ElementRef;
  @ViewChild('inputVehicleAddressNumber') protected inputVehicleAddressNumber: ElementRef;
  @ViewChild('inputVehicleAddressFloor') protected inputVehicleAddressFloor: ElementRef;
  @ViewChild('inputVehicleAddressFlat') protected inputVehicleAddressFlat: ElementRef;
  protected disableForm: boolean;
  protected provinces: ProvinceModel[];
  protected provincesToSearch: ProvinceModel[] = [];
  protected personProvince: ProvinceModel = null;
  protected vehicleProvinceSearchTerm: string;
  protected personLocation: LocationModel = null;
  protected locationsToSearch: LocationModel[] = [];
  protected vehicleLocationSearchTerm: string;
  protected personAddress: string;
  protected personAddressNumber: string;
  protected personAddressFloor: string;
  protected personAddressFlat: string;
  protected personPostalCode: PostalCodeModel = null;
  protected vehicleAddressSameAsPerson: boolean;
  protected vehicleProvince: ProvinceModel = null;
  protected vehicleLocation: LocationModel = null;
  protected vehicleAddress: string;
  protected vehicleAddressNumber: string;
  protected vehicleAddressFloor: string;
  protected vehicleAddressFlat: string;
  protected vehiclePostalCode: PostalCodeModel = null;
  protected loadingPersonProvinces: boolean;
  protected loadingPersonLocations: boolean;
  protected loadingPersonPostalCodes: boolean;
  protected loadingVehicleProvinces: boolean;
  protected loadingVehicleLocations: boolean;
  protected loadingVehiclePostalCodes: boolean;

  private lastSelectedVehicleProvince: ProvinceModel = null;
  private lastSelectedVehicleLocation: LocationModel = null;

  private changedPersonAddress: boolean;
  private changedPersonAddressNumber: boolean;
  private changedPersonAddressFloor: boolean;
  private changedVehicleAddress: boolean;
  private changedVehicleAddressNumber: boolean;
  private changedVehicleAddressFloor: boolean;

  constructor(
    private router: Router,
    private elem: ElementRef,
    private errorService: ErrorService,
    private geoService: GeoService,
    private modalsService: ModalsService,
    private sessionService: SessionService,
    private cotizadorService: CotizadorService
  ) {
  }

  /**
   * Returns TRUE when there are any form missing data.
   */
  protected get formIsMissingData(): boolean {
    return (!this.personProvince || !this.personLocation || !this.personAddress || !this.personAddressNumber || !this.personPostalCode ||
      (!this.vehicleAddressSameAsPerson && (!this.vehicleProvince || !this.vehicleLocation || !this.vehicleAddress ||
        !this.vehicleAddressNumber || !this.vehiclePostalCode)));
  }

  /**
   * Gets the Locations for a given Province.
   *
   * @param province  The Province to search for Locations.
   */
  private getProvinceLocations(province: ProvinceModel): Promise<boolean> {
    const __SUBTAG = 'getProvinceLocations';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.', '; Prov:', province);

    if (province) {
      return new Promise<boolean>((resolve, reject) => {
        this.geoService.getLocationsByProvince(province)
          .subscribe((resultOrError: LocationModel[] | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getLocationsByProvince resulted in error.');
              reject(resultOrError);
              return;
            }

            // Succeed.
            if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Locations.');
              reject(new Error('Ha ocurrido un error durante la obtención de localidades.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Locations retrieved.');
              // province.locations = resultOrError is done inside GeoService.getLocationsByProvince().
              resolve(true);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getLocationsByProvince.');
            reject(error);
          });
      });
    } else {
      return Promise.reject(new Error('No se obtuvo la provincia.'));
    }
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;
    Promise
      .all([
        // Get Provinces.
        new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
          try {
            this.loadingPersonProvinces = true;
            this.loadingVehicleProvinces = true;
            this.geoService.getProvinces()
              .subscribe((resultOrError: ProvinceModel[] | BaseAppError) => {
                // Failed.
                if (resultOrError instanceof BaseAppError) {
                  _LOGGER.error(_TAG, __SUBTAG, 'getProvinces resulted in error.');
                  reject(resultOrError);
                  return;
                }

                // Succeed.
                if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
                  _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Provinces.');
                  reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
                } else {
                  _LOGGER.debug(_TAG, __SUBTAG, 'Provinces initialized.');
                  this.provinces = resultOrError;
                  resolve(this.provinces);
                }
              }, (error: any) => {
                _LOGGER.error(_TAG, __SUBTAG, 'Error on getProvinces.');
                reject(error);
              });
          } catch (e) {
            _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Provinces.', e);
            reject(e);
          } finally {
            this.loadingPersonProvinces = false;
            this.loadingVehicleProvinces = false;
          }
        })
      ])
      .then(() => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
        this.formReset();
      })
      .catch(error => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
        this.onError(error);
      });
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.addressDataForm && this.addressDataForm.pristine) {
      this.addressDataForm.control.reset();
    }

    const prevData = this.sessionService.getBuyInsuranceStep2AddressData();
    const helper = Helper.getInstance();

    // Copy previous selected Vehicle Address Data to Person's Address Data. Then finish resetting the form.
    _LOGGER.debug(_TAG, __SUBTAG, 'Search for previous selected Prov.:', this.personData.vehicleProvince.code);
    this.personProvince = this.provinces.find(prov => prov.code === this.personData.vehicleProvince.code);
    if (!this.personProvince) {
      this.onError(new Error('No se pudo recuperar la provincia seleccionada anteriormente.'));
      return;
    }
    this.onChangePersonProvince(true);
    this.getProvinceLocations(this.personProvince)
      .then((success: boolean) => {
        if (success) {
          _LOGGER.debug(_TAG, __SUBTAG, 'Search for previous selected Loc.:', this.personData.vehicleLocation.code);
          this.personLocation = this.personProvince.locations
            .find(location => location.code === this.personData.vehicleLocation.code);
          if (!this.personLocation) {
            this.onError(new Error('No se pudo recuperar la localidad seleccionada anteriormente.'));
            return;
          }
          this.onChangePersonLocation(true);
          _LOGGER.debug(_TAG, __SUBTAG, 'Search for previous selected C.P.:', this.personData.vehiclePostalCode.code);
          this.personPostalCode = this.personLocation.postalCodes
            .find(postalCode => postalCode.code === this.personData.vehiclePostalCode.code);
          if (!this.personPostalCode) {
            this.onError(new Error('No se pudo recuperar el código postal seleccionado anteriormente.'));
            return;
          }
          this.onChangePersonPostalCode();

          // Finish form reset.
          if (prevData && prevData.personAddress) {
            this.personAddress = prevData.personAddress;
          } else {
            this.personAddress = null;
          }
          this.onChangePersonAddress();
          this.changedPersonAddress = false;
          if (prevData && prevData.personAddressNumber) {
            this.personAddressNumber = prevData.personAddressNumber;
          } else {
            this.personAddressNumber = null;
          }
          this.onChangePersonAddressNumber();
          this.changedPersonAddressNumber = false;
          if (prevData && prevData.personAddressFloor) {
            this.personAddressFloor = prevData.personAddressFloor;
          } else {
            this.personAddressFloor = null;
          }
          this.onChangePersonAddressFloor();
          this.changedPersonAddressFloor = false;
          if (prevData && prevData.personAddressFlat) {
            this.personAddressFlat = prevData.personAddressFlat;
          } else {
            this.personAddressFlat = null;
          }
          this.onChangePersonAddressFlat();

          if (prevData) {
            this.vehicleAddressSameAsPerson = !!prevData.vehicleAddressSameAsPerson;
          } else {
            this.vehicleAddressSameAsPerson = true;
          }
          this.onChangeVehicleAddressSameAsPerson();

          this.vehicleProvince = null;
          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleProvince) {
            this.vehicleProvince = this.provinces.find(vp => helper.equalsByProps(vp, prevData.vehicleProvince, ['code', 'name']));
            this.vehicleProvinceSearchTerm = prevData.vehicleProvince.name;
            this.lastSelectedVehicleProvince = prevData.vehicleProvince;
          } else {
            this.vehicleProvince = null;
          }
          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleLocation && prevData.vehiclePostalCode) {
            this.vehicleLocationSearchTerm = prevData.vehicleLocation.name;
            this.lastSelectedVehicleLocation = prevData.vehicleLocation;
            this.onChangeVehicleProvince(prevData.vehicleLocation, prevData.vehiclePostalCode);
          } else {
            this.onChangeVehicleProvince();
          }

          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleAddress) {
            this.vehicleAddress = prevData.vehicleAddress;
          } else {
            this.vehicleAddress = null;
          }
          this.onChangeVehicleAddress();
          this.changedVehicleAddress = false;
          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleAddressNumber) {
            this.vehicleAddressNumber = prevData.vehicleAddressNumber;
          } else {
            this.vehicleAddressNumber = null;
          }
          this.onChangeVehicleAddressNumber();
          this.changedVehicleAddressNumber = false;
          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleAddressFloor) {
            this.vehicleAddressFloor = prevData.vehicleAddressFloor;
          } else {
            this.vehicleAddressFloor = null;
          }
          this.onChangeVehicleAddressFloor();
          this.changedVehicleAddressFloor = false;
          if (!this.vehicleAddressSameAsPerson && prevData && prevData.vehicleAddressFlat) {
            this.vehicleAddressFlat = prevData.vehicleAddressFlat;
          } else {
            this.vehicleAddressFlat = null;
          }
          this.onChangeVehicleAddressFlat();

          this.disableForm = false;
        } else {
          this.onError(new Error('No se pudo recuperar la provincia/localidad seleccionada anteriormente.'));
        }
      })
      .catch((error: any) => this.onError(error));
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks steps #2 data.
   * Initializes form.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      this.personData = this.sessionService.getQuoteInsuranceStep2AboutYou();
      if (this.personData && this.sessionService.getBuyInsuranceStep1MoreAboutYou()) {
        this.formInit();
        return;
      }
    }
    this.onStepBack();
  }

  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Navs back on errors.
   * Resets session & Cotizador data on 401 errors.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService
      .showErrorModal(appError.getMessage())
      .then(modal => {
        modal.result
          .then(result => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
          })
          .catch(reason => {
            _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
          })
          .finally(() => {
            this.onStepBack();
          });
      });
  }

  /**
   * Navs to More Vehicle Data page.
   */
  protected onContinue(): void {
    this.sessionService.setBuyInsuranceStep2AddressData({
      personProvince: this.personProvince,
      personLocation: this.personLocation,
      personAddress: this.personAddress,
      personAddressNumber: this.personAddressNumber,
      personAddressFloor: this.personAddressFloor,
      personAddressFlat: this.personAddressFlat,
      personPostalCode: this.personPostalCode,
      vehicleAddressSameAsPerson: this.vehicleAddressSameAsPerson,
      vehicleProvince: !this.vehicleAddressSameAsPerson ? this.vehicleProvince : null,
      vehicleLocation: !this.vehicleAddressSameAsPerson ? this.vehicleLocation : null,
      vehicleAddress: !this.vehicleAddressSameAsPerson ? this.vehicleAddress : null,
      vehicleAddressNumber: !this.vehicleAddressSameAsPerson ? this.vehicleAddressNumber : null,
      vehicleAddressFloor: !this.vehicleAddressSameAsPerson ? this.vehicleAddressFloor : null,
      vehicleAddressFlat: !this.vehicleAddressSameAsPerson ? this.vehicleAddressFlat : null,
      vehiclePostalCode: !this.vehicleAddressSameAsPerson ? this.vehiclePostalCode : null
    });
    this.router.navigate(['/insurance/more-vehicle-data']);
  }

  /**
   * Navs back to More About You page.
   */
  protected onStepBack(): void {
    this.router.navigate(['/insurance/more-about-you']);
  }

  /**
   * User changes Person Province data handler.
   * Gets province's Locations.
   */
  protected onChangePersonProvince(suppressCascade: boolean = false): void {
    const __SUBTAG = 'onChangePersonProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.', '; Suppress Cascade:', suppressCascade);

    if (!suppressCascade) {
      this.personLocation = null;

      if (this.personProvince) {
        this.loadingPersonLocations = true;
        this.getProvinceLocations(this.personProvince)
          .then((success: boolean) => {
            if (success) {
              this.onChangePersonLocation();
            } else {
              this.onError(new Error('No se pudo obtener las localidades para la provincia seleccionada.'));
            }
          })
          .catch((error: any) => this.onError(error))
          .finally(() => {
            this.loadingPersonLocations = false;
          });
      } else {
        this.onChangePersonLocation();
      }
    }
  }

  /**
   * User changes Person Location data handler.
   * Gets location's Postal Codes.
   */
  protected onChangePersonLocation(suppressCascade: boolean = false): void {
    const __SUBTAG = 'onChangePersonLocation';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.', '; Suppress Cascade:', suppressCascade);

    if (!suppressCascade) {
      this.personPostalCode = null;

      if (this.personLocation) {
        this.loadingPersonPostalCodes = true;
        if (!this.personLocation.postalCodes.length || (this.personLocation.postalCodes.length < 1)) {
          _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Postal Codes.');
          this.onError(new Error('Ha ocurrido un error durante la obtención de códigos postales.'));
          return;
        } else {
          _LOGGER.debug(_TAG, __SUBTAG, 'Postal Codes are OK.');
          if (this.personLocation.postalCodes.length === 1) {
            this.personPostalCode = this.personLocation.postalCodes[0];
          }
        }
        this.loadingPersonPostalCodes = false;
      }
      this.onChangePersonPostalCode();
    }
  }

  /**
   * User changes Person Postal Code data handler.
   */
  protected onChangePersonPostalCode(): void {
    // No action needed.
  }

  /**
   * User changes Person Address data handler.
   */
  protected onChangePersonAddress(): void {
    this.changedPersonAddress = true;
    if (this.personAddress) {
      this.personAddress = this.personAddress.toUpperCase();
    }
  }

  /**
   * Person Address blur handler.
   */
  protected onBlurPersonAddress(): void {
    if (this.personAddress && this.changedPersonAddress) {
      this.changedPersonAddress = false;
      setTimeout(() => {
        if (this.inputPersonAddressNumber && this.inputPersonAddressNumber.nativeElement &&
          this.inputPersonAddressNumber.nativeElement.focus) {
          this.inputPersonAddressNumber.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Address input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonAddress(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonAddress();
    }
  }

  /**
   * User changes Person Address Number data handler.
   */
  protected onChangePersonAddressNumber(): void {
    this.changedPersonAddressNumber = true;
  }

  /**
   * Person Address Number blur handler.
   */
  protected onBlurPersonAddressNumber(): void {
    if (this.personAddressNumber && this.changedPersonAddressNumber) {
      this.changedPersonAddressNumber = false;
      setTimeout(() => {
        if (this.inputPersonAddressFloor && this.inputPersonAddressFloor.nativeElement &&
          this.inputPersonAddressFloor.nativeElement.focus) {
          this.inputPersonAddressFloor.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Address Number input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonAddressNumber(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonAddressNumber();
    }
  }

  /**
   * User changes Person Address Floor data handler.
   */
  protected onChangePersonAddressFloor(): void {
    this.changedPersonAddressFloor = true;
  }

  /**
   * Person Address Floor blur handler.
   */
  protected onBlurPersonAddressFloor(): void {
    if (this.personAddressFloor && this.changedPersonAddressFloor) {
      this.changedPersonAddressFloor = false;
      setTimeout(() => {
        if (this.inputPersonAddressFlat && this.inputPersonAddressFlat.nativeElement &&
          this.inputPersonAddressFlat.nativeElement.focus) {
          this.inputPersonAddressFlat.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Person Address Floor input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonAddressFloor(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurPersonAddressFloor();
    }
  }

  /**
   * User changes Person Address Flat data handler.
   */
  protected onChangePersonAddressFlat(): void {
    // No action needed.
  }

  /**
   * User changes Vehicle Address Same As Person Address data handler.
   */
  protected onChangeVehicleAddressSameAsPerson(): void {
    // No action needed.
  }

  /**
   * User selects Vehicle Province from Autocomplete component.
   *
   * @param item  Item selected.
   */
  protected onSelectAutocompleteVehicleProvince(item: any): void {
    if (!this.lastSelectedVehicleProvince || (this.lastSelectedVehicleProvince.code !== item.code)) {
      this.lastSelectedVehicleProvince = item as ProvinceModel;
      this.vehicleProvince = this.lastSelectedVehicleProvince;
      this.onChangeVehicleProvince(null, null, true);
      this.vehicleLocationSearchTerm = null;
      this.provincesToSearch = [];
    }
  }

  /**
   * User changes search term on Vehicle Province Autocomplete input.
   */
  protected onInputChangedEventAutocompleteVehicleProvince(): void {
    if (!this.vehicleProvinceSearchTerm || !this.vehicleProvinceSearchTerm.length || (this.vehicleProvinceSearchTerm.length < 3)) {
      this.provincesToSearch = [];
    } else {
      this.provincesToSearch = this.provinces;
    }
  }

  /**
   * This event fires on user Click on Vehicle Province Autocomplete input (due to Autocomplete's plugin logic).
   * Searches for the hidden Autocomplete input (plugin's) and attaches a "blur" event listener to it.
   */
  protected onBlurAutocompleteVehicleProvince(): void {
    setTimeout(() => {
      const elements: NodeList = this.elem.nativeElement.querySelectorAll('.autocomplete.inputVehicleProvince > input');
      if (elements.length === 1) {
        const inputEl = elements.item(0) as HTMLInputElement;
        inputEl.addEventListener('blur', () => {
          if (this.vehicleProvince) {
            this.vehicleProvinceSearchTerm = this.vehicleProvince.name;
          }
        });
        inputEl.select();
      }
    }, 10);
  }

  /**
   * User changes Vehicle Province data handler.
   * Gets province's Locations.
   */
  protected onChangeVehicleProvince(selectVehicleLocation: LocationModel = null, selectVehiclePostalCode: PostalCodeModel = null,
                                    selectAutocompleteLocationInput: boolean = false): void {
    const __SUBTAG = 'onChangeVehicleProvince';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    this.vehicleLocation = null;
    this.lastSelectedVehicleLocation = null;

    if (this.vehicleProvince) {
      this.loadingVehicleLocations = true;
      this.getProvinceLocations(this.vehicleProvince)
        .then((success: boolean) => {
          if (success) {
            if (this.vehicleProvince.locations && this.vehicleProvince.locations.length) {
              if (selectVehicleLocation) {
                this.vehicleLocation = this.vehicleProvince.locations
                  .find(vl => Helper.getInstance().equalsByProps(vl, selectVehicleLocation, ['code', 'name']));
              }
              if (!this.vehicleLocation && (this.vehicleProvince.locations.length === 1)) {
                this.vehicleLocation = this.vehicleProvince.locations[0];
              }
              this.lastSelectedVehicleLocation = this.vehicleLocation;
            }
          } else {
            this.onError(new Error('No se pudo obtener las localidades para la provincia seleccionada.'));
          }
        })
        .catch((error: any) => this.onError(error))
        .finally(() => {
          this.loadingVehicleLocations = false;
          this.onChangeVehicleLocation(selectVehiclePostalCode);
          // Autoselect next input.
          if (selectAutocompleteLocationInput) {
            if (this.autocompleteVehicleLocation) {
              setTimeout(() => {
                this.autocompleteVehicleLocation.nativeElement.click();
                this.onBlurAutocompleteVehicleLocation();
              }, 10);
            }
          }
        });
    } else {
      this.onChangeVehicleLocation();
    }
  }

  /**
   * User selects Vehicle Location from Autocomplete component.
   *
   * @param item  Location selected.
   */
  protected onSelectAutocompleteVehicleLocation(item: any): void {
    if (!this.lastSelectedVehicleLocation || (this.lastSelectedVehicleLocation.code !== item.code)) {
      this.lastSelectedVehicleLocation = item as LocationModel;
      this.vehicleLocation = this.lastSelectedVehicleLocation;
      this.onChangeVehicleLocation();
      this.locationsToSearch = [];

      // Autoselect next input.
      setTimeout(() => {
        if (this.inputVehicleAddress && this.inputVehicleAddress.nativeElement &&
          this.inputVehicleAddress.nativeElement.focus) {
          this.inputVehicleAddress.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * User changes search term on Vehicle Location Autocomplete input.
   */
  protected onInputChangedEventAutocompleteVehicleLocation(): void {
    if (!this.vehicleLocationSearchTerm || !this.vehicleLocationSearchTerm.length || (this.vehicleLocationSearchTerm.length < 3)) {
      this.locationsToSearch = [];
    } else {
      this.locationsToSearch = this.vehicleProvince.locations;
    }
  }

  /**
   * This event fires on user Click on Vehicle Location Autocomplete input (due to Autocomplete's plugin logic).
   * Searches for the hidden Autocomplete input (plugin's) and attaches a "blur" event listener to it.
   */
  protected onBlurAutocompleteVehicleLocation(): void {
    setTimeout(() => {
      const elements: NodeList = this.elem.nativeElement.querySelectorAll('.autocomplete.inputVehicleLocation > input');
      if (elements.length === 1) {
        const inputEl = elements.item(0) as HTMLInputElement;
        inputEl.addEventListener('blur', () => {
          if (this.vehicleLocation) {
            this.vehicleLocationSearchTerm = this.vehicleLocation.name;
          }
        });
        inputEl.select();
      }
    }, 10);
  }

  /**
   * User changes Vehicle Location data handler.
   * Gets location's Postal Codes.
   */
  protected onChangeVehicleLocation(selectVehiclePostalCode: PostalCodeModel = null): void {
    const __SUBTAG = 'onChangeVehicleLocation';
    _LOGGER.debug(_TAG, __SUBTAG, 'started.');

    this.vehiclePostalCode = null;

    if (this.vehicleLocation) {
      this.loadingVehiclePostalCodes = true;
      if (!this.vehicleLocation.postalCodes.length || (this.vehicleLocation.postalCodes.length < 1)) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Postal Codes.');
        this.onError(new Error('Ha ocurrido un error durante la obtención de códigos postales.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'Postal Codes are OK.');
        if (selectVehiclePostalCode) {
          this.vehiclePostalCode = this.vehicleLocation.postalCodes
            .find(vpc => Helper.getInstance().equalsByProps(vpc, selectVehiclePostalCode, ['code', 'name']));
        }
        if (!this.vehiclePostalCode && (this.vehicleLocation.postalCodes.length === 1)) {
          this.vehiclePostalCode = this.vehicleLocation.postalCodes[0];
        }
      }
      this.loadingVehiclePostalCodes = false;
    }

    this.onChangeVehiclePostalCode();
  }

  /**
   * User changes Vehicle Postal Code data handler.
   */
  protected onChangeVehiclePostalCode(): void {
    // No action needed.
  }

  /**
   * User changes Vehicle Address data handler.
   */
  protected onChangeVehicleAddress(): void {
    this.changedVehicleAddress = true;
    if (this.vehicleAddress) {
      this.vehicleAddress = this.vehicleAddress.toUpperCase();
    }
  }

  /**
   * Vehicle Address blur handler.
   */
  protected onBlurVehicleAddress(): void {
    if (this.vehicleAddress && this.changedVehicleAddress) {
      this.changedVehicleAddress = false;
      setTimeout(() => {
        if (this.inputVehicleAddressNumber && this.inputVehicleAddressNumber.nativeElement &&
          this.inputVehicleAddressNumber.nativeElement.focus) {
          this.inputVehicleAddressNumber.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Vehicle Address input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonVehicleAddress(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurVehicleAddress();
    }
  }

  /**
   * User changes Vehicle Address Number data handler.
   */
  protected onChangeVehicleAddressNumber(): void {
    this.changedVehicleAddressNumber = true;
  }

  /**
   * Vehicle Address Number blur handler.
   */
  protected onBlurVehicleAddressNumber(): void {
    if (this.vehicleAddressNumber && this.changedVehicleAddressNumber) {
      this.changedVehicleAddressNumber = false;
      setTimeout(() => {
        if (this.inputVehicleAddressFloor && this.inputVehicleAddressFloor.nativeElement &&
          this.inputVehicleAddressFloor.nativeElement.focus) {
          this.inputVehicleAddressFloor.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Vehicle Address Number input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonVehicleAddressNumber(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurVehicleAddressNumber();
    }
  }

  /**
   * User changes Vehicle Address Floor data handler.
   */
  protected onChangeVehicleAddressFloor(): void {
    this.changedVehicleAddressFloor = true;
  }

  /**
   * Vehicle Address Floor blur handler.
   */
  protected onBlurVehicleAddressFloor(): void {
    if (this.vehicleAddressFloor && this.changedVehicleAddressFloor) {
      this.changedVehicleAddressFloor = false;
      setTimeout(() => {
        if (this.inputVehicleAddressFlat && this.inputVehicleAddressFlat.nativeElement &&
          this.inputVehicleAddressFlat.nativeElement.focus) {
          this.inputVehicleAddressFlat.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Vehicle Address Number input keydown handler.
   * Triggers Blur on same input.
   */
  protected onKeyDownPersonVehicleAddressFloor(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurVehicleAddressFloor();
    }
  }

  /**
   * User changes Vehicle Address Flat data handler.
   */
  protected onChangeVehicleAddressFlat(): void {
    // No action needed.
  }
}
