import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerUtilsService} from './date-picker-utils.service';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class DatePickerToDateAdapter extends NgbDateAdapter<Date> {
  constructor(
    private datePickerUtilsService: DatePickerUtilsService) {
    super();
  }

  public fromModel(value: Date | null): NgbDateStruct | null {
    return this.datePickerUtilsService.dateToNgbDateStruct(value);
  }

  public toModel(date: NgbDateStruct | null): Date | null {
    return this.datePickerUtilsService.ngbDateStructToDate(date);
  }
}
