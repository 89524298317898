// Core.
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Models & Interfaces.
import { BaseAppError } from '../../../../services/error/base-app-error';
// Services.
import { ErrorService } from '../../../../services/error/error.service';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ErrorPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Error page.
 */
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  protected errorMessage: string;
  protected showCotizarButton = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService
  ) {
  }

  private showError(error: any): void {
    const __SUBTAG = 'showError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());
    this.errorMessage = appError.getMessage();

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.showCotizarButton = false;
    }
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   */
  public ngOnInit(): void {
    // Parse & show errors.
    this.route.queryParamMap.subscribe(params => {
      try {
        const stringError: string = params.get('e');
        if (!!stringError) {
          const jsonError: string = atob(decodeURIComponent(stringError));
          if (!!jsonError) {
            const parsedError: any = JSON.parse(jsonError);
            if (!!parsedError) {
              this.showError(this.errorService.getAppError(parsedError));
            } else {
              this.showError(this.errorService.getAppError('No se pudo analizar el error.'));
            }
          } else {
            this.showError(this.errorService.getAppError('No se pudo determinar el error.'));
          }
        } else {
          this.showError(this.errorService.getAppError('Error inesperado.'));
        }
      } catch (e) {
        this.showError(this.errorService.getAppError(e));
      }
    });
  }

  /**
   * Navs to Cotizar Seguro - Step #1-1 (Vehicle Data).
   */
  protected onCotizar(): void {
    this.router.navigate(['/insurance/vehicle-data']);
  }
}
