// Core.
import { Injectable } from '@angular/core';
// Models & Interfaces.
import { BrokerModel } from '../../models/broker.model';
import { IBuyInsuranceStep1MoreAboutYou } from './i-buy-insurance-step1-more-about-you';
import { IBuyInsuranceStep2AddressData } from './i-buy-insurance-step2-address-data';
import { IBuyInsuranceStep3MoreVehicleData } from './i-buy-insurance-step3-more-vehicle-data';
import { IQuoteInsuranceStep1VehicleData } from './i-quote-insurance-step1-vehicle-data';
import { IQuoteInsuranceStep2AboutYou } from './i-quote-insurance-step2-about-you';
import { VehicleStep2DataModel } from '../../models/vehicle-step2-data.model';
// Shared.
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'SessionService';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Session Service.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private quoteInsuranceStep1VehicleData: IQuoteInsuranceStep1VehicleData;
  private quoteInsuranceStep2AboutYou: IQuoteInsuranceStep2AboutYou;
  private vehicleStep2Data: VehicleStep2DataModel;
  private selectedBroker: BrokerModel;
  private selectedPaymentMethod: string;
  private allActiveBrokers: BrokerModel[];

  private buyInsuranceStep1MoreAboutYou: IBuyInsuranceStep1MoreAboutYou;
  private buyInsuranceStep2AddressData: IBuyInsuranceStep2AddressData;
  private buyInsuranceStep3MoreVehicleData: IBuyInsuranceStep3MoreVehicleData;

  private personFraudCheckCompleted: boolean;
  private personFraudCheckResult: boolean;

  constructor() {
    this.reset();
  }

  public setQuoteInsuranceStep1VehicleData(data: IQuoteInsuranceStep1VehicleData): void {
    this.quoteInsuranceStep1VehicleData = data;
  }

  public getQuoteInsuranceStep1VehicleData(): IQuoteInsuranceStep1VehicleData {
    return this.quoteInsuranceStep1VehicleData;
  }

  public setQuoteInsuranceStep2AboutYou(data: IQuoteInsuranceStep2AboutYou): void {
    this.quoteInsuranceStep2AboutYou = data;
  }

  public getQuoteInsuranceStep2AboutYou(): IQuoteInsuranceStep2AboutYou {
    return this.quoteInsuranceStep2AboutYou;
  }

  public getVehicleStep2Data() : VehicleStep2DataModel {
    return this.vehicleStep2Data;
  }

  public setVehicleStep2Data(data: VehicleStep2DataModel) : void {
    this.vehicleStep2Data = data;
  }

  public setSelectedBroker(broker: BrokerModel): void {
    this.selectedBroker = broker;
  }

  public getSelectedBroker(): BrokerModel {
    return this.selectedBroker;
  }

  public setSelectedPaymentMethod(paymentMethod: string): void {
    this.selectedPaymentMethod = paymentMethod;
  }

  public getSelectedPaymentMethod(): string {
    return this.selectedPaymentMethod;
  }

  public setBuyInsuranceStep1MoreAboutYou(data: IBuyInsuranceStep1MoreAboutYou): void {
    this.buyInsuranceStep1MoreAboutYou = data;
  }

  public getBuyInsuranceStep1MoreAboutYou(): IBuyInsuranceStep1MoreAboutYou {
    return this.buyInsuranceStep1MoreAboutYou;
  }

  public setBuyInsuranceStep2AddressData(data: IBuyInsuranceStep2AddressData): void {
    this.buyInsuranceStep2AddressData = data;
  }

  public getBuyInsuranceStep2AddressData(): IBuyInsuranceStep2AddressData {
    return this.buyInsuranceStep2AddressData;
  }

  public setBuyInsuranceStep3MoreVehicleData(data: IBuyInsuranceStep3MoreVehicleData): void {
    this.buyInsuranceStep3MoreVehicleData = data;
  }

  public getBuyInsuranceStep3MoreVehicleData(): IBuyInsuranceStep3MoreVehicleData {
    return this.buyInsuranceStep3MoreVehicleData;
  }

  public setAllActiveBrokers(brokers: BrokerModel[]): void {
    this.allActiveBrokers = brokers;
  }

  public getAllActiveBrokers(): BrokerModel[] {
    return this.allActiveBrokers;
  }

  public setPersonFraudCheckCompleted(isCompleted: boolean): void {
    this.personFraudCheckCompleted = isCompleted;
  }

  public isPersonFraudCheckCompleted(): boolean {
    return this.personFraudCheckCompleted;
  }

  public setPersonFraudCheckResult(personFraudCheckResult: boolean): void {
    this.personFraudCheckResult = personFraudCheckResult;
  }

  public isPersonFraudListed(): boolean {
    return (this.personFraudCheckResult !== null) && this.personFraudCheckResult;
  }

  /**
   * Resets session data.
   */
  public reset(): void {
    this.setSelectedBroker(null);
    this.setQuoteInsuranceStep2AboutYou(null);
    this.setQuoteInsuranceStep1VehicleData(null);
    this.setAllActiveBrokers(null);

    this.setBuyInsuranceStep1MoreAboutYou(null);
    this.setBuyInsuranceStep2AddressData(null);
    this.setBuyInsuranceStep3MoreVehicleData(null);

    this.setPersonFraudCheckCompleted(false);
    this.setPersonFraudCheckResult(null);

    this.setSelectedPaymentMethod(null);
  }
}
