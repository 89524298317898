import { environment } from '../../environments/environment';
import { environment as environmentProd } from '../../environments/environment.prod';
import { environment as environmentTest1 } from '../../environments/environment.test1';
import { environment as environmentTest2 } from '../../environments/environment.test2';
import { environment as environmentTest3 } from '../../environments/environment.test3';
import { environment as environmentTest4 } from '../../environments/environment.test4';
import { environment as environmentTestQa } from '../../environments/environment.testqa';
import { environment as environmentQa } from '../../environments/environment.qa';
import { environment as environmentDev } from '../../environments/environment.dev';
import { LOGLEVEL } from './simple-logger.shared';


export interface IEnvironmentConfiguration {
  apiBaseURL: string;
  logLevel: LOGLEVEL;
  recaptchaKey: string;
}

export interface ISharedConfiguration {
  i18nLocaleShort: string;
  i18nLocaleFull: string;
  decimalSeparator: string;
  milesSeparator: string;
  currencySymbol: string;
  vehicleYearsAgoToConsider: number;
  birthDateYearsAgoToConsider: number;
  birthDateYearsAgoOffset: number;
  birthDateYearInitialSelection: number;
  defaultVehicleUseTypeCode: string;
  defaultVehicleGncTypeCode: string;
  defaultPersonDocumentTypeCode: string;
  defaultEnterpriseDocumentTypeCode: string;
  defaultPersonTaxConditionCode: string;
  enterpriseTaxConditionExclusiveCode: string;
  creditCardDueDateYearsToConsider: number;
  individualNationalityCode: string;
}

export interface IConfiguration extends IEnvironmentConfiguration, ISharedConfiguration {
  production: boolean;
  envName: string;
}

interface EnvironmentConfigByDomain {
  frontDomains: string[];
  envConfig: IEnvironmentConfiguration;
  envName: string;
  isProduction: boolean;
}

const _SHARED_CONFIG: ISharedConfiguration = {
  i18nLocaleShort: 'es',
  i18nLocaleFull: 'es-AR',
  decimalSeparator: ',',
  milesSeparator: '.',
  currencySymbol: '$',
  vehicleYearsAgoToConsider: 15,
  birthDateYearsAgoToConsider: 90,
  birthDateYearsAgoOffset: 18,
  birthDateYearInitialSelection: 35,
  defaultVehicleUseTypeCode: 'PARTICULAR',
  defaultVehicleGncTypeCode: 'MENOS_90L',
  defaultPersonDocumentTypeCode: 'DN',
  defaultEnterpriseDocumentTypeCode: 'CU',
  defaultPersonTaxConditionCode: 'CONSUMIDOR_FINAL',
  enterpriseTaxConditionExclusiveCode: 'RESPONSABLE_INSCRIPTO',
  creditCardDueDateYearsToConsider: 10,
  individualNationalityCode: '080'
};

const _ENVS_CONFIG: EnvironmentConfigByDomain[] = [
  // Local - Dev:
  {
    frontDomains: ['localhost:4200'],
    envConfig: environmentDev,
    envName: 'DEV',
    isProduction: false
  },
  // Codeicus - QA:
  {
    frontDomains: ['qa.www.tcfaseguros.codeicus.com.ar', 'qa.www.tcfaseguros.codeicus'],
    envConfig: environmentQa,
    envName: 'QA',
    isProduction: false
  },
  // TCFA - Testing 1:
  {
    frontDomains: ['test1www.tcfaseguros.com.ar'],
    envConfig: environmentTest1,
    envName: 'TEST1',
    isProduction: false
  },
  // TCFA - Testing 2:
  {
    frontDomains: ['test2www.tcfaseguros.com.ar'],
    envConfig: environmentTest2,
    envName: 'TEST2',
    isProduction: false
  },
  // TCFA - Testing 3:
  {
    frontDomains: ['test3www.tcfaseguros.com.ar'],
    envConfig: environmentTest3,
    envName: 'TEST3',
    isProduction: false
  },
  // TCFA - Testing 4:
  {
    frontDomains: ['test4www.tcfaseguros.com.ar'],
    envConfig: environmentTest4,
    envName: 'TEST4',
    isProduction: false
  },
  // TCFA - Testing QA:
  {
    frontDomains: ['testqawww.tcfaseguros.com.ar'],
    envConfig: environmentTestQa,
    envName: 'TESTQA',
    isProduction: false
  },
  // TCFA - Production:
  {
    frontDomains: ['www.tcfaseguros.com.ar'],
    envConfig: environmentProd,
    envName: 'PROD',
    isProduction: true
  }
];


/**
 * <Singleton>
 */
export class EnvironmentManager {
  private static instance: EnvironmentManager = new EnvironmentManager();

  private config: IConfiguration;

  private constructor() {
  }

  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }
    return EnvironmentManager.instance;
  }

  private buildConfig(): void {
    const config: any = {};

    // Get shared configuration.
    Object.keys(_SHARED_CONFIG).forEach(k => config[k] = _SHARED_CONFIG[k]);

    // Get env configuration.
    const currentDomain = location.host.toLowerCase();
    let envConfig: IEnvironmentConfiguration;
    const currentEnvConfig = _ENVS_CONFIG.find(ec => ec.frontDomains.some(d => d === currentDomain));
    if (!!currentEnvConfig) {
      config.production = currentEnvConfig.isProduction;
      envConfig = currentEnvConfig.envConfig;
      config.envName = currentEnvConfig.envName;
    } else { // Default environment.
      config.production = false;
      envConfig = environment;
      config.envName = 'DEFAULT';
      envConfig.apiBaseURL = `${location.origin}/api`;
    }
    Object.keys(envConfig).forEach(k => config[k] = envConfig[k]);

    this.config = config;
  }

  public getConfig(): IConfiguration {
    if (!this.config) {
      this.buildConfig();
    }
    return this.config;
  }
}
