// Core.
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models & Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { IDoPersonFraudeResponse } from './i-do-person-fraude-response';
import { IGetPersonActivitiesResponsePersonActivityItem } from './i-get-person-activities-response-person-activity-item';
import { IGetPersonDocumentTypesResponsePersonDocumentTypeItem } from './i-get-person-document-types-response-person-document-type-item';
import { IGetPersonMaritalStatusResponsePersonMaritalStatusItem } from './i-get-person-marital-status-response-person-marital-status-item';
import { IGetPersonNationalitiesResponsePersonNationalityItem } from './i-get-person-nationalities-response-person-nationality-item';
import { IGetPersonTaxConditionsResponsePersonTaxConditionItem } from './i-get-person-tax-conditions-response-person-tax-condition-item';
import { PersonActivityModel } from '../../models/person-activity.model';
import { PersonDocumentTypeModel } from '../../models/person-document-type.model';
import { PersonMaritalStatusModel } from '../../models/person-marital-status.model';
import { PersonNationalityModel } from '../../models/person-nationality.model';
import { PersonSocietyKindModel } from '../../models/person-society-kind.model';
import { PersonTaxConditionModel } from '../../models/person-tax-condition.model';
// Services.
import { ErrorService } from '../error/error.service';
import { PersistenceService } from '../persistence/persistence.service';
import { SessionService } from '../session/session.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PersonService';
_LOGGER.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_DOC_TYPES: {
    KEY: 'get_doc_types',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_TAX_CONDITIONS: {
    KEY: 'get_tax_conds',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_NATIONALITIES: {
    KEY: 'get_nations',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_MARITAL_STATUS: {
    KEY: 'get_marital_st',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_ACTIVITIES: {
    KEY: 'get_activities',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Person Data Service (Document Types, Nationalities, Activities).
 */
@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private baseURL = `${_CONFIG.apiBaseURL}/persona`;

  private cacheGetDocumentTypes: Observable<PersonDocumentTypeModel[] | BaseAppError>;
  private cacheGetTaxConditions: Observable<PersonTaxConditionModel[] | BaseAppError>;
  private cacheGetNationalities: Observable<PersonNationalityModel[] | BaseAppError>;
  private cacheGetMaritalStatus: Observable<PersonMaritalStatusModel[] | BaseAppError>;
  private cacheGetActivities: Observable<PersonActivityModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistenceService: PersistenceService,
    private sessionService: SessionService
  ) {
  }

  private sortItemsByName(a: PersonNationalityModel | PersonSocietyKindModel,
                          b: PersonNationalityModel | PersonSocietyKindModel): number {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  /**
   * Gets the available Person Document Types.
   */
  public getDocumentTypes(): Observable<PersonDocumentTypeModel[] | BaseAppError> {
    const __SUBTAG = 'getDocumentTypes';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]) => PersonDocumentTypeModel[] = (data) => {
      const documentTypes = data
        .filter(docType => docType.code !== "DE")
        .map(docType => new PersonDocumentTypeModel(docType.code, docType.descripcion));
      // Add hardcoded a Document Type for Enterprise Person Type because the API only returns Document for Individuals.
      documentTypes.push(new PersonDocumentTypeModel(_CONFIG.defaultEnterpriseDocumentTypeCode, 'CUIT', false, true));
      return documentTypes;
    };

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_DOC_TYPES.KEY, _CACHE_CONFIG.GET_DOC_TYPES.AGE)) {
      const endpointURL = `${this.baseURL}/tiposdocumento`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetDocumentTypes = this.http.get<IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]>(endpointURL,
        {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_DOC_TYPES.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetDocumentTypes) {
        const cacheData: IGetPersonDocumentTypesResponsePersonDocumentTypeItem[] = PersistenceService.retrieve(
          _CACHE_CONFIG.GET_DOC_TYPES.KEY, _CACHE_CONFIG.GET_DOC_TYPES.AGE);
        this.cacheGetDocumentTypes = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetPersonDocumentTypesResponsePersonDocumentTypeItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetDocumentTypes;
  }

  /**
   * Gets the available Person Tax Conditions.
   */
  public getTaxConditions(): Observable<PersonTaxConditionModel[] | BaseAppError> {
    const __SUBTAG = 'getTaxConditions';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetPersonTaxConditionsResponsePersonTaxConditionItem[]) => PersonTaxConditionModel[] = (data) => data
      .map(taxCond => new PersonTaxConditionModel(taxCond.code, taxCond.descripcion,
        (taxCond.code !== _CONFIG.enterpriseTaxConditionExclusiveCode)));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_TAX_CONDITIONS.KEY, _CACHE_CONFIG.GET_TAX_CONDITIONS.AGE)) {
      const endpointURL = `${this.baseURL}/condicionesimpositivas`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetTaxConditions = this.http.get<IGetPersonTaxConditionsResponsePersonTaxConditionItem[]>(endpointURL,
        {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetPersonTaxConditionsResponsePersonTaxConditionItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_TAX_CONDITIONS.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetPersonTaxConditionsResponsePersonTaxConditionItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetTaxConditions) {
        const cacheData: IGetPersonTaxConditionsResponsePersonTaxConditionItem[] = PersistenceService.retrieve(
          _CACHE_CONFIG.GET_TAX_CONDITIONS.KEY, _CACHE_CONFIG.GET_TAX_CONDITIONS.AGE);
        this.cacheGetTaxConditions = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetPersonTaxConditionsResponsePersonTaxConditionItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetPersonTaxConditionsResponsePersonTaxConditionItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetTaxConditions;
  }

  /**
   * Gets the available Person Nationalities.
   */
  public getNationalities(): Observable<PersonNationalityModel[] | BaseAppError> {
    const __SUBTAG = 'getNationalities';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetPersonNationalitiesResponsePersonNationalityItem[]) => PersonNationalityModel[] = (data) => data
      .map(nationality => new PersonNationalityModel(nationality.code, nationality.descripcion))
      .sort(this.sortItemsByName);

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_NATIONALITIES.KEY, _CACHE_CONFIG.GET_NATIONALITIES.AGE)) {
      const endpointURL = `${this.baseURL}/nacionalidades`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetNationalities = this.http.get<IGetPersonNationalitiesResponsePersonNationalityItem[]>(endpointURL,
        {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetPersonNationalitiesResponsePersonNationalityItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_NATIONALITIES.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetPersonNationalitiesResponsePersonNationalityItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetNationalities) {
        const cacheData: IGetPersonNationalitiesResponsePersonNationalityItem[] = PersistenceService.retrieve(
          _CACHE_CONFIG.GET_NATIONALITIES.KEY, _CACHE_CONFIG.GET_NATIONALITIES.AGE);
        this.cacheGetNationalities = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetPersonNationalitiesResponsePersonNationalityItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetPersonNationalitiesResponsePersonNationalityItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetNationalities;
  }

  /**
   * Gets the available Person Marital Status.
   */
  public getMaritalStatus(): Observable<PersonMaritalStatusModel[] | BaseAppError> {
    const __SUBTAG = 'getMaritalStatus';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]) => PersonMaritalStatusModel[] = (data) => data
      .map(maritalStatus => new PersonMaritalStatusModel(maritalStatus.code, maritalStatus.descripcion));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_MARITAL_STATUS.KEY, _CACHE_CONFIG.GET_MARITAL_STATUS.AGE)) {
      const endpointURL = `${this.baseURL}/estadosciviles`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetMaritalStatus = this.http.get<IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]>(endpointURL,
        {observe: 'response'})
        .pipe(
          // Log operation & store response in cache
          tap((response: HttpResponse<IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_MARITAL_STATUS.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetMaritalStatus) {
        const cacheData: IGetPersonMaritalStatusResponsePersonMaritalStatusItem[] = PersistenceService.retrieve(
          _CACHE_CONFIG.GET_MARITAL_STATUS.KEY, _CACHE_CONFIG.GET_MARITAL_STATUS.AGE);
        this.cacheGetMaritalStatus = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetPersonMaritalStatusResponsePersonMaritalStatusItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetMaritalStatus;
  }

  /**
   * Gets the available Person Activities.
   */
  public getActivities(personType: string): Observable<PersonActivityModel[] | BaseAppError> {
    const __SUBTAG = 'getActivities';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetPersonActivitiesResponsePersonActivityItem[]) => PersonActivityModel[] = (data) => data
      .map(personActivity => new PersonActivityModel(personActivity.code, personActivity.descripcion));

    const __CACHE_KEY_SUFFIX = `_${personType}`;

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_ACTIVITIES.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_ACTIVITIES.AGE)) {
      const endpointURL = `${this.baseURL}/actividades?type=${personType}`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetActivities = this.http.get<IGetPersonActivitiesResponsePersonActivityItem[]>(endpointURL,
        {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetPersonActivitiesResponsePersonActivityItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_ACTIVITIES.KEY + __CACHE_KEY_SUFFIX, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetPersonActivitiesResponsePersonActivityItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      const cacheData: IGetPersonActivitiesResponsePersonActivityItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_ACTIVITIES.KEY + __CACHE_KEY_SUFFIX,
        _CACHE_CONFIG.GET_ACTIVITIES.AGE);
      this.cacheGetActivities = of(cacheData)
        .pipe(
          // Log operation.
          tap((data: IGetPersonActivitiesResponsePersonActivityItem[]) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
          }),
          // Parse response.
          map((data: IGetPersonActivitiesResponsePersonActivityItem[]) => parseData(data)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    }

    return this.cacheGetActivities;
  }

  /**
   * Checks Person fraud listing. Returns success operation flag.
   *
   * @param personIsIndividual  Flag that indicates whether the Person is an individual or not.
   * @param surname             Surname of the Person being checked.
   * @param name                Name of the Person being checked.
   * @param cuit                CUIT number of the Person being checked.
   */
  public doCheckPersonFraud(personIsIndividual: boolean, surname: string, name: string, cuit: number): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doCheckPersonFraud';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    this.sessionService.setPersonFraudCheckCompleted(false);
    this.sessionService.setPersonFraudCheckResult(null);

    const endpointURL = `${this.baseURL}/fraude`;
    const payload = {
      apellido: personIsIndividual ? surname : null,
      cuit,
      nombre: name,
      tipoPersona: personIsIndividual ? 'INDIVIDUO' : 'EMPRESA'
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoPersonFraudeResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoPersonFraudeResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Return success flag.
        map((response: HttpResponse<IDoPersonFraudeResponse>) => {
          this.sessionService.setPersonFraudCheckCompleted(true);
          this.sessionService.setPersonFraudCheckResult(false); // Result: Not listed.
          return response.ok;
        }),
        // Error handler.
        catchError(error => {
          this.sessionService.setPersonFraudCheckCompleted(true);
          this.sessionService.setPersonFraudCheckResult(true); // Result: Listed.
          return of(this.errorService.getAppError(error));
        })
      );
  }

  /**
   * Gets the available Society Kins
   */
  public getSocietyKinds(): Observable<PersonSocietyKindModel[] | BaseAppError> {
    const __SUBTAG = 'getSocietyKinds';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    // Hardcoded response.
    return of([
      new PersonSocietyKindModel('AC', 'ASOCIACIÓN CIVIL'),
      new PersonSocietyKindModel('CAM', 'CÁMARA'),
      new PersonSocietyKindModel('CCE', 'CONTRATO DE COLABORACIÓN EMPRESERIAL'),
      new PersonSocietyKindModel('CONF', 'CONFEDERACIÓN'),
      new PersonSocietyKindModel('COOP', 'COOPERATIVA'),
      new PersonSocietyKindModel('EESFL', 'ENTIDAD EXTRANJERA SIN FINES DE LUCRO'),
      new PersonSocietyKindModel('FC', 'FIDELCOMISO'),
      new PersonSocietyKindModel('FED', 'FEDERACIÓN'),
      new PersonSocietyKindModel('FUN', 'FUNDACIÓN'),
      new PersonSocietyKindModel('MUT', 'MUTUAL'),
      new PersonSocietyKindModel('O P', 'ORGAN PUBLICO'),
      new PersonSocietyKindModel('SA', 'SOCIEDAD ANONIMA'),
      new PersonSocietyKindModel('SAA1C&A', 'SA ARG INDUSTRIAL COMERCIAL Y AGROPECUARIA'),
      new PersonSocietyKindModel('SAACEI', 'SOC ANONIMA AGROPECUARIA COM E IND'),
      new PersonSocietyKindModel('SAAICYA', 'SA ARG INDUSTRIAL COMERCIAL Y AGROPECUARIA'),
      new PersonSocietyKindModel('SAAYC', 'SOC ANONIMA AGROPECUARIA Y COMERCIAL'),
      new PersonSocietyKindModel('SAC', 'SOCIEDAD ANONIMA COMERCIAL'),
      new PersonSocietyKindModel('SACAYF', 'SA COMERCIAL AGROPECUARIA FINANCIERA'),
      new PersonSocietyKindModel('SACI', 'SOC ANONIMA COMERCIAL INDUSTRIAL'),
      new PersonSocietyKindModel('SACIF', 'SOC ANONIMA COMERCIAL INDUSTRIAL FINANCIERA'),
      new PersonSocietyKindModel('SAFIM', 'S A FINANCIERA IMPORTADORA MANDATARIA'),
      new PersonSocietyKindModel('SAFYM', 'SOCIEDAD ANONIMA FINANCIERA Y MANDATARIA'),
      new PersonSocietyKindModel('SAIC', 'SOCIEDAD ANONIMA INDUSTRIAL Y COMERCIAL'),
      new PersonSocietyKindModel('SAIC Y F', 'SOC ANONIMA, INMOB, COMERCIAL Y FINANCIERA'),
      new PersonSocietyKindModel('SAICYF', 'SOC ANONIMA, INMOB, COMERCIAL Y FINANCIERA'),
      new PersonSocietyKindModel('SAIYA', 'S A INDUSTRIAL Y AGROPECUARIA'),
      new PersonSocietyKindModel('SAS', 'SOCIEDAD ANONIMA SIMPLIFICADA'),
      new PersonSocietyKindModel('SASOC', 'SIMPLES ASOCIACIONES'),
      new PersonSocietyKindModel('SAU', 'SOCIEDAD ANONIMA UNIPERSONAL'),
      new PersonSocietyKindModel('SB', 'SOCIEDAD DE BOLSA'),
      new PersonSocietyKindModel('SBFJ', 'SOCIEDAD BINACIONAL FUERA DE JURIDICCIÓN'),
      new PersonSocietyKindModel('SC', 'SOCIEDAD COLECTIVA'),
      new PersonSocietyKindModel('SCA', 'SOCIEDAD EN COMANDITA POR ACCIONES'),
      new PersonSocietyKindModel('SCAH', 'SOCIEDAD DE CAPITALIZACIÓN Y AHORRO'),
      new PersonSocietyKindModel('SCE', 'SOCIEDAD CONSTITUIDA EN EL EXTRANJERO'),
      new PersonSocietyKindModel('SCI', 'SOCIEDAD DE CAPITAL E INDUSTRIA'),
      new PersonSocietyKindModel('SCIV', 'SOCIEDAD CIVIL'),
      new PersonSocietyKindModel('SCS', 'SOCIEDAD EN COMANDITA SIMPLE'),
      new PersonSocietyKindModel('SE', 'SOCIEDAD DEL ESTADO'),
      new PersonSocietyKindModel('SGR', 'SOCIEDAD DE GARANTÍA RECÍPROCA'),
      new PersonSocietyKindModel('SH', 'SOCIEDAD DE HECHO'),
      new PersonSocietyKindModel('SRL', 'SOCIEDAD DE RESPONSABILIDAD LIMITADA'),
      new PersonSocietyKindModel('UTE', 'UNIÓN TRANSITORIA DE EMPRESAS')
    ].sort(this.sortItemsByName))
      .pipe(
        // Log operation.
        tap((data: PersonSocietyKindModel[]) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'hardcoded response:', data);
        }),
        // Replay last response.
        publishReplay(1),
        refCount(),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }
}
