import { BrokerInsuranceQuoteModel } from './broker-insurance-quote.model';
import { BrokerInsuranceQuoteOptionModel } from './broker-insurance-quote-option.model';


export enum BROKER_INSURANCE_FAMILY {
  THIRD_PARTY_FULL = 1,
  ALL_RISK = 2,
  THIRD_PARTY = 3
}


export class BrokerModel {
  public code: string;
  public name: string;
  public cashPaymentMessage: string;

  public loadingInsuranceQuote = true;
  public insuranceQuote: BrokerInsuranceQuoteModel;
  public successLoadInsuranceQuote = false;

  public selectedInsuranceFamily: BROKER_INSURANCE_FAMILY;
  public selectedInsuranceOption: BrokerInsuranceQuoteOptionModel;

  constructor(code: string, name: string, cashPaymentMessage: string) {
    this.code = code;
    this.name = name;
    this.cashPaymentMessage = cashPaymentMessage;
  }

  public get selectedInsuranceFamilyName(): string {
    if (this.selectedInsuranceFamily) {
      switch (this.selectedInsuranceFamily) {
        case BROKER_INSURANCE_FAMILY.THIRD_PARTY_FULL:
          return 'Terceros Completo';
        case BROKER_INSURANCE_FAMILY.ALL_RISK:
          return 'Todo Riesgo';
        case BROKER_INSURANCE_FAMILY.THIRD_PARTY:
          return 'Terceros Básico';
      }
    }
    return 'Ninguna';
  }

  public get selectedInsuranceFamilyOptions(): BrokerInsuranceQuoteOptionModel[] {
    if (this.selectedInsuranceFamily && this.insuranceQuote) {
      switch (this.selectedInsuranceFamily) {
        case BROKER_INSURANCE_FAMILY.THIRD_PARTY_FULL:
          return this.insuranceQuote.thirdPartyFullOptions || null;
        case BROKER_INSURANCE_FAMILY.ALL_RISK:
          return this.insuranceQuote.allRiskOptions || null;
        case BROKER_INSURANCE_FAMILY.THIRD_PARTY:
          return this.insuranceQuote.thirdPartyOptions || null;
      }
    }
    return null;
  }
}
