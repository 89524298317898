// Core.
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Models & Interfaces.
import { BaseAppError } from './base-app-error';
import { IAPIErrorResponse } from './i-api-error-response';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ErrorService';
_LOGGER.debug(_TAG, 'loaded.');


@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  /** Unknown error. */
  public readonly UNKNOWN_ERROR = {code: 'UNKNOWN', message: 'Error desconocido.'};

  /** Known external errors. */
  public readonly EXTERNAL_KNOWN_ERROR = {
    NETWORK: {
      OFFLINE: {
        code: 'EXT:NETWORK:OFFLINE',
        message: 'No fue posible conectarse con el servidor. Por favor, compruebe su conexión a Internet e intente nuevamente.'
      }
    },
    API: {
      SERVER_BASE: {
        code: 'EXT:API:SERVER_BASE',
        message: 'Error general del sistema, por favor intente nuevamente más tarde.'
      },
      UNAUTHORIZED: {
        code: 'EXT:API:UNAUTHORIZED',
        message: 'Usted no tiene permisos suficientes para acceder a esta funcionalidad o su sesión ha expirado.'
      }
    }
  };

  constructor() {
  }

  /**
   * Returns a known error code from any HTTP error number code.
   */
  private static getHttpErrorCode(code: number): string {
    return 'HTTP:' + code.toString();
  }

  /**
   * Returns a known error code from an API HTTP error number code.
   */
  public static getApiHttpErrorCode(code: number): string {
    return 'API:' + ErrorService.getHttpErrorCode(code);
  }


  /**
   * Errors handler. Default behaviour: console log if not production env.
   */
  public static handleError(error: any): void {
    const __SUBTAG = 'handleError';
    if (!_CONFIG.production) {
      _LOGGER.error(_TAG, __SUBTAG, error);
    }
  }

  /**
   * Parses API message errors.
   */
  private getApiErrorText(apiError: IAPIErrorResponse): string {
    // Get error message from API error response interface.
    return ((apiError && apiError.message) || this.UNKNOWN_ERROR.message);
  }

  /**
   * Handles App errors.
   */
  public getAppError(appError: any): BaseAppError {
    const __SUBTAG = 'getAppError';

    if (appError instanceof BaseAppError) {
      return appError;
    }

    _LOGGER.debug(_TAG, __SUBTAG, 'Error:', appError);

    // Base error.
    let errorCode: string = (appError && (appError.code || appError.errorCode) || this.UNKNOWN_ERROR.code);
    let errorMessage: string = (appError && (appError.message || appError.errorMessage) || this.UNKNOWN_ERROR.message);

    // HTTP Errors.
    if (appError instanceof HttpErrorResponse) {
      // Common HTTP errors.
      _LOGGER.debug(_TAG, __SUBTAG, 'error is instanceof HttpErrorResponse');

      errorCode = ErrorService.getHttpErrorCode(appError.status);
      errorMessage = (appError.statusText || errorMessage);

      _LOGGER.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', errorCode, 'Msg:', errorMessage);

      // Get Response body.
      let responseBody: any = (appError.error || {});
      if ((responseBody instanceof String) || (typeof responseBody === 'string')) {
        try {
          _LOGGER.debug(_TAG, __SUBTAG, 'parsing JSON HttpErrorResponse Body:', responseBody);
          if (typeof responseBody !== 'string') {
            responseBody = JSON.parse(responseBody.toString());
          } else {
            responseBody = JSON.parse(responseBody);
          }
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error parsing appError Response body as JSON:', e);
        }
      }
      _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse Body:', responseBody);

      // Get error context by URL.
      if (appError.url) {
        _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse URL:', appError.url);

        // TCFA API errors.
        if (appError.url.indexOf(_CONFIG.apiBaseURL) === 0) {
          _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse URL matches API:', _CONFIG.apiBaseURL);

          errorCode = ErrorService.getApiHttpErrorCode(appError.status);
          errorMessage = (this.getApiErrorText(responseBody as IAPIErrorResponse) || errorMessage);

          _LOGGER.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', errorCode, 'Msg:', errorMessage);
        }
      }

      // Override explicit errors messages.
      // Network error.
      if (appError.status === 0) {
        _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 0 (network error).');

        errorMessage = this.EXTERNAL_KNOWN_ERROR.NETWORK.OFFLINE.message;
        // Server-side errors.
      } else if (appError.status >= 500) {
        _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 500 (server error).');

        errorMessage = this.EXTERNAL_KNOWN_ERROR.API.SERVER_BASE.message;
        // Session error.
      } else if (appError.status === 401) {
        _LOGGER.debug(_TAG, __SUBTAG, 'HttpErrorResponse overriding error status 401 (session error).');

        errorMessage = this.EXTERNAL_KNOWN_ERROR.API.UNAUTHORIZED.message;
      }

      // Known errors.
    } else if (appError instanceof Error) {
      // Base known errors.
      _LOGGER.debug(_TAG, __SUBTAG, 'error is instanceof Error');

      errorCode = (appError.name || errorCode);
      errorMessage = (appError.message || errorMessage);

      // Numeric errors.
    } else if ((appError instanceof Number) || (typeof appError === 'number')) {
      // Base numeric errors.
      _LOGGER.debug(_TAG, __SUBTAG, 'error is instanceof Number');

      errorCode = appError.toString();
      errorMessage = 'Error ' + appError.toString() + '.';

      // String errors (no code).
    } else if ((appError instanceof String) || (typeof appError === 'string')) {
      _LOGGER.debug(_TAG, __SUBTAG, 'error is instanceof String');

      errorMessage = (appError.toString() || errorMessage);

      // Other errors.
    } else {
      _LOGGER.debug(_TAG, __SUBTAG, 'error is instanceof None');

      // None.
    }

    _LOGGER.debug(_TAG, __SUBTAG, 'error partial.', 'Code:', errorCode, 'Msg:', errorMessage);

    // Handle error & return error message.
    const resultError: BaseAppError = new BaseAppError(errorCode, errorMessage);
    ErrorService.handleError(resultError);
    return resultError;
  }
}
