import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../../services/user/user.service';
import { CotizadorService } from 'src/app/services/cotizador/cotizador.service';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PageHeaderQuoteInfoComponent';
_LOGGER.debug(_TAG, 'loaded.');

@Component({
  selector: 'app-page-header-quote-info',
  templateUrl: './page-header-quote-info.component.html',
  styleUrls: ['./page-header-quote-info.component.scss']
})
export class PageHeaderQuoteInfoComponent {

  constructor(
    public userService: UserService,
    private readonly cotizadorService: CotizadorService,
  ) {
  }

  public getQuoteId() : number{
    return CotizadorService.getInsuranceQuoteMainId();
  }

  public getQuoteChannel() : string{
    return CotizadorService.getInsuranceQuoteChannel();
  }
}
