/** Adds Public Access Secret HEADER to requests. */
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from '../../services/user/user.service';

const SECRET_HEADER_KEY = 'X-Employee-Secret';

@Injectable()
export class PublicAccessSecretInterceptor implements HttpInterceptor {
  constructor(
    private readonly userService: UserService
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const publicAccessSecret = this.userService.getUserPublicAccessSecret();
    return !!publicAccessSecret ? next.handle(req.clone({
      headers: req.headers.set(SECRET_HEADER_KEY, publicAccessSecret)
    })) : next.handle(req);
  }
}
