export class BrokerInsuranceQuoteOptionModel {
  public id: number;
  public name: string;
  public bullets: string[];
  public amount: number;
  public acceptCashPayment: boolean;

  constructor(id: number, name: string, bullets: string[], amount: number, acceptCashPayment: boolean) {
    this.id = id;
    this.name = name;
    this.bullets = bullets;
    this.amount = amount;
    this.acceptCashPayment = acceptCashPayment;
  }
}
