export enum LOGLEVEL {
  NONE = 0,
  ERROR = 10,
  INFO = 20,
  DEBUG = 30
}

/**
 * <Singleton>
 */
export class SimpleLogger {
  private static instance: SimpleLogger = new SimpleLogger();

  private logLevel: LOGLEVEL = LOGLEVEL.DEBUG;

  private constructor() {
  }

  public static getInstance(): SimpleLogger {
    if (!SimpleLogger.instance) {
      SimpleLogger.instance = new SimpleLogger();
    }
    return SimpleLogger.instance;
  }

  public setLogLevel(logLevel: LOGLEVEL): void {
    this.logLevel = logLevel;
  }

  public info(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.INFO) {
      window.console.info(message, ...params);
    }
  }

  public error(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.ERROR) {
      window.console.error(message, ...params);
    }
  }

  public debug(message?: any, ...params: any[]): void {
    if (this.logLevel >= LOGLEVEL.DEBUG) {
      window.console.debug(message, ...params);
    }
  }
}
