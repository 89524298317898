export class PolicyIssuedModel {
  public policyId: number;
  public holderName: string;
  public holderDocument: string;
  public insuranceCompany: string;
  public paymentMethod: string;
  public insuranceCoverage: string;
  public vehicleBrandName: string;
  public vehicleModelName: string;
  public policyUrl: string;
  public requestUrl: string;
  public certificateUrl: string;
  public cardUrl: string;
  public couponUrl: string;
  public checked?: boolean;

  constructor(policyId: number, holderName: string, holderDocument: string, insuranceCompany: string, paymentMethod: string,
              insuranceCoverage: string, vehicleBrandName: string, vehicleModelName: string, policyUrl: string, requestUrl: string,
              certificateUrl: string, cardUrl: string, couponUrl: string, checked: boolean = false) {
    this.policyId = policyId;
    this.holderName = holderName;
    this.holderDocument = holderDocument;
    this.insuranceCompany = insuranceCompany;
    this.paymentMethod = paymentMethod;
    this.insuranceCoverage = insuranceCoverage;
    this.vehicleBrandName = vehicleBrandName;
    this.vehicleModelName = vehicleModelName;
    this.policyUrl = policyUrl;
    this.requestUrl = requestUrl;
    this.certificateUrl = certificateUrl;
    this.cardUrl = cardUrl;
    this.couponUrl = couponUrl;
    this.checked = checked;
  }
}
