import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerUtilsService} from './date-picker-utils.service';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class DatePickerStringParserFormatter extends NgbDateParserFormatter {
  constructor(
    private datePickerUtilsService: DatePickerUtilsService) {
    super();
  }

  public parse(value: string): NgbDateStruct | null {
    return this.datePickerUtilsService.stringToNgbDateStruct(value);
  }

  public format(date: NgbDateStruct | null): string {
    return this.datePickerUtilsService.ngbDateStructToString(date);
  }
}
