// Models.
import { VehicleModelModel } from './vehicle-model.model';


export class VehicleBrandModel {
  public id: number;
  public name: string;
  public models: VehicleModelModel[];

  constructor(id: number, name: string, models: VehicleModelModel[] = []) {
    this.id = id;
    this.name = name;
    this.models = models;
  }
}
