import {BrokerInsuranceQuoteOptionModel} from './broker-insurance-quote-option.model';


export class BrokerInsuranceQuoteModel {
  public id: number;
  public timestamp: Date;
  public insuredAmount: number;

  public thirdPartyFromValue: number;
  public thirdPartyFullFromValue: number;
  public allRiskFromValue: number;

  public thirdPartyOptions: BrokerInsuranceQuoteOptionModel[];
  public thirdPartyFullOptions: BrokerInsuranceQuoteOptionModel[];
  public allRiskOptions: BrokerInsuranceQuoteOptionModel[];

  public minThirdPartyOption: BrokerInsuranceQuoteOptionModel;
  public minThirdPartyFullOption: BrokerInsuranceQuoteOptionModel;
  public minAllRiskOption: BrokerInsuranceQuoteOptionModel;

  constructor(id: number, timestamp: Date, insuredAmount: number, thirdPartyFromValue?: number, thirdPartyFullFromValue?: number,
              allRiskFromValue?: number, thirdPartyOptions?: BrokerInsuranceQuoteOptionModel[],
              thirdPartyFullOptions?: BrokerInsuranceQuoteOptionModel[], allRiskOptions?: BrokerInsuranceQuoteOptionModel[],
              minThirdPartyOption?: BrokerInsuranceQuoteOptionModel, minThirdPartyFullOption?: BrokerInsuranceQuoteOptionModel,
              minAllRiskOption?: BrokerInsuranceQuoteOptionModel) {
    this.id = id;
    this.timestamp = timestamp;
    this.insuredAmount = insuredAmount;

    this.thirdPartyFromValue = thirdPartyFromValue;
    this.thirdPartyFullFromValue = thirdPartyFullFromValue;
    this.allRiskFromValue = allRiskFromValue;

    this.thirdPartyOptions = thirdPartyOptions;
    this.thirdPartyFullOptions = thirdPartyFullOptions;
    this.allRiskOptions = allRiskOptions;

    this.minThirdPartyOption = minThirdPartyOption;
    this.minThirdPartyFullOption = minThirdPartyFullOption;
    this.minAllRiskOption = minAllRiskOption;
  }
}
