// Core.
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ContinueByPhonePageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Continue By Phone page.
 */
@Component({
  selector: 'app-continue-by-phone-page',
  templateUrl: './continue-by-phone-page.component.html',
  styleUrls: ['./continue-by-phone-page.component.scss']
})
export class ContinueByPhonePageComponent implements OnInit {

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
  }

  /**
   * Angular component OnInit event handler.
   * Navs to Home because this functionality is disabled (issue #TCSOM-123).
   */
  public ngOnInit(): void {
    this.router
      .navigate(['/home'])
      .finally(() => {
        this.viewportScroller.scrollToAnchor('app-main-header');
      });
  }
}
