export type UserVerifyResultStatus = 'OK' | 'VERIFY' | 'REJECT';

export class UserVerifyResultModel {
  public status: UserVerifyResultStatus;
  public detail: string;

  constructor(status: UserVerifyResultStatus, detail: string) {
    this.status = status;
    this.detail = detail;
  }
}
