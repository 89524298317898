import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';
import {PublicAccessService} from '../../../../services/public-access/public-access.service';
import {BaseAppError} from '../../../../services/error/base-app-error';
import {ModalsService} from '../../../../services/modals/modals.service';
import {ErrorService} from '../../../../services/error/error.service';
import {PublicAccessContinueByInfo} from '../../../../services/public-access/public-access-continue-by-info';
import {Router} from '@angular/router';
import {PublicAccessContinueByMethod} from '../../../../services/public-access/public-access-continue-by-method';
import {GetPublicAccessInfoResponseTimeSlot} from '../../../../services/public-access/get-public-access-info-response';
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'HowToContinuePublicAccessComponent';
_LOGGER.debug(_TAG, 'loaded.');

@Component({
  selector: 'app-how-to-continue-public-access',
  templateUrl: './how-to-continue-public-access.component.html',
  styleUrls: ['./how-to-continue-public-access.component.scss']
})
export class HowToContinuePublicAccessComponent implements OnInit {
  public publicAccessContinueByContactInfo: PublicAccessContinueByInfo;
  private continueByMethod: PublicAccessContinueByMethod;
  public disableMethodSelection: boolean;
  public selectedTimeSlot: GetPublicAccessInfoResponseTimeSlot = null;
  public disableTimeSlotSelection: boolean;
  public continueByCallbackIsDone: boolean;
  public whatsappChatUrl: string;
  public loadingContinueByInfo: boolean;

  public get isContinueByUserCall(): boolean {
    return (this.continueByMethod === PublicAccessContinueByMethod.USER_CALL);
  }

  public get isContinueByWhatsApp(): boolean {
    return (this.continueByMethod === PublicAccessContinueByMethod.WHATSAPP);
  }

  public get isContinueByCallback(): boolean {
    return (this.continueByMethod === PublicAccessContinueByMethod.CALLBACK);
  }

  @Input() public accessFromButtonDownload: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private modalsService: ModalsService,
    private errorService: ErrorService,
    private publicAccessService: PublicAccessService,
    private router: Router,
    private cotizadorService: CotizadorService
  ) {
  }

  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      }).finally(() => {
        this.activeModal.dismiss(error);
      });
    });
  }

  public ngOnInit(): void {
    const __SUBTAG = 'ngOnInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');
    this.loadingContinueByInfo = true;
    this.publicAccessService.getContinueByInfo().subscribe(resultOrError => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'getContinueByInfo resulted in error.');
        this.onError(resultOrError);
        return;
      }

      // Succeed.
      if (!!resultOrError) {
        _LOGGER.debug(_TAG, __SUBTAG, 'getContinueByInfo completed.');
        this.publicAccessContinueByContactInfo = resultOrError;
      } else {
        _LOGGER.error(_TAG, __SUBTAG, 'getContinueByInfo failed.');
        this.onError(new Error('Ha ocurrido un error durante la obtención de datos. Intente nuevamente más tarde.'));
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on getContinueByInfo.');
      this.onError(error);
    }, () => {
      this.loadingContinueByInfo = false;
    });
  }

  private signalMethodSelection(): void {
    const __SUBTAG = 'signalMethodSelection';
    if (!this.accessFromButtonDownload) {
      this.publicAccessService.selectContinueByMethod(this.continueByMethod, this.selectedTimeSlot)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          this.callBackSelectContinueByMethod(resultOrError);
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on selectContinueByMethod.');
          this.onError(error);
        });
    } else {
      this.cotizadorService.doSetQuotesRequested(this.continueByMethod, this.selectedTimeSlot)
        .subscribe((resultOrError: boolean | BaseAppError) => {
          this.callBackSelectContinueByMethod(resultOrError);
        }, (error: any) => {
          _LOGGER.error(_TAG, __SUBTAG, 'Error on doSetQuotesRequested.');
          this.onError(error);
        });
    }
  }

  public onContinueByUserCall(): void {
    this.continueByMethod = PublicAccessContinueByMethod.USER_CALL;
    this.disableMethodSelection = true;
    this.signalMethodSelection();
  }

  public onContinueByWhatsApp(): void {
    this.continueByMethod = PublicAccessContinueByMethod.WHATSAPP;
    this.disableMethodSelection = true;
    this.signalMethodSelection();
  }

  public onContinueByCallBack(): void {
    this.continueByMethod = PublicAccessContinueByMethod.CALLBACK;
    this.disableMethodSelection = true;
    this.disableTimeSlotSelection = false;
    this.continueByCallbackIsDone = true;
  }

  public onMakeTimeSlotSelection(): void {
    this.disableTimeSlotSelection = true;
    this.signalMethodSelection();
  }

  public onClose(): void {
    this.activeModal.close(this.continueByMethod);
  }

  public callBackSelectContinueByMethod(resultOrError: boolean | BaseAppError): void {
    const __SUBTAG = 'callBackSelectContinueByMethod';

    // Failed.
    if (resultOrError instanceof BaseAppError) {
      _LOGGER.error(_TAG, __SUBTAG, 'callBackSelectContinueByMethod resulted in error.');
      this.onError(resultOrError);
      return;
    }

    // Succeed.
    if (resultOrError) {
      _LOGGER.debug(_TAG, __SUBTAG, 'callBackSelectContinueByMethod completed.');
      if (this.isContinueByCallback) {
        this.continueByCallbackIsDone = true;
      } else if (this.isContinueByWhatsApp) {
        this.whatsappChatUrl = `https://wa.me/${this.publicAccessContinueByContactInfo.whatsapp.phone}/`;
        if (!!this.publicAccessContinueByContactInfo.whatsapp.message) {
          this.whatsappChatUrl = `${this.whatsappChatUrl}?text=${this.publicAccessContinueByContactInfo.whatsapp.message}`;
        }
        window.open(this.whatsappChatUrl);
      }
    } else {
      _LOGGER.error(_TAG, __SUBTAG, 'callBackSelectContinueByMethod failed.');
      this.onError(new Error('Ha ocurrido un error durante el guardado de los datos. Intente nuevamente más tarde.'));
    }

  }
}
