import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as XLSXStyle from 'sheetjs-style'
import { IDoExcelExport } from './i-do-excel-export';
import { IDoExcelExportWorksheet } from './i-do-excel-export-worksheet';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';
const HEADER_STYLE = {
  fill: {
    fgColor: { rgb: 'FF808080' }
  },
  font: {
    name: 'Calibri',
    sz: 10,
    bold: true,
    color: { rgb: 'FFFFFFFF' }
  },
  border: {
    bottom: {
      style: 'thin',
      color: 'FF808080'
    },
    left: {
      style: 'thin',
      color: 'FF808080'
    },
    right: {
      style: 'thin',
      color: 'FF808080'
    },
    top: {
      style: 'thin',
      color: 'FF808080'
    }
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center'
  }
};
const VEHICLE_ROW_STYLE = {
  fill: {
    fgColor: { rgb: 'FFFFFFFF' }
  },
  font: {
    name: 'Calibri',
    sz: 9,
    bold: false,
    color: { rgb: 'FF000000' }
  },
  border: {
    bottom: {
      style: 'thin',
      color: 'FF538DD5'
    },
    left: {
      style: 'thin',
      color: 'FF538DD5'
    },
    right: {
      style: 'thin',
      color: 'FF538DD5'
    },
    top: {
      style: 'thin',
      color: 'FF538DD5'
    }
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true
  }
};
const ROW_STYLE = {
  fill: {
    fgColor: { rgb: 'FFFFFFFF' }
  },
  font: {
    name: 'Calibri',
    sz: 9,
    bold: false,
    color: { rgb: 'FF000000' }
  },
  border: {
    bottom: {
      style: 'thin',
      color: 'FF538DD5'
    },
    left: {
      style: 'thin',
      color: 'FF538DD5'
    },
    right: {
      style: 'thin',
      color: 'FF538DD5'
    },
    top: {
      style: 'thin',
      color: 'FF538DD5'
    }
  },
  alignment: {
    horizontal: 'left',
    vertical: 'center',
    wrapText: true
  }
};

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  
  constructor() { }
  
  private columnNumberToColumnName(columnNumber: number): string {
    let dividend = columnNumber;
    let columnName = "";
    let modulo;
    while (dividend > 0) {
      modulo = dividend % 26;
      if (modulo === 0) {
      	columnName = 'Z' + columnName;
        dividend = ((dividend - modulo) / 26 - 1);
      }
      else {
      	columnName = String.fromCharCode(64 + modulo) + columnName;
        dividend = (dividend - modulo) / 26;
      }
    }
    return columnName;
  }
  
  public exportAsExcelFile(DoExcelExport: IDoExcelExport): void {
    const worksheets: any = {};
    const worksheetsNames: string[] = [];
    DoExcelExport.worksheets.forEach((worksheet: IDoExcelExportWorksheet, sheetIndex: number) => {
      let ws = XLSX.utils.json_to_sheet(worksheet.data);
      let rows = [{}];
      for (let i = 0; i <= worksheet.data.length; i++) {
        let cols = [];
        let dataIndex = 0;
        if (i > 0) {
          dataIndex = i - 1;
        }
        for (let x = 0; x < Object.entries(worksheet.data[dataIndex]).length; x++) {
          if (sheetIndex === 0) {
            ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].s = (i === 0) ? HEADER_STYLE : VEHICLE_ROW_STYLE;
            if (i === 1 && x === 6) {
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].z = '#,##0';
            }
            else if (i === 1 && x === 7) {
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].z = '0000';
            }
            cols[x] = {
              width: 25
            };
          }
          else {
            if (x === 0) {
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].s = (i === 0) ? HEADER_STYLE : ROW_STYLE;
              cols[x] = {
                width: 25
              };
            }
            else if (x === 1 || x === 3) {
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].s = (i === 0) ? HEADER_STYLE : VEHICLE_ROW_STYLE;
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].z = '$ #,##0.00';
              XLSX.utils.format_cell(ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`]);
              cols[x] = {
                width: 15
              };
            }
            else {
              ws[`${this.columnNumberToColumnName(x + 1)}${i + 1}`].s = (i === 0) ? HEADER_STYLE : ROW_STYLE;
              cols[x] = {
                width: 75
              };
            }
          }
        }
        ws['!cols'] = cols;
        if (sheetIndex === 0) {
          rows[i + 1] = {
            hpt: 50
          };
        }
        else {
          rows[i + 1] = {
            hpt: 150
          };
        }
      }
      ws['!rows'] = rows;
      worksheets[worksheet.name] = ws;
      worksheetsNames.push(worksheet.name);
    });
    const workbook: XLSX.WorkBook = { Sheets: worksheets, SheetNames: worksheetsNames };
    const excelBuffer: any = XLSXStyle.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, DoExcelExport.name);
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  
}
