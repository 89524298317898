export class ClientDataModel {
  public lastName?: string;
  public name?: string;
  public businessName?: string;
  public cuit?: string;
  public citizenshipCode?: string;
  public maritalStatusCode?: string;
  public activityCode?: string;
  public societyTypeCode?: string;

  constructor(name?: string, activityCode?: string, lastName?: string, cuit?: string, citizenshipCode?: string, maritalStatusCode?: string,
              societyTypeCode?: string, businessName?: string) {
    this.lastName = lastName;
    this.name = name;
    this.cuit = cuit;
    this.citizenshipCode = citizenshipCode;
    this.maritalStatusCode = maritalStatusCode;
    this.activityCode = activityCode;
    this.societyTypeCode = societyTypeCode;
    this.businessName = businessName;
  }
}
