// Core.
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
// Shared.
import { EnvironmentManager } from './shared/environment-manager.shared';
import { SimpleLogger } from './shared/simple-logger.shared';

declare var gtag;

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
_LOGGER.setLogLevel(_CONFIG.logLevel);

const _TAG = 'AppComponent';
_LOGGER.debug(_TAG, 'loaded.');


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(router:Router){
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event:NavigationEnd) => {
      gtag('config', 'G-Q43VQLJQFD', { 'page_path': event.urlAfterRedirects});
    });
  }
}
