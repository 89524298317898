// Core.
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'ErrorModalComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Error Modal component.
 */
@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @Input() message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
  }


  /* Handlers. */

  /**
   * Closes modal.
   */
  protected onAccept(): void {
    this.activeModal.close('Accept');
  }
}
