// Models.
import { PostalCodeModel } from './postal-code.model';


export class LocationModel {
  public code: string;
  public name: string;
  public postalCodes: PostalCodeModel[];

  constructor(code: string, name: string, postalCodes: PostalCodeModel[] = []) {
    this.code = code;
    this.name = name;
    this.postalCodes = postalCodes;
  }
}
