// Core.
import { catchError, map, publishReplay, refCount, tap } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// Models & Interfaces.
import { BaseAppError } from '../error/base-app-error';
import { IGetLocationsByProvinceResponseLocationItem } from './i-get-locations-by-province-response-location-item';
import { IGetProvincesResponseProvinceItem } from './i-get-provinces-response-province-item';
import { IGetChannelProvinceResponseItem } from './i-get-channel-province-response-item';
import { LocationModel } from '../../models/location.model';
import { PostalCodeModel } from '../../models/postal-code.model';
import { ProvinceModel } from '../../models/province.model';
import { ChannelProvinceModel } from '../../models/channel-province.model';
// Services.
import { ErrorService } from '../error/error.service';
import { PersistenceService } from '../persistence/persistence.service';
// Shared.
import { EnvironmentManager } from '../../shared/environment-manager.shared';
import { SimpleLogger } from '../../shared/simple-logger.shared';


const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'GeoService';
_LOGGER.debug(_TAG, 'loaded.');

const _CACHE_CONFIG = {
  GET_PROVINCES: {
    KEY: 'get_provinces',
    AGE: 20 * 60 * 1000 // 20m * 60s * 1000ms.
  },
  GET_LOCATIONS: {
    KEY: 'get_locations',
    AGE: 10 * 60 * 1000 // 20m * 60s * 1000ms.
  }
};


/**
 * Geographical Data Service (Provinces, Locations, Postal Codes).
 */
@Injectable({
  providedIn: 'root'
})
export class GeoService {
  private baseURL = `${_CONFIG.apiBaseURL}/location`;

  private cacheGetProvinces: Observable<ProvinceModel[] | BaseAppError>;
  private cacheGetLocations: { [key: string]: Observable<LocationModel[] | BaseAppError> } = {};
  private channelProvinceData: Observable<ChannelProvinceModel | BaseAppError>;
  private channelProvince: ChannelProvinceModel;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private persistenceService: PersistenceService
  ) {
  }

  /**
   * Gets all the available Provinces.
   */
  public getProvinces(): Observable<ProvinceModel[] | BaseAppError> {
    const __SUBTAG = 'getProvinces';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetProvincesResponseProvinceItem[]) => ProvinceModel[] = (data) => data
      .map(prov => new ProvinceModel(prov.code, prov.descripcion));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_PROVINCES.KEY, _CACHE_CONFIG.GET_PROVINCES.AGE)) {
      const endpointURL = `${this.baseURL}/provincias`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetProvinces = this.http.get<IGetProvincesResponseProvinceItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetProvincesResponseProvinceItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_PROVINCES.KEY, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetProvincesResponseProvinceItem[]>) => parseData(response.body)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      if (!this.cacheGetProvinces) {
        const cacheData: IGetProvincesResponseProvinceItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_PROVINCES.KEY,
          _CACHE_CONFIG.GET_PROVINCES.AGE);
        this.cacheGetProvinces = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetProvincesResponseProvinceItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetProvincesResponseProvinceItem[]) => parseData(data)),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      }
    }

    return this.cacheGetProvinces;
  }

  /**
   * Gets Locations for a given Province.
   *
   * @param province    The Province to search for Locations.
   */
  public getLocationsByProvince(province: ProvinceModel): Observable<LocationModel[] | BaseAppError> {
    const __SUBTAG = 'getLocationsByProvince';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetLocationsByProvinceResponseLocationItem[]) => LocationModel[] = (data) => data
      .map(loc => new LocationModel(loc.code, loc.descripcion, (loc.cps || [])
        .map(cp => new PostalCodeModel(cp))));

    const __CACHE_KEY_SUFFIX = `_${province.code}`;

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_LOCATIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_LOCATIONS.AGE)) {
      const endpointURL = `${this.baseURL}/localidades/${province.code}`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetLocations[__CACHE_KEY_SUFFIX] = this.http
        .get<IGetLocationsByProvinceResponseLocationItem[]>(endpointURL, {observe: 'response'})
        .pipe(
          // Log operation & store response in cache.
          tap((response: HttpResponse<IGetLocationsByProvinceResponseLocationItem[]>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            PersistenceService.store(_CACHE_CONFIG.GET_LOCATIONS.KEY + __CACHE_KEY_SUFFIX, response.body);
          }),
          // Parse response.
          map((response: HttpResponse<IGetLocationsByProvinceResponseLocationItem[]>) => parseData(response.body)),
          // Store data in model.
          tap((data: LocationModel[]) => {
            province.locations = data;
          }),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    } else {
      const cacheData: IGetLocationsByProvinceResponseLocationItem[] = PersistenceService.retrieve(
        _CACHE_CONFIG.GET_LOCATIONS.KEY + __CACHE_KEY_SUFFIX, _CACHE_CONFIG.GET_LOCATIONS.AGE);
      if (!this.cacheGetLocations[__CACHE_KEY_SUFFIX]) {
        this.cacheGetLocations[__CACHE_KEY_SUFFIX] = of(cacheData)
          .pipe(
            // Log operation.
            tap((data: IGetLocationsByProvinceResponseLocationItem[]) => {
              _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
            }),
            // Parse response.
            map((data: IGetLocationsByProvinceResponseLocationItem[]) => parseData(data)),
            // Store data in model.
            tap((data: LocationModel[]) => {
              province.locations = data;
            }),
            // Replay last response.
            publishReplay(1),
            refCount(),
            // Error handler.
            catchError(error => of(this.errorService.getAppError(error)))
          );
      } else if (!province.locations || !province.locations.length) {
        province.locations = parseData(cacheData);
      }
    }

    return this.cacheGetLocations[__CACHE_KEY_SUFFIX];
  }
  
  public enableDeclaredValueInput(provinceId: string): Observable<ChannelProvinceModel | BaseAppError> {
    const __SUBTAG = 'enableDeclaredValueInput';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

      const endpointURL = `${this.baseURL}/inputValorAuto/${provinceId}`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.channelProvinceData = this.http.get<IGetChannelProvinceResponseItem>(endpointURL, {observe: 'response'})
      .pipe(
          // Log operation & store response
          tap((response: HttpResponse<IGetChannelProvinceResponseItem>) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
            this.channelProvince = response.body;
          }),
          // Parse response.
          map((response: HttpResponse<IGetChannelProvinceResponseItem>) => new ChannelProvinceModel(
            response.body.id, 
            response.body.channelId, 
            response.body.channelDescription, 
            response.body.provinceId,
            response.body.provinceDescription, 
            response.body.enableCarValue, 
            response.body.minPorcentualValue,
            response.body.maxPorcentualValue)),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
    return this.channelProvinceData;
  }
}
