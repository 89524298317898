// Core.
import { Component, Input, OnInit } from '@angular/core';
// Shared.
import { SimpleLogger } from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'WorkflowComponent';
_LOGGER.debug(_TAG, 'loaded.');


interface IWorkflowStep {
  index: number;
  title: string;
  subtitle: string;
  active: boolean;
  completed: boolean;
}


/**
 * Workflow component to place top step-by-step process views.
 */
@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
  @Input()
  protected workflowSteps: IWorkflowStep[];

  constructor() {
  }

  ngOnInit() {
  }
}
