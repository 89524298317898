// Modules.
import {AutocompleteModule} from 'ng2-input-autocomplete';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InsuranceRoutingModule} from './insurance-routing.module';
import {MainModule} from '../main/main.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
// Components.
import {AboutYouPageComponent} from './pages/about-you-page/about-you-page.component';
import {AddressDataPageComponent} from './pages/address-data-page/address-data-page.component';
import {AlertModalComponent} from '../main/components/alert-modal/alert-modal.component';
import {ConclusionPageComponent} from './pages/conclusion-page/conclusion-page.component';
import {ContactDataPageComponent} from './pages/contact-data-page/contact-data-page.component';
import {ContinueByPhonePageComponent} from './pages/continue-by-phone-page/continue-by-phone-page.component';
import {ErrorModalComponent} from '../main/components/error-modal/error-modal.component';
import {LoadingModalComponent} from '../main/components/loading-modal/loading-modal.component';
import {MoreAboutYouPageComponent} from './pages/more-about-you-page/more-about-you-page.component';
import {MoreVehicleDataPageComponent} from './pages/more-vehicle-data-page/more-vehicle-data-page.component';
import {PaymentMethodAutomaticDebitPageComponent} from './pages/payment-method-automatic-debit-page/payment-method-automatic-debit-page.component'; // tslint:disable-line:max-line-length
import {PaymentMethodCreditCardPageComponent} from './pages/payment-method-credit-card-page/payment-method-credit-card-page.component';
import {PickBrokerPageComponent} from './pages/pick-broker-page/pick-broker-page.component';
import {PickFamilyPageComponent} from './pages/pick-family-page/pick-family-page.component';
import {PickPaymentMethodPageComponent} from './pages/pick-payment-method-page/pick-payment-method-page.component';
import {ShareInsurancePageComponent} from './pages/share-insurance-page/share-insurance-page.component';
import {SuccesModalComponent} from '../main/components/succes-modal/succes-modal.component';
import {VehicleDataPageComponent} from './pages/vehicle-data-page/vehicle-data-page.component';
// Shared.
import {SimpleLogger} from '../../shared/simple-logger.shared';
import {HowToContinuePublicAccessComponent} from './components/how-to-continue-public-access/how-to-continue-public-access.component';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'InsuranceModule';
_LOGGER.debug(_TAG, 'loaded.');


@NgModule({
  declarations: [
    AboutYouPageComponent,
    AddressDataPageComponent,
    ConclusionPageComponent,
    ContactDataPageComponent,
    ContinueByPhonePageComponent,
    MoreAboutYouPageComponent,
    MoreVehicleDataPageComponent,
    PaymentMethodAutomaticDebitPageComponent,
    PaymentMethodCreditCardPageComponent,
    PickBrokerPageComponent,
    PickFamilyPageComponent,
    PickPaymentMethodPageComponent,
    ShareInsurancePageComponent,
    VehicleDataPageComponent,
    HowToContinuePublicAccessComponent
  ],
  imports: [
    // Core.
    BrowserModule,
    CommonModule,
    FormsModule,
    NgbModule,
    AutocompleteModule.forRoot(),
    // Custom.
    MainModule,
    InsuranceRoutingModule
  ],
  entryComponents: [
    AlertModalComponent,
    ErrorModalComponent,
    LoadingModalComponent,
    SuccesModalComponent,
    HowToContinuePublicAccessComponent
  ]
})
export class InsuranceModule {
}
