import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {SimpleLogger} from '../../shared/simple-logger.shared';

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'NoPublicAccessGuard';
_LOGGER.debug(_TAG, 'loaded.');

@Injectable({providedIn: 'root'})
export class NoPublicAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const __SUBTAG = 'canActivate';
    _LOGGER.info(_TAG, __SUBTAG, 'called.', state);

    if (!this.userService.isUserPublicAccess()) {
      return true;
    }

    this.router.navigate(['/home'], {queryParams: state.root.queryParams});
    return false;
  }
}
