// Modules.
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Shared.
import { SimpleLogger } from './shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'AppRoutingModule';
_LOGGER.debug(_TAG, 'loaded.');


const _ROUTES: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home', pathMatch: 'full'} // FIXME: add 404.
];


@NgModule({
  imports: [RouterModule.forRoot(_ROUTES, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
