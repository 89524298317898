// Core.
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {ViewportScroller} from '@angular/common';
// Models & Interfaces.
import {BankAccountTypeModel} from '../../../../models/bank-account-type.model';
import {BankModel} from '../../../../models/bank.model';
import {BaseAppError} from '../../../../services/error/base-app-error';
import {IGetPaymentInputsResponse} from '../../../../services/payment/i-get-payment-inputs-response';
// Services.
import {CompraService} from '../../../../services/compra/compra.service';
import {CotizadorService} from '../../../../services/cotizador/cotizador.service';
import {ErrorService} from '../../../../services/error/error.service';
import {ModalsService} from '../../../../services/modals/modals.service';
import {PaymentService} from '../../../../services/payment/payment.service';
import {SessionService} from '../../../../services/session/session.service';
// Shared.
import {Helper} from '../../../../shared/helper.shared';
import {SimpleLogger} from '../../../../shared/simple-logger.shared';


const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'PaymentMethodAutomaticDebitPageComponent';
_LOGGER.debug(_TAG, 'loaded.');


/**
 * Payment Method - Automatic Debit page.
 */
@Component({
  selector: 'app-payment-method-automatic-debit-page',
  templateUrl: './payment-method-automatic-debit-page.component.html',
  styleUrls: ['./payment-method-automatic-debit-page.component.scss']
})
export class PaymentMethodAutomaticDebitPageComponent implements OnInit {
  /* Form controls. */
  @ViewChild('automaticDebitForm') public automaticDebitForm: NgForm;
  @ViewChild('inputAccountCbu') public inputAccountCbu: ElementRef;
  public disableForm: boolean;
  public banks: BankModel[];
  public bank: BankModel;
  public accountTypes: BankAccountTypeModel[];
  public loadingPaymentInputs: boolean;
  public paymentInputs: IGetPaymentInputsResponse;
  public accountType: BankAccountTypeModel;
  public accountNumber: string;
  public accountCbu: string;
  public loadingBanks: boolean;
  public loadingAccountTypes: boolean;

  private changedAccountNumber: boolean;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private cotizadorService: CotizadorService,
    private sessionService: SessionService,
    private paymentService: PaymentService,
    private modalsService: ModalsService,
    private errorService: ErrorService,
    private compraService: CompraService
  ) {
  }

  /**
   * Initializes form data.
   */
  private formInit(): void {
    const __SUBTAG = 'formInit';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    this.disableForm = true;

    Promise.all([
      // Get Banks.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingBanks = true;
          this.paymentService.getBanks().subscribe((resultOrError: BankModel[] | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getBanks resulted in error.');
              reject(resultOrError);
              return;
            }

            // Succeed.
            if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Banks.');
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Banks initialized.');
              this.banks = resultOrError;
              this.orderByNameBank();
              resolve(this.banks);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getBanks.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Banks.', e);
          reject(e);
        } finally {
          this.loadingBanks = false;
        }
      }),

      // Get Bank Account Types.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingAccountTypes = true;
          this.paymentService.getBankAccountTypes().subscribe((resultOrError: BankAccountTypeModel[] | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getBankAccountTypes resulted in error.');
              reject(resultOrError);
              return;
            }

            // Succeed.
            if (!resultOrError || !resultOrError.length || (resultOrError.length < 1)) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Bank Account Types.');
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Bank Account Types initialized.');
              this.accountTypes = resultOrError;
              resolve(this.accountTypes);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getBankAccountTypes.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Bank Account Types.', e);
          reject(e);
        } finally {
          this.loadingAccountTypes = false;
        }
      }),

      // Get Payment Inputs.
      new Promise((resolve: (value?: any) => void, reject: (reason?: any) => void) => {
        try {
          this.loadingPaymentInputs = true;
          this.paymentInputs = null;
          this.paymentService.getPaymentInputs().subscribe((resultOrError: IGetPaymentInputsResponse | BaseAppError) => {
            // Failed.
            if (resultOrError instanceof BaseAppError) {
              _LOGGER.error(_TAG, __SUBTAG, 'getPaymentInputs resulted in error.');
              reject(resultOrError);
              return;
            }
            // Succeed.
            if (!resultOrError) {
              _LOGGER.error(_TAG, __SUBTAG, 'Cannot get Payment Inputs.');
              reject(new Error('Ha ocurrido un error durante la inicialización del formulario.'));
            } else {
              _LOGGER.debug(_TAG, __SUBTAG, 'Payment Inputs initialized.');
              this.paymentInputs = resultOrError;
              resolve(this.paymentInputs);
            }
          }, (error: any) => {
            _LOGGER.error(_TAG, __SUBTAG, 'Error on getPaymentInputs.');
            reject(error);
          });
        } catch (e) {
          _LOGGER.error(_TAG, __SUBTAG, 'Error while initializing Payment Inputs.', e);
          reject(e);
        } finally {
          this.loadingPaymentInputs = false;
        }
      })
    ]).then(() => {
      _LOGGER.debug(_TAG, __SUBTAG, 'Init completed.');
      this.formReset();
    }).catch(error => {
      _LOGGER.debug(_TAG, __SUBTAG, 'Init error.');
      this.onError(error);
    });
  }

  /**
   * Resets the form.
   */
  private formReset(): void {
    const __SUBTAG = 'formReset';
    _LOGGER.info(_TAG, __SUBTAG, 'Start.');

    if (this.automaticDebitForm && this.automaticDebitForm.pristine) {
      this.automaticDebitForm.control.reset();
    }

    this.bank = null;
    this.onChangeBank();
    this.accountType = null;
    this.onChangeAccountType();
    this.accountNumber = null;
    this.onChangeAccountNumber();
    this.changedAccountNumber = false;
    this.accountCbu = null;
    this.onChangeAccountCbu();

    this.disableForm = false;
  }


  /* Handlers. */

  /**
   * Angular component OnInit event handler.
   * Checks step #2-3 data.
   * Navs back if there is missing data.
   */
  public ngOnInit(): void {
    if (CotizadorService.isInitProcessCompleted()) {
      if (this.sessionService.getBuyInsuranceStep3MoreVehicleData()) {
        this.formInit();
        return;
      }
    }
    this.onStepBack();
  }


  /**
   * Handles errors on this view.
   * Opens the Error Modal or navs to Error Landing, according to the error code got.
   * Stays on this page on specific errors.
   * Resets session & Cotizador data before nav.
   */
  private onError(error: any): void {
    const __SUBTAG = 'onError';
    const appError: BaseAppError = this.errorService.getAppError(error);
    _LOGGER.error(_TAG, __SUBTAG, 'Error:', appError.getMessage());

    if (appError.getCode() === ErrorService.getApiHttpErrorCode(401)) {
      this.sessionService.reset();
      CotizadorService.doReset();
      this.router.navigate(['/error'], {queryParams: {e: encodeURIComponent(btoa(JSON.stringify(appError)))}});
      return;
    }

    this.modalsService.showErrorModal(appError.getMessage()).then(modal => {
      modal.result.then(result => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal closed with result:', result);
      }).catch(reason => {
        _LOGGER.debug(_TAG, __SUBTAG, 'Error modal dismissed with reason:', reason);
      }).finally(() => {
        this.viewportScroller.scrollToAnchor('app-main-header');
      });
    });
  }

  /**
   * Navs back to Pick Payment Method page.
   */
  public onStepBack(): void {
    this.router.navigate(['/insurance/pick-payment-method']);
  }

  /**
   * Navs to Conclusion page.
   */
  public onConfirm(): void {
    const __SUBTAG = 'onConfirm';
    this.disableForm = true;
    const loadingModal = this.modalsService.showLoadingModal();
    this.compraService.doPagarWithAutomaticDebit(
      this.paymentInputs.bank ? this.bank.code : null,
      this.paymentInputs.cbu ? this.accountCbu : null,
      this.paymentInputs.accountNumber ? this.accountNumber : null,
      this.paymentInputs.accountType ? this.accountType.code : null
    ).subscribe((resultOrError: boolean | BaseAppError) => {
      // Failed.
      if (resultOrError instanceof BaseAppError) {
        _LOGGER.error(_TAG, __SUBTAG, 'CompraService doPagar resulted in error.');
        this.onError(resultOrError);
        return;
      }
      // Succeed.
      if (!resultOrError) {
        _LOGGER.error(_TAG, __SUBTAG, 'Cannot initialize CompraService.');
        this.onError(new Error('Ha ocurrido un error durante el proceso de compra.'));
      } else {
        _LOGGER.debug(_TAG, __SUBTAG, 'CompraService doPagar ended.');
        this.router.navigate(['/insurance/conclusion']);
      }
    }, (error: any) => {
      _LOGGER.error(_TAG, __SUBTAG, 'Error on CompraService doPagar.');
      this.onError(error);
    }, () => {
      loadingModal.then(modal => modal.close());
      this.disableForm = false;
    });
  }

  /**
   * User changes Bank data handler.
   */
  public onChangeBank(): void {
    // No action needed.
  }

  /**
   * User changes Bank Account Type data handler.
   */
  public onChangeAccountType(): void {
    // No action needed.
  }

  /**
   * User changes Bank Account Number data handler.
   */
  public onChangeAccountNumber(): void {
    this.changedAccountNumber = true;
  }

  /**
   * Bank Account Number blur handler.
   */
  public onBlurAccountNumber(): void {
    if (this.accountNumber && this.changedAccountNumber) {
      this.changedAccountNumber = false;
      setTimeout(() => {
        if (this.inputAccountCbu && this.inputAccountCbu.nativeElement &&
          this.inputAccountCbu.nativeElement.focus) {
          this.inputAccountCbu.nativeElement.focus();
        }
      }, 10);
    }
  }

  /**
   * Bank Account Number input keydown handler.
   * Triggers Blur on same input.
   */
  public onKeyDownPersonAccountNumber(event: KeyboardEvent): void {
    if (Helper.getInstance().isEnterKeyPress(event)) {
      this.onBlurAccountNumber();
    }
  }

  /**
   * User changes Bank Account CBU data handler.
   */
  public onChangeAccountCbu(): void {
    // No action needed.
  }

  /**
   * Order array bank by name
   */
  public orderByNameBank(): void {
    this.banks.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  }

  public isAccountCbuValid(): boolean {
    if (!!this.paymentInputs && !!this.paymentInputs.cbu && this.bank && this.accountCbu && (this.accountCbu.length === 22)) {
      return (this.bank.code.substring(2, 5) === this.accountCbu.substring(0, 3));
    } else {
      return true;
    }
  }
}
