const ENTER_CODE = 'Enter';
const ENTER_NUMPAD_CODE = 'NumpadEnter';


/**
 * <Singleton>
 */
export class Helper {
  private static instance: Helper = new Helper();

  private constructor() {
  }

  public static getInstance(): Helper {
    if (!Helper.instance) {
      Helper.instance = new Helper();
    }
    return Helper.instance;
  }

  /**
   * Checks if two objects are equals by checking both for equal properties.
   *
   * @param a       Object "A" to check.
   * @param b       Object "B" to check.
   * @param props   Array of properties to check in both objects.
   */
  public equalsByProps(a: any, b: any, props: string[]): boolean {
    try {
      return (a === b) || !props.some((p: string) => (a[p] !== b[p]));
    } catch (e) {
      return false;
    }
  }

  /**
   * Returns TRUE if an event corresponds to key ENTER being pressed.
   *
   * @param e   Event to check.
   */
  public isEnterKeyPress(e: KeyboardEvent): boolean {
    return ((e.code === ENTER_CODE) || (e.key === ENTER_CODE) ||
      (e.code === ENTER_NUMPAD_CODE) || (e.key === ENTER_NUMPAD_CODE) ||
      (e.keyCode === 13));
  }

  /**
   * Returns a sanitized alphabetic string (only letters and spaces).
   */
  public sanitizeInputAlphabetic(inputString: string): string {
    if (!inputString) {
      return inputString;
    }
    return ('' + inputString).replace(/[^\w\sñÑ]/g, '');
  }
}
