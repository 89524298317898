// Core.
import * as moment from 'moment';
import {catchError, map, publishReplay, refCount, tap} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
// Models & Interfaces.
import {BaseAppError} from '../error/base-app-error';
import {BrokerInsuranceQuoteOptionModel} from '../../models/broker-insurance-quote-option.model';
import {BrokerModel} from '../../models/broker.model';
import {BrokerInsuranceQuoteModel} from '../../models/broker-insurance-quote.model';
import {IDoCotizadorInitResponse} from './i-do-cotizador-init-response';
import {IDoCotizadorMailResponse} from './i-do-cotizador-mail-response';
import {IDoCotizarByBrokerResponse} from './i-do-cotizar-by-broker-response';
import {IGetBrokersResponseBrokerItem} from './i-get-brokers-response-broker-item';
import {IGetCotizadorInsuranceValidityDateInfoResponse} from './i-get-cotizador-insurance-validity-date-info-response';
// Services.
import {DatePickerUtilsService} from '../date-picker-utils.service';
import {ErrorService} from '../error/error.service';
import {PersistenceService} from '../persistence/persistence.service';
import {SessionService} from '../session/session.service';
// Shared.
import {EnvironmentManager} from '../../shared/environment-manager.shared';
import {SimpleLogger} from '../../shared/simple-logger.shared';
// Components
import {GetPublicAccessInfoResponseTimeSlot} from '../public-access/get-public-access-info-response';
import {PublicAccessContinueByMethod} from '../public-access/public-access-continue-by-method';

const _CONFIG = EnvironmentManager.getInstance().getConfig();

const _LOGGER: SimpleLogger = SimpleLogger.getInstance();
const _TAG = 'CotizadorService';
_LOGGER.debug(_TAG, 'loaded.');


const _CACHE_CONFIG = {
  GET_BROKERS: {
    KEY: 'get_brokers',
    AGE: 60 * 1000 // 60s * 1000ms.
  }
};


/**
 * Cotizador Service.
 */
@Injectable({
  providedIn: 'root'
})
export class CotizadorService {
  private static initCompleted: boolean;
  private static insuranceQuoteTrackID: string;
  private static insuranceQuoteMainId: number;
  private static insuranceQuoteOriginDealer: string;
  private static insuranceQuoteDate: Date;
  private static insuranceLawText: string;
  private static insuranceQuoteChannel: string;
  private static memCacheBrokerInsuranceQuotes: { [key: string]: BrokerInsuranceQuoteModel };
  private baseURL = `${_CONFIG.apiBaseURL}/cotizador`;


  private cacheGetBrokers: Observable<BrokerModel[] | BaseAppError>;

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private sessionService: SessionService,
    private datePickerUtilsService: DatePickerUtilsService
  ) {
    CotizadorService.doReset(); // Singleton.
  }

  public static isInitProcessCompleted(): boolean {
    return CotizadorService.initCompleted;
  }

  private static setInsuranceQuoteTrackId(trackId: string): void {
    CotizadorService.insuranceQuoteTrackID = trackId;
  }

  public static getInsuranceQuoteTrackId(): string {
    return CotizadorService.insuranceQuoteTrackID;
  }

  private static setInsuranceQuoteMainId(mainId: number): void {
    CotizadorService.insuranceQuoteMainId = mainId;
  }

  public static getInsuranceQuoteMainId(): number {
    return CotizadorService.insuranceQuoteMainId;
  }

  private static setInsuranceQuoteOriginDealer(dealer: string): void {
    CotizadorService.insuranceQuoteOriginDealer = dealer;
  }

  public static getInsuranceQuoteOriginDealer(): string {
    return CotizadorService.insuranceQuoteOriginDealer;
  }

  private static setInsuranceQuoteDate(quoteDate: Date): void {
    CotizadorService.insuranceQuoteDate = quoteDate;
  }

  public static getInsuranceQuoteDate(): Date {
    return CotizadorService.insuranceQuoteDate;
  }

  private static setInsuranceLawText(lawText: string): void {
    CotizadorService.insuranceLawText = lawText;
  }

  public static getInsuranceLawText(): string {
    return CotizadorService.insuranceLawText;
  }

  public static setInsuranceQuoteChannel(channel: string): void {
    CotizadorService.insuranceQuoteChannel = channel;
  }

  public static getInsuranceQuoteChannel(): string {
    return CotizadorService.insuranceQuoteChannel;
  }

  /**
   * Resets Cotizador (Init state, Track-ID).
   */
  public static doReset(): void {
    const __SUBTAG = 'doReset';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');
    CotizadorService.initCompleted = false;
    CotizadorService.setInsuranceQuoteTrackId(null);
    CotizadorService.setInsuranceQuoteMainId(null);
    CotizadorService.setInsuranceQuoteOriginDealer(null);
    CotizadorService.setInsuranceQuoteDate(null);
    CotizadorService.setInsuranceLawText(null);
    CotizadorService.setInsuranceQuoteChannel(null);
    CotizadorService.memCacheBrokerInsuranceQuotes = {};
  }

  /**
   * Do Cotizador Init. Inits insurance quote.
   */
  public doInit(recaptchaToken: string): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doInit';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (CotizadorService.isInitProcessCompleted()) {
      CotizadorService.doReset();
    }

    const vehicleData = this.sessionService.getQuoteInsuranceStep1VehicleData();
    const vehicleDataStep2 = this.sessionService.getVehicleStep2Data();
    const aboutYouData = this.sessionService.getQuoteInsuranceStep2AboutYou();

    const endpointURL = `${this.baseURL}/init`;
    const payload = {
      auto: {
        anio: vehicleData.vehicleYear.year,
        gncCode: vehicleData.vehicleHasGnc ? vehicleData.vehicleGncType.code : null,
        gncValor: vehicleData.vehicleHasGnc ? vehicleData.vehicleGncValue : null,
        is0KM: vehicleData.vehicleYear.brandNew,
        lugar: {
          codigoPostal: aboutYouData.vehiclePostalCode.code,
          localidad: aboutYouData.vehicleLocation.name,
          localidadCode: aboutYouData.vehicleLocation.code,
          provincia: aboutYouData.vehicleProvince.name,
          provinciaCode: aboutYouData.vehicleProvince.code
        },
        marca: vehicleData.vehicleBrand.name,
        modelo: vehicleData.vehicleModel.name,
        tieneGNC: vehicleData.vehicleHasGnc,
        usoCode: vehicleData.vehicleUseType.code,
        vehiculoInfoautoId: vehicleData.vehicleVersion.idInfoAuto,
        version: vehicleData.vehicleVersion.name,
        valorDeclarado: vehicleDataStep2.declaredValue,
        valorReferenciaInfoAuto: vehicleDataStep2.referenceValueFromInfoAuto,
      },
      persona: {
        celular: aboutYouData.personCellphone,
        condicionImpositivaCode: aboutYouData.personTaxCondition.code,
        email: aboutYouData.personEmail,
        fechaNacimiento: aboutYouData.personTypeIsIndividual ? moment(aboutYouData.personBirthDate).format('DD/MM/YYYY') : '',
        nroDocumento: aboutYouData.personDocument,
        sexo: aboutYouData.personTypeIsIndividual ? aboutYouData.personGender : null,
        tipoDocumentoCode: aboutYouData.personDocumentType.code,
        tipoPersona: aboutYouData.personTypeIsIndividual ? 'INDIVIDUO' : 'EMPRESA'
      },
      fechaCotizacion: !!vehicleData.insuranceValidityDateSince ?
        this.datePickerUtilsService.dateToString(vehicleData.insuranceValidityDateSince) : null,
      captchaToken: recaptchaToken
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCotizadorInitResponse>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IDoCotizadorInitResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      // Save Insurance Quota Track ID.
      // Return success flag.
      map((response: HttpResponse<IDoCotizadorInitResponse>) => {
        CotizadorService.initCompleted = response.ok;
        if (CotizadorService.isInitProcessCompleted()) {
          CotizadorService.setInsuranceQuoteTrackId(response.body.trackId);
          CotizadorService.setInsuranceQuoteMainId(response.body.cotizacionId);
          CotizadorService.setInsuranceQuoteOriginDealer(response.body.razonSocialConcesionario);
          CotizadorService.setInsuranceQuoteDate(moment(response.body.fechaCotizacion, 'DD/MM/YYYY').toDate());
          CotizadorService.setInsuranceLawText(response.body.legales);
          CotizadorService.setInsuranceQuoteChannel(response.body.canalCotizacion);
        }
        return CotizadorService.isInitProcessCompleted();
      }),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Gets the available Brokers.
   */
  public getBrokers(): Observable<BrokerModel[] | BaseAppError> {
    const __SUBTAG = 'getBrokers';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const parseData: (data: IGetBrokersResponseBrokerItem[]) => BrokerModel[] = (data) => data
      .map(broker => new BrokerModel(broker.code, broker.descripcion, broker.mensajePagoEfectivo));

    // Check cache.
    if (PersistenceService.isExpiredOrEmpty(_CACHE_CONFIG.GET_BROKERS.KEY, _CACHE_CONFIG.GET_BROKERS.AGE)) {
      const endpointURL = `${this.baseURL}/companias`;
      _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

      this.cacheGetBrokers = this.http.get<IGetBrokersResponseBrokerItem[]>(endpointURL, {observe: 'response'}).pipe(
        // Log operation & store response in cache.
        tap((response: HttpResponse<IGetBrokersResponseBrokerItem[]>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
          PersistenceService.store(_CACHE_CONFIG.GET_BROKERS.KEY, response.body);
        }),
        // Parse response.
        map((response: HttpResponse<IGetBrokersResponseBrokerItem[]>) => parseData(response.body)),
        // Replay last response.
        publishReplay(1),
        refCount(),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
    } else {
      if (!this.cacheGetBrokers) {
        const cacheData: IGetBrokersResponseBrokerItem[] = PersistenceService.retrieve(_CACHE_CONFIG.GET_BROKERS.KEY,
          _CACHE_CONFIG.GET_BROKERS.AGE);
        this.cacheGetBrokers = of(cacheData).pipe(
          // Log operation.
          tap((data: IGetBrokersResponseBrokerItem[]) => {
            _LOGGER.debug(_TAG, __SUBTAG, 'cache response:', data);
          }),
          // Parse response.
          map((data: IGetBrokersResponseBrokerItem[]) => parseData(data)),
          // Replay last response.
          publishReplay(1),
          refCount(),
          // Error handler.
          catchError(error => of(this.errorService.getAppError(error)))
        );
      }
    }

    return this.cacheGetBrokers;
  }

  /**
   * Gets the Insurance Quote data of a given Broker.
   */
  public doCotizarByBroker(broker: BrokerModel): Observable<BrokerInsuranceQuoteModel | BaseAppError> {
    const __SUBTAG = 'doCotizarByBroker';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const cacheData = CotizadorService.memCacheBrokerInsuranceQuotes[broker.code];
    if (cacheData) {
      _LOGGER.debug(_TAG, __SUBTAG, 'memCache response:', cacheData);
      broker.insuranceQuote = cacheData;
      return of(cacheData);
    }

    const endpointURL = `${this.baseURL}/cotizar/${broker.code}`;
    const payload = {
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCotizarByBrokerResponse>(endpointURL, payload, {observe: 'response'}).pipe(
      // Log operation.
      tap((response: HttpResponse<IDoCotizarByBrokerResponse>) => {
        _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
      }),
      // Parse response.
      map((response: HttpResponse<IDoCotizarByBrokerResponse>) => new BrokerInsuranceQuoteModel(
        response.body.externalCotizacionId, moment(response.body.fecha, 'dd/mm/yyyy').toDate(), response.body.montoAsegurado,
        response.body.tercerosDesde, response.body.tercerosCompletoDesde, response.body.todoRiesgoDesde,
        (response.body.terceros || [])
          .map(o => new BrokerInsuranceQuoteOptionModel(o.externalCoberturaId, o.nombre, o.bullets, o.importe, o.aceptaPagoEfectivo)),
        (response.body.tercerosCompleto || [])
          .map(o => new BrokerInsuranceQuoteOptionModel(o.externalCoberturaId, o.nombre, o.bullets, o.importe, o.aceptaPagoEfectivo)),
        (response.body.todoRiesgo || [])
          .map(o => new BrokerInsuranceQuoteOptionModel(o.externalCoberturaId, o.nombre, o.bullets, o.importe, o.aceptaPagoEfectivo)),
        !!response.body.tercerosMinimo ? new BrokerInsuranceQuoteOptionModel(response.body.tercerosMinimo.externalCoberturaId,
          response.body.tercerosMinimo.nombre, response.body.tercerosMinimo.bullets, response.body.tercerosMinimo.importe,
          response.body.tercerosMinimo.aceptaPagoEfectivo) : null,
        !!response.body.tercerosCompletoMinimo ? new BrokerInsuranceQuoteOptionModel(
          response.body.tercerosCompletoMinimo.externalCoberturaId, response.body.tercerosCompletoMinimo.nombre,
          response.body.tercerosCompletoMinimo.bullets, response.body.tercerosCompletoMinimo.importe,
          response.body.tercerosCompletoMinimo.aceptaPagoEfectivo) : null,
        !!response.body.todoRiesgoMinimo ? new BrokerInsuranceQuoteOptionModel(response.body.todoRiesgoMinimo.externalCoberturaId,
          response.body.todoRiesgoMinimo.nombre, response.body.todoRiesgoMinimo.bullets, response.body.todoRiesgoMinimo.importe,
          response.body.todoRiesgoMinimo.aceptaPagoEfectivo) : null)),
      // Store data in model & in memCache.
      tap((data: BrokerInsuranceQuoteModel) => {
        broker.insuranceQuote = data;
        CotizadorService.memCacheBrokerInsuranceQuotes[broker.code] = data;
      }),
      // Error handler.
      catchError(error => of(this.errorService.getAppError(error)))
    );
  }

  /**
   * Returns the Insurance Quotation Download URL.
   */
  public getInsuranceQuotationDownloadUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }

    return `${this.baseURL}/download/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Returns the Insurance Quotation Print URL.
   */
  public getInsuranceQuotationPrintUrl(): string {
    if (!CotizadorService.isInitProcessCompleted()) {
      return null;
    }

    return `${this.baseURL}/print/${CotizadorService.getInsuranceQuoteTrackId()}`;
  }

  /**
   * Sends the Insurance to user's email..
   */
  public doEmailInsurance(): Observable<string | BaseAppError> {
    const __SUBTAG = 'doEmailInsurance';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    if (!CotizadorService.isInitProcessCompleted()) {
      return of(this.errorService.getAppError(new Error('Servicio Cotizador no iniciado.')));
    }

    const endpointURL = `${this.baseURL}/mail`;
    const payload = {
      trackId: CotizadorService.getInsuranceQuoteTrackId()
    };
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);

    return this.http.post<IDoCotizadorMailResponse>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IDoCotizadorMailResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IDoCotizadorMailResponse>) => response.body.status),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  /**
   * Gets the Insurance Validity Date max days and enabled status.
   */
  public getInsuranceValidityDateInfo(): Observable<{ maxDays: number; isEnabled: boolean; } | BaseAppError> {
    const __SUBTAG = 'getInsuranceValidityDateInfo';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/fechafutura`;
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'GET', endpointURL);

    return this.http.get<IGetCotizadorInsuranceValidityDateInfoResponse>(endpointURL, {observe: 'response'})
      .pipe(
        // Log operation.
        tap((response: HttpResponse<IGetCotizadorInsuranceValidityDateInfoResponse>) => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<IGetCotizadorInsuranceValidityDateInfoResponse>) => ({
          maxDays: response.body.days || 0,
          isEnabled: !!response.body.enabled
        })),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public doSetQuotesDownloaded(): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doSetQuotesDownloaded';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const endpointURL = `${this.baseURL}/setCotizacionDescargada/${CotizadorService.getInsuranceQuoteTrackId()}`;
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL);

    return this.http.post(endpointURL, null, {observe: 'response'})
      .pipe(
        // Log operation.
        tap(response => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<boolean>) => {
          return response.ok;
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

  public doSetQuotesRequested(method: PublicAccessContinueByMethod,
                              timeSlot: GetPublicAccessInfoResponseTimeSlot): Observable<boolean | BaseAppError> {
    const __SUBTAG = 'doSetQuotesRequested';
    _LOGGER.info(_TAG, __SUBTAG, 'method invoked.');

    const payload = {
      mode: method,
      trackId: CotizadorService.getInsuranceQuoteTrackId(),
      slot: null
    };

    if (method === PublicAccessContinueByMethod.CALLBACK) {
      payload.slot = timeSlot.id;
    } else {
      delete payload.slot;
    }
    const endpointURL = `${this.baseURL}/setCotizacionSolicitada`;
    _LOGGER.debug(_TAG, __SUBTAG, 'request:', 'POST', endpointURL, '; Payload', payload);


    return this.http.post<any>(endpointURL, payload, {observe: 'response'})
      .pipe(
        // Log operation.
        tap(response => {
          _LOGGER.debug(_TAG, __SUBTAG, 'response:', response);
        }),
        // Parse response.
        map((response: HttpResponse<boolean>) => {
          return response.ok;
        }),
        // Error handler.
        catchError(error => of(this.errorService.getAppError(error)))
      );
  }

}
